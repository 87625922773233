import React, { useEffect, useRef, useState } from 'react';
import Footer from '../components/Footer';
import { useTheme } from '../contexts/ThemeContext';
import Navbar from '../components/Navbar';
import LeagueRegistrationSlider from '../components/ui/LeagueRegistrationSlider';
import { ChevronDown, ChevronUp } from 'lucide-react';

// Define interface for founder data
interface Founder {
  id: string;
  number: string;
  name: string;
  title: string;
  description: string;
  quote: string;
  imageSrc: string;
}

const AboutUs: React.FC = () => {
  const { theme } = useTheme();
  const [scrollY, setScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const parallaxRef1 = useRef<HTMLDivElement>(null);
  const parallaxRef2 = useRef<HTMLDivElement>(null);
  const parallaxRef3 = useRef<HTMLDivElement>(null);
  const parallaxRef4 = useRef<HTMLDivElement>(null);
  
  const [showFirstQuote, setShowFirstQuote] = useState(false);
  const [showSecondQuote, setShowSecondQuote] = useState(false);
  const [showThirdQuote, setShowThirdQuote] = useState(false);
  const [showFourthQuote, setShowFourthQuote] = useState(false);
  const [showFifthQuote, setShowFifthQuote] = useState(false);
  const [showSixthQuote, setShowSixthQuote] = useState(false);
  const [showKirkCousinsQuote, setShowKirkCousinsQuote] = useState(false);
  const [showWatsonQuoteSection3, setShowWatsonQuoteSection3] = useState(false);
  const [showWatsonQuoteSection4, setShowWatsonQuoteSection4] = useState(false);
  const [showBrianLeagueQuote, setShowBrianLeagueQuote] = useState(false);
  const [showDrewButtFumbleQuote, setShowDrewButtFumbleQuote] = useState(false);
  const [showRoddyDanielJonesQuote, setShowRoddyDanielJonesQuote] = useState(false);
  const [showJohnGiantsQuote, setShowJohnGiantsQuote] = useState(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  const [expandedFounder, setExpandedFounder] = useState<string | null>(null);
  
  // Add the founders array
  const founders: Founder[] = [
    {
      id: "pete-jimison",
      number: "01",
      name: "PETE JIMISON",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Thought Tyreik Smoochie-Wallace was a real guy for a minute.",
      imageSrc: "../assets/pete_jimison.png"
    },
    {
      id: "steve-flisler",
      number: "02",
      name: "STEVE FLISLER",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Traded away Cooper Kupp before his breakout season.",
      imageSrc: "../assets/steve_flisler.png"
    },
    {
      id: "william-sanchez",
      number: "03",
      name: "WILLIAM SANCHEZ",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Started three players on bye in week 9.",
      imageSrc: "../assets/william_sanchez.png"
    },
    {
      id: "hailey-pink",
      number: "04",
      name: "HAILEY PINK",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Made the playoffs with the lowest points total in league history.",
      imageSrc: "../assets/hailey_pink.png"
    },
    {
      id: "jay-green",
      number: "05",
      name: "JAY GREEN",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Drafted a kicker in the 3rd round 'to lock up the position'.",
      imageSrc: "../assets/jay_green.png"
    },
    {
      id: "mike-guzzo",
      number: "06",
      name: "MIKE GUZZO",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Spent 90% of FAAB on a player who was ruled out 5 minutes later.",
      imageSrc: "../assets/mike_guzzo.png"
    },
    {
      id: "brian-krawczyk",
      number: "07",
      name: "BRIAN KRAWCZYK",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Lost the championship by 0.2 points after a stat correction.",
      imageSrc: "../assets/brian_krawczyk.png"
    },
    {
      id: "omer-jilani",
      number: "08",
      name: "OMER JILANI",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Forgot to set his lineup for the entire month of November.",
      imageSrc: "../assets/omer_jilani.png"
    },
    {
      id: "donald-leeds",
      number: "09",
      name: "DONALD LEEDS",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Forgot to set his lineup for the entire month of November.",
      imageSrc: "../assets/omer_jilani.png"
    },
    {
      id: "roddy-evans",
      number: "10",
      name: "RODDY EVANS",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Forgot to set his lineup for the entire month of November.",
      imageSrc: "../assets/omer_jilani.png"
    },
    {
      id: "seth-young",
      number: "11",
      name: "SETH YOUNG",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Forgot to set his lineup for the entire month of November.",
      imageSrc: "../assets/omer_jilani.png"
    },
    {
      id: "john-decker",
      number: "12",
      name: "JOHN DECKER",
      title: "Co-Founder",
      description: "Serial Entrepreneur in digital audio and digital advertising.\nCEO Frequency / Sharp",
      quote: "Forgot to set his lineup for the entire month of November.",
      imageSrc: "../assets/omer_jilani.png"
    },
  ];
  
  // Check if mobile on mount and window resize
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  // Add parallax effect and handle scroll-based quote visibility
  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;
      setScrollY(newScrollY);
      
      // Apply parallax effect to background images
      requestAnimationFrame(() => {
        if (parallaxRef1.current) {
          // Slower parallax effect for background text
          parallaxRef1.current.style.transform = `translateY(${newScrollY * 0.10}px)`;
        }
        
        if (parallaxRef2.current) {
          // Start second parallax when reaching its section
          const secondSectionOffset = window.innerHeight;
          const relativeScroll = Math.max(0, (newScrollY - secondSectionOffset * 0.5));
          parallaxRef2.current.style.transform = `translateY(${relativeScroll * 0.05}px)`;
        }
        
        if (parallaxRef3.current) {
          // Start third parallax when reaching its section
          const thirdSectionOffset = window.innerHeight * 2;
          const relativeScroll = Math.max(0, (newScrollY - thirdSectionOffset * 0.5));
          parallaxRef3.current.style.transform = `translateY(${relativeScroll * 0.10}px)`;
        }
        
        if (parallaxRef4.current) {
          // Start fourth parallax when reaching its section
          const fourthSectionOffset = window.innerHeight * 3;
          const relativeScroll = Math.max(0, (newScrollY - fourthSectionOffset * 0.5));
          parallaxRef4.current.style.transform = `translateY(${relativeScroll * 0.05}px)`;
        }
      });
      
      // Action-based triggers for mobile
      if (isMobile) {
        // First section quotes
        if (newScrollY > 200) {
          setShowFirstQuote(true);
        }
        
        if (newScrollY > 600) {
          setShowSecondQuote(true);
        }
        
        // Second section quotes
        if (newScrollY > 1100) {
          setShowThirdQuote(true);
        }
        
        if (newScrollY > 1400) {
          setShowFourthQuote(true);
        }
        
        // Third section quotes
        if (newScrollY > 2000) {
          setShowFifthQuote(true);
        }
        
        if (newScrollY > 2300) {
          setShowSixthQuote(true);
        }

        if (newScrollY > 2500) {
          setShowKirkCousinsQuote(true);
        }

        if (newScrollY > 2700) {
          setShowWatsonQuoteSection3(true);
        }
        
        // Fourth section quotes
        if (newScrollY > 3200) {
          setShowWatsonQuoteSection4(true);
        }
        
        if (newScrollY > 3400) {
          setShowBrianLeagueQuote(true);
        }

        if (newScrollY > 3600) {
          setShowDrewButtFumbleQuote(true);
        }

        if (newScrollY > 3800) {
          setShowRoddyDanielJonesQuote(true);
        }

        if (newScrollY > 4000) {
          setShowJohnGiantsQuote(true);
        }
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  // Handle accordion toggle
  const toggleFounder = (founderId: string) => {
    if (expandedFounder === founderId) {
      setExpandedFounder(null);
    } else {
      setExpandedFounder(founderId);
    }
  };

  // Define quote styles with proper typing to satisfy CSSProperties
  const quoteStyles = {
    quote1: {
      desktop: {
        position: 'absolute' as const,
        left: '-300px',
        top: '-200px',
        transform: 'rotate(-5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    quote2: {
      desktop: {
        position: 'absolute' as const,
        right: '-300px',
        top: '300px',
        transform: 'rotate(5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    quote3: {
      desktop: {
        position: 'absolute' as const,
        right: '-300px',
        top: '-200px',
        transform: 'rotate(5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    quote4: {
      desktop: {
        position: 'absolute' as const,
        left: '-350px',
        top: '150px',
        transform: 'rotate(-5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    quote5: {
      desktop: {
        position: 'absolute' as const,
        left: '-300px',
        top: '-200px',
        transform: 'rotate(-5deg)',
        maxWidth: '300px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    quote6: {
      desktop: {
        position: 'absolute' as const,
        right: '-300px',
        top: '-200px',
        transform: 'rotate(5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    quote7: {
      desktop: {
        position: 'absolute' as const,
        right: '-300px',
        top: '-200px',
        transform: 'rotate(5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    quote8: {
      desktop: {
        position: 'absolute' as const,
        left: '-350px',
        top: '150px',
        transform: 'rotate(-5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    qKirkCousins: {
      desktop: {
        position: 'absolute' as const,
        left: '-250px',
        top: '400px',
        transform: 'rotate(-3deg)',
        maxWidth: '500px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    qWatsonSection3: {
      desktop: {
        position: 'absolute' as const,
        right: '300px',
        top: '1700px',
        transform: 'rotate(4deg)',
        maxWidth: '500px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    qWatsonSection4: {
      desktop: {
        position: 'absolute' as const,
        left: '-300px',
        top: '300px',
        transform: 'rotate(-4deg)',
        maxWidth: '500px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    qBrianLeague: {
      desktop: {
        position: 'absolute' as const,
        right: '-400px',
        top: '200px',
        transform: 'rotate(5deg)',
        maxWidth: '400px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    qDrewButtFumble: {
      desktop: {
        position: 'absolute' as const,
        left: '-350px',
        top: '700px',
        transform: 'rotate(-5deg)',
        maxWidth: '500px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    qRoddyDanielJones: {
      desktop: {
        position: 'absolute' as const,
        right: '-300px',
        top: '700px',
        transform: 'rotate(4deg)',
        maxWidth: '500px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    },
    qJohnGiants: {
      desktop: {
        position: 'absolute' as const,
        left: '350px',
        top: '900px',
        transform: 'rotate(-4deg)',
        maxWidth: '500px',
        zIndex: 30
      },
      mobile: {
        display: 'none' as const
      }
    }
};
  
  return (
    <div className="min-h-screen flex flex-col text-white">
      <Navbar />
      {/* First section */}
      <section className="relative md:h-[1730px] h-[1050px] overflow-hidden">
        <div 
          ref={parallaxRef1}
          className="absolute inset-0 bg-[#1a1a1a] z-0 flex flex-col items-center justify-end overflow-hidden"
        >
          <div className="absolute bottom-0 w-full flex flex-col items-center font-antonio font-bold text-[#1a1a1a] drop-shadow-[1.2px_1.2px_1.2px_rgba(255,255,255,255)] opacity-20 select-none pb-4 sm:pb-8 md:pb-12">
            <div className="w-full flex flex-col items-center">
              <span className="text-[40vw] xs:text-[37vw] md:text-[30vw] leading-[0.85] tracking-tight">FANTASY</span>
              <span className="text-[55vw] xs:text-[54vw] md:text-[50vw] leading-[0.9] tracking-tight">LOSER</span>
            </div>
          </div>
        </div>
        
        <div className="relative z-10 w-full mx-auto h-full flex flex-col items-center pt-20 md:pt-40 px-4">
          <h1 className="text-[60px] md:text-[130px] font-antonio  font-bold text-center mb-12 md:mb-40 leading-tight">
            The leagues that<br />punish, prosper.
          </h1>
          
          <div className="relative w-full font-antonio group max-w-6xl">
            {/* First quote as easter egg */}
            <div 
              className={`italic text-[#FFC526] font-knewave text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showFirstQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
              style={isMobile ? quoteStyles.quote1.mobile : quoteStyles.quote1.desktop}
            >
              " Steve owns more than 1 Daniel Jones jersey.
            </div>
            
            <div className="relative z-20">
              <p className="text-[32px] md:text-[60px] text-center leading-tight">
                At Fantasy Loser, we believe that fantasy
                football isn't just about winning. It's about rivalries, chaos, and the satisfaction of
                watching your <span className="text-[#18f7b1] font-bold">close, beloved friends suffer
                for their terrible draft decisions.</span>
              </p>
            </div>
            
            {/* Image positioned to slightly overlap with text above */}
            <div className="mx-auto w-[300px] md:w-[399px] h-[330px] md:h-[435px] rounded-lg relative -mt-32 md:-mt-28 z-10">
              <img 
                src={require('../assets/Mike League 5.png')}
                alt="Fantasy friends" 
                className="h-full w-full"
              />
            </div>
            
            {/* Second quote as easter egg - now positioned below image on mobile */}
            <div 
              className={`italic text-[#FFC526] font-knewave text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showSecondQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
              style={isMobile ? quoteStyles.quote2.mobile : quoteStyles.quote2.desktop}
            >
              " Pete loves losing so much he started a website devoted to it
            </div>
          </div>
        </div>
      </section>
      
      {/* Second section - Applying same styling as section 1 but with white background */}
      <section className="relative md:h-[1427px] h-[914px] overflow-hidden bg-white text-black">
        <div className="relative z-10 w-full mx-auto h-full flex flex-col items-center pt-20 md:pt-40 px-4 max-w-5xl">
          <p className="text-[32px] md:text-[80px] text-center leading-tight font-antonio font-bold mb-12 md:mb-40 text-black/65">
            Too many leagues <span className="text-black">go soft.</span><br />
            They let the season <span className="text-black">fizzle out.</span><br />
            They let last place off easy. 
          </p>
          
          <div className="relative w-full font-antonio group max-w-6xl">
            {/* Third quote as easter egg - opposite position from first quote */}
            <div 
              className={`italic text-[#FFC526] text-[18px] md:text-[40px] font-medium font-knewave transition-opacity duration-300 z-20 ${isMobile ? (showThirdQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
              style={isMobile ? quoteStyles.quote3.mobile : quoteStyles.quote3.desktop}
            >
              " William's the MVP: Most Viciously Punished.
            </div>
            
            <div className="relative z-20">
              <h1 className="text-[97.72px] md:text-[150px] font-antonio font-bold text-center leading-tight">
                NOT US.
              </h1>
            </div>
            
            {/* Image positioned to slightly overlap with text above */}
            <div className="mx-auto w-[300px] md:w-[399px] h-[330px] md:h-[435px] rounded-lg relative -mt-20 md:-mt-28 z-10">
              <img 
                src={require('../assets/Mike League 6.png')}
                alt="Friends at the lake" 
                className="h-full w-full object-cover"
              />
            </div>
            
            {/* Fourth quote as easter egg - opposite position from second quote */}
            <div 
              className={`italic font-knewave text-[#FFC526] text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showFourthQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
              style={isMobile ? quoteStyles.quote4.mobile : quoteStyles.quote4.desktop}
            >
              " Seth better start rehearsing his waffle house order.
            </div>
          </div>
        </div>
      </section>
      
      <div className="w-full bg-[#1a1a1a] py-8 overflow-hidden mb-4">
        <div className="flex items-center animate-marquee whitespace-nowrap justify-between">
          {/* First set */}
          <div className="flex items-center justify-between mx-8">
            <img 
              src={require('../assets/FL_Logo_FL_Icon_Primary_2.png')}
              alt="Finger"
              className="w-[80px] object-cover"
            />
            <span className="text-gray-300 text-[67.79px] font-antonio px-10">LAST PLACE LIVES FOREVER</span>
          </div>
          <div className="flex items-center mx-8">
            <img 
              src={require('../assets/FL_Logo_Loser_Icon_Grn.png')}
              alt="Finger"
              className="w-[80px] object-cover"
            />
            <span className="text-gray-300 text-[67.79px] font-antonio px-10">LAST PLACE LIVES FOREVER</span>
          </div>

          {/* Second set */}
          <div className="flex items-center mx-8 justify-between">
            <img 
              src={require('../assets/FL_Logo_FL_Icon_Primary_2.png')}
              alt="Finger"
              className="w-[80px] object-cover"
            />
            <span className="text-gray-300 text-[67.79px] font-antonio px-10">LAST PLACE LIVES FOREVER</span>
          </div>
          <div className="flex items-center mx-8">
            <img 
              src={require('../assets/FL_Logo_Loser_Icon_Grn.png')}
              alt="Finger"
              className="w-[80px] object-cover"
            />
            <span className="text-gray-300 text-[67.79px] font-antonio px-10">LAST PLACE LIVES FOREVER</span>
          </div>

          {/* Third set */}
          <div className="flex items-center mx-4">
            <img 
              src={require('../assets/FL_Logo_FL_Icon_Primary_2.png')}
              alt="Finger"
              className="w-[80px] object-cover"
            />
            <span className="text-gray-300 text-[67.79px] font-antonio px-10">LAST PLACE LIVES FOREVER</span>
          </div>
          <div className="flex items-center mx-4">
            <img 
              src={require('../assets/FL_Logo_Loser_Icon_Grn.png')}
              alt="Finger"
              className="w-[80px] object-cover"
            />
            <span className="text-gray-300 text-[67.79px] font-antonio px-10">LAST PLACE LIVES FOREVER</span>
          </div>
        </div>
      </div>
      
      {/* Third section - Our Mission & History section */}
<section className="relative min-h-screen pb-20 overflow-hidden bg-[#1a1a1a]">
  {/* Background for section 3 */}
  <div 
    ref={parallaxRef3}
    className="absolute inset-0 z-0 overflow-visible"
  >
    {/* Image behind "we wanted momentum" text */}
    <div className="absolute top-[25%] md:top-[40%] left-1/2 transform -translate-x-1/2 w-full flex justify-center">
      <div className="w-[80%] sm:w-[85%] md:w-[90%] max-w-[399px] relative">
        <img 
          src={require('../assets/Mike League 7.png')}
          alt="Background image" 
          className="w-full h-auto object-contain"
        />
      </div>
    </div>
    
    {/* Images behind "Fantasy Loser is here" text */}
    <div className="absolute top-[80%] md:left-[10%] left-[5%]">
      <div className="w-[75%] sm:w-[80%] md:w-[90%] max-w-[399px] relative">
        <img 
          src={require('../assets/Mike League 9.png')}
          alt="Background image" 
          className="w-full h-auto object-contain"
        />
      </div>
    </div>
    
    <div className="absolute top-[55%] md:right-[10%] -right-[10%]">
      <div className="w-[75%] sm:w-[80%] md:w-[90%] max-w-[399px] relative">
        <img 
          src={require('../assets/Mike League 10.png')}
          alt="Background image" 
          className="w-full h-auto object-contain"
        />
      </div>
    </div>
  </div>
  
  <div className="relative z-10 w-full mx-auto flex flex-col items-center pt-20 md:pt-40 px-4">
    <h1 className="text-[32px] md:text-[60px] font-antonio font-bold text-center mb-14 md:mb-56 leading-tight max-w-5xl">
      We built Fantasy Loser because we wanted leagues that stay competitive from draft day to championship week.
    </h1>
    
    <div className="relative w-full font-antonio group max-w-6xl">
      {/* "Writing this from Jay's mom's house" quote */}
      <div 
        className={`italic text-[#FFC526] text-[18px] font-knewave md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showFifthQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.quote5.mobile : quoteStyles.quote5.desktop}
      >
        " Writing this from Jay's<br />mom's house.
      </div>
      
      <div className="relative z-20">
        <p className="text-[32px] md:text-[60px] text-center leading-tight mt-16 md:mt-24 mb-60">
          We wanted momentum, engagement, and absolute humiliation for last place. We wanted
          <span className="text-[#18f7b1] font-bold"> a group chat that never dies.</span>
        </p>
      </div>
      
      {/* "Donald drafted 3 kickers" quote */}
      <div 
        className={`italic text-[#FFC526] text-[18px] md:text-[40px] font-knewave font-medium transition-opacity duration-300 z-20 ${isMobile ? (showSixthQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.quote6.mobile : quoteStyles.quote6.desktop}
      >
        " Donald drafted<br />3 kickers.
      </div>

      {/* "Hailey started Kirk Cousins on a bye week" quote */}
      <div 
        className={`italic text-[#FFC526] font-knewave text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showKirkCousinsQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.qKirkCousins.mobile : quoteStyles.qKirkCousins.desktop}
      >
        " Hailey started Kirk<br />Cousins on a bye<br />week.
      </div>
      
      <div className="relative z-20 mt-56 md:mt-[500px]">
        <p className="text-[32px] md:text-[60px] text-center leading-tight">
          Fantasy Loser is here to celebrate the time-honored tradition of leagues coming together to immortalize their losers.
        </p>
      </div>
      
      <div className="relative z-20 mt-20 mb-40">
        <p className="text-[32px] md:text-[60px] text-center leading-tight">
          We revel in the legendary punishments of the past, and we're here to document the lore for years to come.
        </p>
      </div>

      {/* "Steve owns more than 1 Deshaun Watson jersey" quote for section 3 */}
      <div 
        className={`italic text-[#FFC526] font-knewave text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showWatsonQuoteSection3 ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.qWatsonSection3.mobile : quoteStyles.qWatsonSection3.desktop}
      >
        " Steve owns more than 1<br />Deshaun Watson jersey.
      </div>
    </div>
  </div>
</section>

<section className="relative md:h-[1805px] h-[1150px] overflow-hidden bg-[#1a1a1a] text-white -mt-1">
  {/* Background for section 4 */}
  <div 
    ref={parallaxRef4}
    className="absolute inset-0 z-0 overflow-hidden"
  >
    {/* Image behind "Hopefully, this movement" text */}
    <div className="absolute top-[25%] left-1/2 transform -translate-x-1/2 w-full flex justify-center">
      <img 
        src={require('../assets/Mike League 5.png')}
        alt="Background image" 
        className="w-[250px] sm:w-[280px] md:w-[399px] h-[280px] sm:h-[310px] md:h-[435px] object-cover"
      />
    </div>
  </div>
  
  <div className="relative z-10 w-full mx-auto h-full flex flex-col items-center px-4 max-w-6xl">
    <p className="text-[32px] md:text-[60px] text-center leading-tight font-antonio font-bold mb-12 md:mb-40 pt-20 md:pt-40">
      Hopefully, this movement grows into something bigger—creating a world where <span className="text-[#18f7b1]">leagues can come together, crank up the competition, and proudly wear the Fantasy Loser tattoo as the ultimate badge of honor.</span>
    </p>
    
    <div className="relative w-full font-antonio group max-w-6xl">
      {/* "Steve owns more than 1 Deshaun Watson jersey" quote for section 4 */}
      <div 
        className={`italic text-[#FFC526] font-knewave text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showWatsonQuoteSection4 ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.qWatsonSection4.mobile : quoteStyles.qWatsonSection4.desktop}
      >
        " Steve owns more than 1<br />Deshaun Watson jersey.
      </div>
      
      {/* "Brian runs a 14-man league" quote */}
      <div 
        className={`italic text-[#FFC526] font-knewave text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showBrianLeagueQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.qBrianLeague.mobile : quoteStyles.qBrianLeague.desktop}
      >
        " Brian runs a 14-man league.
      </div>
      
      <div className="relative z-20 mt-40 md:mt-[500px] justify-center text-center">
        <p className="text-[32px] md:text-[60px] text-center leading-tight">
          Because, at the end of the day, <span className="text-[#18f7b1] font-bold">losing is winning</span> when you lose to your friends.
        </p>
        <button
          onClick={() => setIsRegistrationOpen(true)}
          className="text-white mt-10 text-center text-lg sm:text-xl md:text-2xl w-full sm:w-auto sm:max-w-[220px] font-antonio bg-transparent border border-primary-500 hover:border-[#18f7b1] transition-colors duration-300 py-2 sm:py-4 px-4 sm:px-8 rounded-md"
        >
          Join Us
        </button>
      </div>
      
      {/* "Drew's favorite play is the butt fumble" quote */}
      <div 
        className={`italic text-[#FFC526] font-knewave text-[18px] md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showDrewButtFumbleQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.qDrewButtFumble.mobile : quoteStyles.qDrewButtFumble.desktop}
      >
        " Omer's favorite play is<br />the butt fumble.
      </div>
      
      {/* "Roddy said '2024 was Daniel Jones' year'" quote */}
      <div 
        className={`italic text-[#FFC526] text-[18px] font-knewave md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showRoddyDanielJonesQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.qRoddyDanielJones.mobile : quoteStyles.qRoddyDanielJones.desktop}
      >
        " Roddy said "2024 was<br />Daniel Jones' year."
      </div>
      
      {/* "John gets outscored by the New York Giants" quote */}
      <div 
        className={`italic text-[#FFC526] text-[18px] font-knewave md:text-[40px] font-medium transition-opacity duration-300 z-20 ${isMobile ? (showJohnGiantsQuote ? 'opacity-100' : 'opacity-0') : 'opacity-0 group-hover:opacity-100'}`}
        style={isMobile ? quoteStyles.qJohnGiants.mobile : quoteStyles.qJohnGiants.desktop}
      >
        " John gets outscored by<br />the New York Giants.
      </div>
    </div>
  </div>
</section>

      {/* Shield Logo straddling between sections */}
      {/* <div className="relative flex justify-center">
        <div className="absolute -top-20 z-40">
          <img 
            src={require('../assets/Group 1171276406.png')}
            alt="Fantasy Loser Shield Logo" 
            className="w-[184.57px] h-[217.3px]"
          />
        </div>
      </div> */}
      
      {/* New Team Roster Section
      <section className="relative bg-white text-black pt-40 pb-16">
        <div className="container mx-auto px-4">
          <h2 className="text-[48px] md:text-[80px] font-antonio font-bold text-center mb-12">Our Roster</h2>
          
          <div className="max-w-4xl mx-auto border-t border-gray-200">
            {founders.map((founder) => (
              <div 
                key={founder.id} 
                className="border-b border-gray-200"
              >
                <div 
                  className="py-6 px-4 flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFounder(founder.id)}
                >
                  <div className="flex items-center">
                    <span className="text-gray-500 font-antonio text-xl mr-6">{founder.number}</span>
                    <h3 className="font-antonio text-2xl md:text-3xl font-bold">{founder.name}</h3>
                  </div>
                  {expandedFounder === founder.id ? 
                    <ChevronUp className="h-6 w-6 text-gray-500" /> : 
                    <ChevronDown className="h-6 w-6 text-gray-500" />
                  }
                </div>
                
                {expandedFounder === founder.id && (
                  <div className="px-4 pb-6">
                    <div className="flex flex-col md:flex-row">
                      <div className="md:w-1/4 mb-4 md:mb-0">
                        <div className="h-[200px] w-[200px] rounded-md overflow-hidden bg-gray-100">
                          <img 
                            src={require('../assets/FL_Cover_2.jpg')} 
                            alt={founder.name}
                            className="w-full h-full object-cover" 
                            onError={(e) => {
                              // Fallback if image doesn't load
                              e.currentTarget.src = require('../assets/FL_Cover_2.jpg');
                            }}
                          />
                        </div>
                      </div>
                      <div className="md:w-3/4 md:pl-8">
                        <h4 className="font-antonio text-xl font-bold mb-2">{founder.title}</h4>
                        <p className="text-gray-700 whitespace-pre-line mb-4">{founder.description}</p>
                        
                        <div className="mt-6">
                          <div className="italic text-[#FFC526] text-lg md:text-xl font-medium font-knewave">
                            " {founder.quote}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section> */}
      
      <LeagueRegistrationSlider 
        isOpen={isRegistrationOpen}
        onClose={() => setIsRegistrationOpen(false)}
      />
      
      <Footer />
    </div>
  );
};

export default AboutUs; 