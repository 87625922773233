import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Menu, X } from 'lucide-react';

interface NavbarProps {
  className?: string; 
}

const Navbar: React.FC<NavbarProps> = ({ className = '' }) => {
  const { user, logout, userRole } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // On mobile, close the menu after clicking
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  // Custom link component that scrolls to top
  const NavLink = ({ to, children, ...props }: { to: string; children: React.ReactNode; className?: string; target?: string }) => (
    <Link 
      to={to} 
      onClick={scrollToTop} 
      {...props}
    >
      {children}
    </Link>
  );

  const NavItems = () => (
    <>
      {user ? (
        <>
        <NavLink target="_blank" to="https://shop.fantasyloser.com" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
            SHOP
          </NavLink>
          <NavLink to="/about" className="block font-antonio py-2 px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
            ABOUT
          </NavLink>
        <NavLink to="/punishments" className="block font-antonio py-2 px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
        PUNISHMENTS
      </NavLink>
          <NavLink to="/dashboard" className="block font-antonio py-2 px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
            COMMISSIONERS
          </NavLink>
          {userRole === 'admin' && (
            <NavLink to="/admin" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
              ADMIN
            </NavLink>
          )}
          
          <button
            onClick={handleLogout}
            className="block w-full text-left py-2 font-antonio px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl"
          >
            LOGOUT
          </button>
        </>
      ) : (
        <>
        <NavLink target="_blank" to="https://shop.fantasyloser.com" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
            SHOP
          </NavLink>
          <NavLink to="/about" className="block font-antonio py-2 px-4 text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
            ABOUT
          </NavLink>
        <NavLink to="/punishments" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">
            PUNISHMENTS
          </NavLink>
          <NavLink to="/login" className="block py-2 px-4 font-antonio text-white hover:bg-[#18f7b1] hover:text-[#333333] rounded-lg text-xl">LOGIN</NavLink>
        </>
      )}
    </>
  );

  return (
    <nav className={`bg-[#1A1A1A] fixed top-0 left-0 right-0 z-50 h-[50px] sm:h-[80px] ${className}`}>
      <div className="mx-auto px-4 max-w-screen-xl h-[60px] sm:h-[80px]">
        <div className="flex justify-between items-center mb-2">
          <Link 
            to={user ? "/" : "/"} 
            className="flex items-center md:ml-6 lg:ml-[-10px]" 
            onClick={scrollToTop}
          >
            <img 
              className="w-40 sm:w-40 md:w-48 lg:w-64 max-w-[80%] h-auto"
              src={require('../assets/FL_Logo_Horizontal_Rev.png')} 
              alt="FL Logo"
            />
          </Link>
          
          <div className="hidden md:flex items-center space-x-3 mr-4 md:mr-8 lg:mr-[-10px]">
            <NavItems />
          </div>

          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-white hover:text-[#18f7b1] focus:outline-none transition-colors duration-300 p-2"
            >
              {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div 
        className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out bg-[#1A1A1A] mt-[-3%] ${
          isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <NavItems />
      </div>
    </nav>
  );
};

export default Navbar;