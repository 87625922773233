import { db, storage } from '../../services/firebase';
import { 
  collection, 
  doc, 
  addDoc, 
  updateDoc, 
  getDoc,
  getDocs,
  query,
  where,
  Timestamp,
  serverTimestamp,
  writeBatch,
  orderBy
} from 'firebase/firestore';

export interface CustomChallenge {
  id?: string;
  title: string;
  image?: string | null; 
  subtitle: string;
  description: string;
  stakes: {
    title: string;
    description: string;
  }[];
  formulaGroups: {
    name: string;
    description: string;
    steps: {
      type: 'stat' | 'operator' | 'number';
      value: string | number;
      playerStat?: {
        position: string;
        positionRank: number;
        statId: string;
      };
    }[];
  }[];
  week: number;
  status: 'active' | 'completed';
  createdAt: Date;
  updatedAt: Date;
}




const COLLECTION_NAME = 'customChallenges';

export const addCustomChallenge = async (challenge: Omit<CustomChallenge, 'id' | 'createdAt' | 'updatedAt'>) => {
  try {
    // Get all active challenges first
    const activeQuery = query(
      collection(db, COLLECTION_NAME),
      where('status', '==', 'active')
    );
    const activeChallenges = await getDocs(activeQuery);

    // Create a batch write using the correct Firestore v9 function
    const batch = writeBatch(db);

    // Set all currently active challenges to completed
    activeChallenges.forEach(doc => {
      batch.update(doc.ref, { 
        status: 'completed',
        updatedAt: serverTimestamp()
      });
    });

    // Create the new challenge document
    const newChallengeRef = doc(collection(db, COLLECTION_NAME));
    batch.set(newChallengeRef, {
      ...challenge,
      status: 'active', // Ensure new challenge is active
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Execute all the updates atomically
    await batch.commit();

    return newChallengeRef.id;
  } catch (error) {
    console.error('Error adding custom challenge:', error);
    throw error;
  }
};

export const updateCustomChallenge = async (id: string, challengeData: Partial<CustomChallenge>) => {
  try {
    const docRef = doc(db, COLLECTION_NAME, id);
    await updateDoc(docRef, {
      ...challengeData,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating custom challenge:', error);
    throw error;
  }
};

export const getCustomChallengeById = async (id: string): Promise<CustomChallenge | null> => {
  try {
    const docRef = doc(db, COLLECTION_NAME, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as CustomChallenge;
    }
    return null;
  } catch (error) {
    console.error('Error getting custom challenge:', error);
    throw error;
  }
};

export const getActiveCustomChallenges = async (): Promise<CustomChallenge[]> => {
  try {
    // Query for both active and completed challenges, ordered by week
    const q = query(
      collection(db, COLLECTION_NAME),
      // Removed the where clause to get all challenges
      orderBy('week', 'desc')  // Sort by week in descending order
    );
    
    const querySnapshot = await getDocs(q);
    const challenges = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        title: data.title,
        subtitle: data.subtitle,
        description: data.description,
        image: data.image || null,
        stakes: data.stakes || [],
        formulaGroups: data.formulaGroups || [],
        week: data.week,
        status: data.status,
        createdAt: data.createdAt?.toDate() || new Date(),
        updatedAt: data.updatedAt?.toDate() || new Date()
      } as CustomChallenge;
    });

    // Sort challenges by week in descending order if needed
    challenges.sort((a, b) => b.week - a.week);

    return challenges;
  } catch (error) {
    console.error('Error getting custom challenges:', error);
    throw error;
  }
};