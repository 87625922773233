import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import AdminForm from '../components/AdminForm';
import { createUser } from '../services/firebase';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import ChallengeCalculator from '../components/weeklyChallenges/challengeCalculator';

interface TabProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ label, active, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 font-semibold transition-colors duration-200 ${
      active 
        ? 'text-blue-600 border-b-2 border-blue-600' 
        : 'text-gray-500 hover:text-blue-500'
    }`}
  >
    {label}
  </button>
);

const AdminPage: React.FC = () => {
  const { theme } = useTheme();
  const { user, userRole } = useAuth();
  const [activeTab, setActiveTab] = useState('users');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserRole, setNewUserRole] = useState<'user' | 'commissioner' | 'admin'>('user');
  const [userCreationError, setUserCreationError] = useState<string | null>(null);
  const [userCreationSuccess, setUserCreationSuccess] = useState<string | null>(null);

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setUserCreationError(null);
    setUserCreationSuccess(null);

    if (!user || userRole !== 'admin') {
      setUserCreationError('Only admins can create new users');
      return;
    }

    try {
      const result = await createUser(newUserEmail, newUserPassword, newUserRole);
      if (result.success) {
        setUserCreationSuccess(`User created successfully with ID: ${result.uid}`);
        setNewUserEmail('');
        setNewUserPassword('');
        setNewUserRole('user');
      } else {
        setUserCreationError('Failed to create user');
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('auth/email-already-in-use')) {
          setUserCreationError('Email is already in use. Please use a different email.');
        } else if (error.message.includes('auth/weak-password')) {
          setUserCreationError('Password is too weak. Please use a stronger password.');
        } else {
          setUserCreationError(`Error creating user: ${error.message}`);
        }
      } else {
        setUserCreationError('An unknown error occurred while creating user');
      }
    }
  };

  if (userRole !== 'admin') {
    return <div>Access Denied. Only admins can view this page.</div>;
  }

  const renderUserCreationForm = () => (
    <form onSubmit={handleCreateUser} className={`${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} shadow-md rounded px-8 pt-6 pb-8 mb-8`}>
      <h2 className="text-2xl font-bold mb-4">Create New User</h2>
      {userCreationError && (
        <div className="mb-4 text-red-500">{userCreationError}</div>
      )}
      {userCreationSuccess && (
        <div className="mb-4 text-green-500">{userCreationSuccess}</div>
      )}
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="newUserEmail">
          Email
        </label>
        <input
          type="email"
          id="newUserEmail"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="newUserPassword">
          Password
        </label>
        <input
          type="password"
          id="newUserPassword"
          value={newUserPassword}
          onChange={(e) => setNewUserPassword(e.target.value)}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="newUserRole">
          Role
        </label>
        <select
          id="newUserRole"
          value={newUserRole}
          onChange={(e) => setNewUserRole(e.target.value as 'user' | 'commissioner' | 'admin')}
          className={`shadow appearance-none border rounded w-full py-2 px-3 ${theme === 'dark' ? 'bg-gray-700 text-white' : 'text-gray-700'} leading-tight focus:outline-none focus:shadow-outline`}
        >
          <option value="user">User</option>
          <option value="commissioner">Commissioner</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Create User
        </button>
      </div>
    </form>
  );

  return (
    <div className="min-h-screen bg-gray-100 mt-[50px]">
      <Navbar />
      <main className="container mx-auto px-4 py-8 pt-[50px]">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Admin Dashboard</h1>
        
        <div className="mb-8 border-b border-gray-200">
          <div className="flex space-x-4">
            <Tab 
              label="User Management" 
              active={activeTab === 'users'} 
              onClick={() => setActiveTab('users')} 
            />
            <Tab 
              label="Content Management" 
              active={activeTab === 'content'} 
              onClick={() => setActiveTab('content')} 
            />
            <Tab 
              label="Challenge Calculator" 
              active={activeTab === 'calculator'} 
              onClick={() => setActiveTab('calculator')} 
            />
          </div>
        </div>

        <div className="mt-6">
          {activeTab === 'users' && renderUserCreationForm()}
          {activeTab === 'content' && <AdminForm />}
          {activeTab === 'calculator' && (
            <div className="bg-white rounded-lg shadow-md p-6">
              <ChallengeCalculator onSaveSuccess={() => console.log('Saved!')} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default AdminPage;