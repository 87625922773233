import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Routes, Route, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { getLeagueById, updateLeague as firebaseUpdateLeague, League, getContentByType, verifyLeaguePassword, uploadImage } from '../services/firebase';
import Footer from '../components/Footer';
import DeadbeatTracker from '../components/DeadbeatTracker';
import { Pencil, AlertCircle, Home, Trophy, BookOpen, Menu, X, Copy, Check, Settings } from 'lucide-react';
import Navbar from '../components/Navbar';
import Challenges from '../components/leaguePages/Challenges';
import RecordBook from '../components/leaguePages/RecordBook';
import LeagueSettings from '../components/leaguePages/Settings';
import LeagueSeasonDisplay from '../components/LeagueSeasonDisplay';
import { generateNewInviteCode, sendTeamInvite } from '../services/inviteService';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';

const LeaguePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user, userRole } = useAuth();
  const { theme } = useTheme();
  const [league, setLeague] = useState<League | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedLeague, setEditedLeague] = useState<League | null>(null);
  const [punishments, setPunishments] = useState<Array<{ id: string; title: string }>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [selectedLeagueImage, setSelectedLeagueImage] = useState<File | null>(null);
  const [selectedLoserImage, setSelectedLoserImage] = useState<File | null>(null);
  const [leagueImagePreview, setLeagueImagePreview] = useState<string | null>(null);
  const [loserImagePreview, setLoserImagePreview] = useState<string | null>(null);
  const [selectedBannerImage, setSelectedBannerImage] = useState<File | null>(null);
  const [bannerImagePreview, setBannerImagePreview] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(null);
  const [isUserCommissioner, setIsUserCommissioner] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isInviteLinkCopied, setIsInviteLinkCopied] = useState(false);
  const [isGeneratingNewCode, setIsGeneratingNewCode] = useState(false);
  const [inviteError, setInviteError] = useState<string | null>(null);
  const [inviteSuccess, setInviteSuccess] = useState<string | null>(null);
  const [standings, setStandings] = useState<any[]>([]);

  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (id) {
      fetchLeague();
      fetchPunishments();
    }
  }, [id]);

  useEffect(() => {
    if (league) {
      setLoserImagePreview(league.mostRecentLoser?.imageUrl ?? null);
      setBannerImagePreview(league.bannerImageUrl ?? null);
    }
  }, [league]);

  useEffect(() => {
    checkCommissionerStatus();
  }, [league, user, userRole]);

  const checkCommissionerStatus = () => {
    if (user && league) {
      const isCommissioner = league.commissionerId === user.uid;
      setIsUserCommissioner(isCommissioner || userRole === 'admin');
    }
  };

  const handleGenerateNewCode = async () => {
    if (!league?.id || !isUserCommissioner) return;
  
    try {
      setIsGeneratingNewCode(true);
      setInviteError(null);
      const newCode = await generateNewInviteCode(league.id);
      
      setLeague(prevLeague => 
        prevLeague ? { ...prevLeague, inviteCode: newCode } : null
      );
      
      setInviteSuccess('New invite code generated successfully!');
      setTimeout(() => setInviteSuccess(null), 3000);
    } catch (error) {
      console.error('Failed to generate new invite code:', error);
      setInviteError('Failed to generate new invite code');
    } finally {
      setIsGeneratingNewCode(false);
    }
  };

  const handleCopyLink = async (inviteLink: string) => {
    try {
      await navigator.clipboard.writeText(inviteLink);
      setIsInviteLinkCopied(true);
      setInviteSuccess('Invite link copied to clipboard!');
      setTimeout(() => {
        setIsInviteLinkCopied(false);
        setInviteSuccess(null);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy link:', error);
      setInviteError('Failed to copy link to clipboard');
    }
  };

  const handleSendInviteEmail = async (email: string) => {
    if (!league?.id || !league.inviteCode) return;
  
    try {
      setInviteError(null);
      const result = await sendTeamInvite(
        league.id,
        email,
        league.inviteCode
      );
      
      if (!result.success) {
        throw new Error(result.error || 'Failed to send invite');
      }
      
      setInviteSuccess('Invitation sent successfully!');
      setTimeout(() => setInviteSuccess(null), 3000);
    } catch (error) {
      console.error('Failed to send invite:', error);
      setInviteError(error instanceof Error ? error.message : 'Failed to send invite');
    }
  };

  const fetchLeague = async () => {
    if (id) {
      try {
        setIsLoading(true);
        const fetchedLeague = await getLeagueById(id);
        if (fetchedLeague) {
          setLeague(fetchedLeague);
          setEditedLeague(fetchedLeague);
          setIsPasswordProtected(fetchedLeague.isPasswordProtected);
          setIsPasswordCorrect(!fetchedLeague.isPasswordProtected);
        } else {
          setError('League not found');
        }
      } catch (error) {
        console.error('Error fetching league:', error);
        setError('Failed to fetch league data');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchPunishments = async () => {
    try {
      const punishmentContent = await getContentByType('punishment');
      const formattedPunishments = punishmentContent.map(item => ({
        id: item.id,
        title: item.title,
        type: item.type,
      }));
      setPunishments(formattedPunishments);
    } catch (err) {
      console.error('Error fetching punishments:', err);
      setError('Failed to fetch punishments');
    }
  };

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (id && password) {
      setIsVerifying(true);
      setVerificationError(null);
      try {
        const isCorrect = await verifyLeaguePassword(id, password);
        if (isCorrect) {
          setIsPasswordCorrect(true);
        } else {
          setVerificationError('Incorrect password');
        }
      } catch (error) {
        console.error('Error verifying password:', error);
        setVerificationError('Failed to verify password. Please try again.');
      } finally {
        setIsVerifying(false);
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleLeagueChange = (updatedLeague: League) => {
    setEditedLeague(updatedLeague);
  };

  const handleUpdateLeague = async (id: string, updatedLeagueData: Partial<League>) => {
    try {
      await firebaseUpdateLeague(id, updatedLeagueData);
      setLeague(prevLeague => {
        if (prevLeague) {
          return { ...prevLeague, ...updatedLeagueData };
        }
        return prevLeague;
      });
    } catch (error) {
      console.error('Error updating league:', error);
      setError('Failed to update league');
    }
  };

  const handleSave = async () => {
    if (id && editedLeague) {
      try {
        let updatedLeague = { ...editedLeague };
  
        if (selectedBannerImage) {
          const bannerImageUrl = await uploadImage(selectedBannerImage);
          updatedLeague.bannerImageUrl = bannerImageUrl;
        }
  
        if (selectedLoserImage) {
          const loserImageUrl = await uploadImage(selectedLoserImage);
          updatedLeague.mostRecentLoser = {
            ...updatedLeague.mostRecentLoser,
            imageUrl: loserImageUrl
          };
        }
  
        if (isPasswordProtected && newPassword) {
          updatedLeague.password = newPassword;
        }
  
        await firebaseUpdateLeague(id, updatedLeague);
        setLeague(updatedLeague);
        setEditedLeague(updatedLeague);
        setIsEditing(false);
        setNewPassword('');
        setSelectedBannerImage(null);
        setSelectedLoserImage(null);
        setBannerImagePreview(updatedLeague.bannerImageUrl ?? null);
        setLoserImagePreview(updatedLeague.mostRecentLoser?.imageUrl ?? null);
      } catch (error) {
        console.error('Error updating league:', error);
        setError('Failed to update league');
      }
    }
  };

  const handleCancel = () => {
    setEditedLeague(league);
    setIsEditing(false);
    setSelectedLeagueImage(null);
    setSelectedLoserImage(null);
    setLeagueImagePreview(league?.imageUrl ?? null);
    setLoserImagePreview(league?.mostRecentLoser?.imageUrl ?? null);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    section?: keyof League
  ) => {
    const { name, value } = e.target;

    setEditedLeague((prev) => {
      if (!prev) return prev;

      if (section) {
        return {
          ...prev,
          [section]: {
            ...(prev[section] as Record<string, unknown>),
            [name]: value
          }
        };
      } else {
        return {
          ...prev,
          [name]: value
        };
      }
    });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, isBannerImage: boolean = false) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      if (isBannerImage) {
        setSelectedBannerImage(file);
        setBannerImagePreview(imageUrl);
      } else {
        setSelectedLoserImage(file);
        setLoserImagePreview(imageUrl);
      }
    }
  };

  const renderSidebar = () => {
    const navItems = [
      { path: "", icon: Home, label: "Home" },
      { path: "challenges", icon: Trophy, label: "Challenges" },
      { path: "record-book", icon: BookOpen, label: "Record Book" },
      { path: "settings", icon: Settings, label: "Settings" }
    ];

    return (
      <>
        <button 
          className="fixed top-20 left-4 z-50 md:hidden bg-[#18f7b1] text-black p-2 rounded-full shadow-lg"
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        <div className={`
          fixed left-0 top-0 h-full bg-gray-100 p-4 z-40 transition-all duration-300 ease-in-out
          flex flex-col justify-center
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          md:translate-x-0 md:sticky md:top-20 md:h-[calc(100vh-5rem)]
          w-64 md:w-72 lg:w-80
        `}>
          <nav>
            <ul className="space-y-6">
              {navItems.map((item) => (
                <li key={item.path}>
                  <Link 
                    to={item.path} 
                    className={`
                      flex items-center text-xl md:text-2xl font-semibold
                      ${location.pathname.endsWith(item.path) 
                        ? 'text-[#18f7b1]' 
                        : 'text-gray-700 hover:text-black'}
                    `}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <item.icon className="mr-4" size={28} />
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </>
    );
  };

  const getPunishmentUrl = (id: string) => {
    return `https://fantasyloser.com/punishments/${id}`;
  };

  const formatTitle = (key: string): string => {
    return key
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };



  const renderInviteSection = () => {
    if (!league?.id) return null;
  
    const inviteLink = league.inviteCode 
      ? `${window.location.origin}/league/${league.id}/join?code=${league.inviteCode}`
      : null;
  
    // If there's no invite code yet, show a message to the commissioner
    if (!league.inviteCode && isUserCommissioner) {
      return (
        <div className="mt-4 p-6 bg-gray-50 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold mb-4 text-black">League Invites</h3>
          <p className="text-gray-600 mb-4">Generate an invite code to allow team managers to claim their teams.</p>
          <button
            onClick={handleGenerateNewCode}
            disabled={isGeneratingNewCode}
            className={`px-6 py-3 rounded-lg font-semibold transition-all duration-200
              ${isGeneratingNewCode
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-[#18f7b1] text-black hover:bg-[#0fd494]'}`}
          >
            {isGeneratingNewCode ? 'Generating...' : 'Generate Invite Code'}
          </button>
        </div>
      );
    }
  
    // If there's no invite code and user is not commissioner, don't show anything
    if (!league.inviteCode) return null;
  
    return (
      <div className="mt-4 p-6 bg-gray-50 rounded-lg shadow-md">
        <h3 className="text-2xl font-semibold mb-4 text-black">League Invite Link</h3>
        
        {/* Success Message */}
        {inviteSuccess && (
          <div className="mb-4 bg-green-100 text-green-700 p-3 rounded flex items-center gap-2">
            <Check size={20} />
            {inviteSuccess}
          </div>
        )}
  
        {/* Error Message */}
        {inviteError && (
          <div className="mb-4 bg-red-100 text-red-700 p-3 rounded flex items-center gap-2">
            <AlertCircle size={20} />
            {inviteError}
          </div>
        )}
        
        <div className="space-y-4">
          {/* Invite Link Copy Section */}
          <div className="flex flex-col sm:flex-row gap-2">
            <input
              type="text"
              value={inviteLink || ''}
              readOnly
              className="flex-1 p-3 border rounded-lg bg-white text-gray-700 font-mono text-sm"
            />
            <button
              onClick={() => inviteLink && handleCopyLink(inviteLink)}
              disabled={!inviteLink}
              className={`px-6 py-3 rounded-lg font-semibold transition-all duration-200 flex items-center justify-center
                ${isInviteLinkCopied 
                  ? 'bg-green-500 text-white' 
                  : 'bg-[#18f7b1] text-black hover:bg-[#0fd494]'}`}
            >
              {isInviteLinkCopied ? (
                <>
                  <Check className="w-5 h-5 mr-2" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="w-5 h-5 mr-2" />
                  Copy Link
                </>
              )}
            </button>
          </div>
  
          {/* Commissioner Controls */}
          {isUserCommissioner && (
            <div className="pt-4 border-t border-gray-200">
              <button
                onClick={handleGenerateNewCode}
                disabled={isGeneratingNewCode}
                className={`px-6 py-3 rounded-lg font-semibold transition-all duration-200
                  ${isGeneratingNewCode
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-black text-white hover:bg-gray-800'}`}
              >
                {isGeneratingNewCode ? 'Generating...' : 'Generate New Invite Code'}
              </button>
              <p className="mt-2 text-sm text-gray-600">
                Warning: Generating a new code will invalidate the previous invite link.
              </p>
            </div>
          )}
  
          {/* Email Invite Form */}
          {isUserCommissioner && (
            <div className="pt-4 border-t border-gray-200">
              <h4 className="text-lg font-semibold mb-2">Send Invite by Email</h4>
              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  const emailInput = e.currentTarget.elements.namedItem('email') as HTMLInputElement;
                  if (emailInput.value) {
                    handleSendInviteEmail(emailInput.value);
                    emailInput.value = '';
                  }
                }}
                className="flex flex-col sm:flex-row gap-2"
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  className="flex-1 p-3 border rounded-lg"
                  required
                />
                <button
                  type="submit"
                  className="px-6 py-3 bg-[#18f7b1] text-black rounded-lg font-semibold hover:bg-[#0fd494] transition-all duration-200"
                  disabled={!inviteLink}
                >
                  Send Invite
                </button>
              </form>
            </div>
          )}
  
          <div className="mt-4 bg-blue-50 p-4 rounded-lg">
            <h4 className="font-semibold text-blue-800 mb-2">How it works:</h4>
            <ul className="text-sm text-blue-700 space-y-1 list-disc list-inside">
              <li>Share this link with your league members</li>
              <li>Team managers can select and verify their teams</li>
              <li>Verification requires access to their email</li>
              <li>Each team can only be claimed once</li>
            </ul>
          </div>
  
          {league.teamAssociations && league.teamAssociations.length > 0 && (
            <div className="mt-4 pt-4 border-t border-gray-200">
              <h4 className="font-semibold mb-2">Claimed Teams:</h4>
              <ul className="space-y-1">
                {league.teamAssociations.map((assoc, index) => (
                  <li key={index} className="flex justify-between items-center text-sm">
                    <span>{assoc.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };


  const renderLeagueInfo = () => {
    const inviteLink = `${window.location.origin}/league/${league!.id}/join`;
  
    return (
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h1 className="text-5xl uppercase mb-4 text-center font-eau">
          {league!.yahooLeague?.name || league!.name}
        </h1>
        
        <div className="flex justify-center items-center mb-6 text-gray-600">
          {isEditing ? (
            <div className="flex flex-wrap justify-center items-center gap-4 w-full">
              <div className="flex items-center">
                <label className="mr-2 font-semibold">Est.</label>
                <input
                  type="text"
                  name="yearStarted"
                  value={editedLeague?.yearStarted || ''}
                  onChange={(e) => handleInputChange(e)}
                  className="w-24 p-1 text-black rounded border border-gray-300"
                  placeholder="Year"
                />
              </div>
              <span className="mx-2">•</span>
              <div className="flex items-center">
                <label className="mr-2 font-semibold">Commissioner:</label>
                <input
                  type="text"
                  name="commish"
                  value={editedLeague?.commish || ''}
                  onChange={(e) => handleInputChange(e)}
                  className="w-48 p-1 text-black rounded border border-gray-300"
                  placeholder="Commissioner name"
                />
              </div>
            </div>
          ) : (
            <>
              <span className="mr-2">Est. {league!.yearStarted}</span>
              <span className="mx-2">•</span>
              <span>Commissioner: {league!.commish}</span>
            </>
          )}
        </div>    

        {renderInviteSection()}

        <LeagueSeasonDisplay 
          league={league!} 
          updateLeague={handleUpdateLeague} 
        />
      </div>
    );
  };
  
  const renderPunishment = () => (
    <div className="bg-black p-4 rounded mt-4">
      <h2 className="text-[#18f7b1] text-3xl font-bold mb-4 italic font-eau">PUNISHMENT TERMS</h2>
      
      <div className="mb-2">
        {isEditing ? (
          <div className="flex flex-wrap items-center">
            <div className="w-full sm:w-2/3 mb-2 sm:mb-0 sm:pr-2">
              <label className="block text-white text-base sm:text-xl font-bold uppercase">SELECTED PUNISHMENT:</label>
              <select
                name="selectedPunishment"
                value={editedLeague?.punishment?.selectedPunishment || ''}
                onChange={(e) => handleInputChange(e, 'punishment')}
                className="w-full p-2 text-black rounded mt-1"
              >
                <option value="">Select a punishment</option>
                {punishments.map((punishment) => (
                  <option key={punishment.id} value={punishment.id}>{punishment.title}</option>
                ))}
              </select>
            </div>
            {editedLeague?.punishment?.selectedPunishment && (
              <div className="w-full sm:w-1/3 sm:pl-2 flex items-end">
                <a
                  href={getPunishmentUrl(editedLeague.punishment.selectedPunishment)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#18f7b1] hover:bg-[#333333] text-black hover:text-white font-bold py-2 px-4 rounded inline-block w-full text-center"
                >
                  View Punishment
                </a>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-wrap items-center">
            <p className="text-white text-base sm:text-xl mr-4 mb-2 sm:mb-0">
              <span className="uppercase font-bold">SELECTED PUNISHMENT:</span>{' '}
              {punishments.find(p => p.id === league?.punishment?.selectedPunishment)?.title || 'N/A'}
            </p>
            {league?.punishment?.selectedPunishment && (
              <a
                href={getPunishmentUrl(league.punishment.selectedPunishment)}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#18f7b1] hover:bg-[#333333] text-black hover:text-white font-bold py-2 px-4 rounded inline-block"
              >
                View Punishment
              </a>
            )}
          </div>
        )}
      </div>
  
      <div className="block mt-2 text-white text-base sm:text-xl font-source">
        {renderField('title', isEditing ? editedLeague?.punishment?.title : league?.punishment?.title, 'punishment')}
      </div>
  
      <div className="mb-2">
        {isEditing ? (
          <div>
            <label className="block text-white text-base sm:text-xl font-bold uppercase">CUSTOM PUNISHMENT:</label>
            <textarea
              name="customPunishment"
              value={editedLeague?.punishment?.customPunishment || ''}
              onChange={(e) => handleInputChange(e, 'punishment')}
              className="w-full p-2 text-black rounded mt-1"
              rows={3}
            />
          </div>
        ) : (
          <div className="text-white text-base sm:text-xl">
            <span className="uppercase font-bold">CUSTOM PUNISHMENT:</span>{' '}
            {league?.punishment?.customPunishment || 'N/A'}
          </div>
        )}
      </div>
  
      {isEditing ? (
        <div className="mb-2">
          <label className="block mt-2 text-white text-base sm:text-xl font-bold uppercase">PUNISHMENT STATUS:</label>
          <select
            name="status"
            value={editedLeague?.punishment?.status || 'pending'}
            onChange={(e) => handleInputChange(e, 'punishment')}
            className="w-full p-2 text-black rounded mt-1"
          >
            <option value="approved">Approved</option>
            <option value="denied">Denied</option>
            <option value="pending">Pending</option>
          </select>
        </div>
      ) : (
        <div className="mb-2">
          <p className="text-white text-base sm:text-xl">
            <span className="uppercase font-bold">PUNISHMENT STATUS:</span>{' '}
            {league?.punishment?.status || 'N/A'}
          </p>
        </div>
      )}
    </div>
  );

  const renderField = (key: string, value: any, section?: keyof League) => {
    const shouldRender = isEditing || (value !== undefined && value !== '' && value !== 0);
    
    if (typeof value === 'object' || Array.isArray(value)) {
      return shouldRender ? (
        <div key={key} className="mb-2">
          <span className="uppercase font-bold text-base sm:text-xl">{formatTitle(key)}:</span> [Complex Data]
        </div>
      ) : null;
    }
  
    if (key === 'leagueUrl') {
      return (
        <div key={key} className={`mb-2 font-source ${shouldRender ? '' : 'hidden'}`}>
          {isEditing ? (
            <>
              <label className="block mt-2 uppercase font-bold text-base sm:text-xl">{formatTitle(key)}:</label>
              <input
                type="text"
                name={key}
                value={value?.toString() ?? ''}
                onChange={(e) => handleInputChange(e, section)}
                className="w-full p-2 text-black rounded"
              />
            </>
          ) : (
            <div className='text-base sm:text-xl flex'>
              <span className="uppercase font-bold inline-block mr-2">{formatTitle(key)}:</span>
              <span className="inline-block max-w-[calc(100%-120px)] truncate" title={value}>
                <a 
                  href={value.startsWith('http') ? value : `https://${value}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  {value}
                </a>
              </span>
            </div>
          )}
        </div>
      );
    }
  
    return (
      <div key={key} className={`mb-2 font-source ${shouldRender ? '' : 'hidden'}`}>
        {isEditing ? (
          <>
            <label className="block mt-2 uppercase font-bold text-base sm:text-xl">{formatTitle(key)}:</label>
            <input
              type="text"
              name={key}
              value={value?.toString() ?? ''}
              onChange={(e) => handleInputChange(e, section)}
              className="w-full p-2 text-black rounded"
            />
          </>
        ) : (
          <div className='text-base sm:text-xl flex'>
            <span className="uppercase font-bold inline-block mr-2">{formatTitle(key)}:</span>
            <span>{value}</span>
          </div>
        )}
      </div>
    );
  };

  
  const renderMostRecentLoser = () => {
    const loser = league?.yahooLeague?.lastPlace;
  
    return (
      <div className="bg-[#18f7b1] p-4 rounded-lg mt-4">
        <h2 className="text-3xl font-bold mb-4 text-black italic font-eau">MOST RECENT LOSER</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="text-black text-base sm:text-xl font-source">
            {isEditing ? (
              <>
                {renderField('name', editedLeague?.mostRecentLoser?.name, 'mostRecentLoser')}
                {renderField('fantasySeason', editedLeague?.mostRecentLoser?.fantasySeason, 'mostRecentLoser')}
              </>
            ) : (
              <>
                <div className='text-base sm:text-xl flex'>
                  <span className="uppercase font-bold inline-block mr-2">Name:</span>
                  <span>{loser?.name || league?.mostRecentLoser?.name || 'N/A'}</span>
                </div>
                <div className='text-base sm:text-xl flex'>
                  <span className="uppercase font-bold inline-block mr-2">Season:</span>
                  <span>{league?.yahooLeague?.season || league?.mostRecentLoser?.fantasySeason || 'N/A'}</span>
                </div>
              </>
            )}
            {isEditing ? (
              <>
                {renderField('record', editedLeague?.mostRecentLoser?.record, 'mostRecentLoser')}
                {renderField('winPercentage', editedLeague?.mostRecentLoser?.winPercentage, 'mostRecentLoser')}
                {renderField('totalSeasonPoints', editedLeague?.mostRecentLoser?.totalSeasonPoints, 'mostRecentLoser')}
                {renderField('totalPointsRanking', editedLeague?.mostRecentLoser?.totalPointsRanking, 'mostRecentLoser')}
              </>
            ) : (
              <>
                <div className='text-base sm:text-xl flex'>
                  <span className="uppercase font-bold inline-block mr-2">Record:</span>
                  <span>{loser ? `${loser.wins}-${loser.losses}-${loser.ties}` : (league?.mostRecentLoser?.record || 'N/A')}</span>
                </div>
                <div className='text-base sm:text-xl flex'>
                  <span className="uppercase font-bold inline-block mr-2">Win Percentage:</span>
                  <span>
                    {loser 
                      ? ((parseInt(loser.wins) / (parseInt(loser.wins) + parseInt(loser.losses) + parseInt(loser.ties))) * 100).toFixed(2) + '%'
                      : (league?.mostRecentLoser?.winPercentage || 'N/A')}
                  </span>
                </div>
                <div className='text-base sm:text-xl flex'>
                  <span className="uppercase font-bold inline-block mr-2">Total Season Points:</span>
                  <span>{loser?.points_for || league?.mostRecentLoser?.totalSeasonPoints || 'N/A'}</span>
                </div>
                <div className='text-base sm:text-xl flex'>
                  <span className="uppercase font-bold inline-block mr-2">Final Rank:</span>
                  <span>{loser?.rank || league?.mostRecentLoser?.totalPointsRanking || 'N/A'}</span>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col items-center justify-center">
            {isEditing ? (
              <div className="w-full">
                <label className="block mt-2 mb-2 uppercase font-bold text-base sm:text-xl">LOSER IMAGE:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, false)}
                  className="w-full p-2 text-black mb-2"
                />
                {loserImagePreview && (
                  <div className="w-full h-48 bg-transparent rounded-lg overflow-hidden">
                    <img
                      src={loserImagePreview}
                      alt="Most Recent Loser"
                      className="w-full h-full object-contain"
                    />
                  </div>
                )}
              </div>
            ) : (
              loserImagePreview && (
                <div className="w-full h-[15rem] bg-transparent rounded-lg overflow-hidden">
                  <img
                    src={loserImagePreview}
                    alt="Most Recent Loser"
                    className="w-full h-full object-contain"
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  };


  if (!league) {
    return <div>League not found</div>;
  }

  if (league.isPasswordProtected && !isPasswordCorrect) {
    return (
      <div className="flex flex-col min-h-screen bg-white text-black">
        <Navbar />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16 pt-[50px]">
        <h1 className="text-4xl font-bold mb-4 text-center">{league.name}</h1>
        <p className="text-center mb-4">This league is password protected.</p>
        <form onSubmit={handlePasswordSubmit} className="max-w-sm mx-auto">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="w-full p-2 border rounded mb-2"
            disabled={isVerifying}
          />
          <button
            type="submit"
            className={`w-full bg-[#18f7b1] hover:bg-black text-black hover:text-white px-4 py-2 rounded-lg ${isVerifying ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isVerifying}
          >
            {isVerifying ? 'Verifying...' : 'Submit'}
          </button>
        </form>
        {verificationError && (
          <p className="text-red-500 text-center mt-2">{verificationError}</p>
        )}
        {error && <p className="text-red-500 text-center mt-2">{error}</p>}
      </main>
      <Footer />
    </div>
    );
  }
  const renderStandingsTable = () => (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
      <h2 className="text-2xl font-semibold mb-4">Current Standings</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Rank</th>
              <th className="py-2 px-4 text-left">Team</th>
              <th className="py-2 px-4 text-center">W</th>
              <th className="py-2 px-4 text-center">L</th>
              <th className="py-2 px-4 text-center">T</th>
              <th className="py-2 px-4 text-center">Win %</th>
              <th className="py-2 px-4 text-right">Points For</th>
            </tr>
          </thead>
          <tbody>
            {standings.map((team, index) => (
              <tr key={team.team_id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="py-2 px-4">{team.team_standings.rank}</td>
                <td className="py-2 px-4">{team.name}</td>
                <td className="py-2 px-4 text-center">{team.team_standings.outcome_totals.wins}</td>
                <td className="py-2 px-4 text-center">{team.team_standings.outcome_totals.losses}</td>
                <td className="py-2 px-4 text-center">{team.team_standings.outcome_totals.ties}</td>
                <td className="py-2 px-4 text-center">
                  {((parseInt(team.team_standings.outcome_totals.wins) / 
                    (parseInt(team.team_standings.outcome_totals.wins) + 
                     parseInt(team.team_standings.outcome_totals.losses) + 
                     parseInt(team.team_standings.outcome_totals.ties))) * 100).toFixed(2)}%
                </td>
                <td className="py-2 px-4 text-right">{parseFloat(team.team_standings.points_for).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderLeagueHome = () => (
    <>
      <div className="mb-8 w-full h-64 relative mt-[12%]">
        {isEditing ? (
          <div className="mb-4">
            <label className="block mb-2">Banner Image:</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, true)}
              className="w-full p-2 text-black mb-2"
            />
          </div>
        ) : null}
        {bannerImagePreview && (
          <div className="w-full h-full bg-transparent overflow-hidden">
            <img
              src={bannerImagePreview}
              alt="League Banner"
              className="w-full h-full object-contain"
            />
          </div>
        )}
      </div>
  
      {renderLeagueInfo()}
      {standings.length > 0 && renderStandingsTable()}

  
      <div className="space-y-4">
        
        {renderPunishment()}
  
        {editedLeague && (
          <DeadbeatTracker 
            league={editedLeague} 
            isEditing={isEditing} 
            onLeagueChange={handleLeagueChange}
          />
        )}
  
  
       
  
        {error && (
          <div className="mt-2 text-red-500">
            {error}
          </div>
        )}
  
        {isEditing && (
          <div className="mt-8 flex justify-end space-x-4">
            <button 
              onClick={handleSave} 
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition duration-300"
            >
              Save Changes
            </button>
            <button 
              onClick={handleCancel} 
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition duration-300"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );

  if (isLoading) {
    return <StyledLoadingSpinner />;
  }

  if (!league) {
    return <div>League not found</div>;
  }

  if (league.isPasswordProtected && !isPasswordCorrect) {
    return (
      <div className="flex flex-col min-h-screen bg-white text-black">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-8 mt-16 pt-[50px]">
          <h1 className="text-4xl font-bold mb-4 text-center">{league.name}</h1>
          <p className="text-center mb-4">This league is password protected.</p>
          <form onSubmit={handlePasswordSubmit} className="max-w-sm mx-auto">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="w-full p-2 border rounded mb-2"
              disabled={isVerifying}
            />
            <button
              type="submit"
              className={`w-full bg-[#18f7b1] hover:bg-black text-black hover:text-white px-4 py-2 rounded-lg ${isVerifying ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isVerifying}
            >
              {isVerifying ? 'Verifying...' : 'Submit'}
            </button>
          </form>
          {verificationError && (
            <p className="text-red-500 text-center mt-2">{verificationError}</p>
          )}
          {error && <p className="text-red-500 text-center mt-2">{error}</p>}
        </main>
        <Footer />
      </div>
    );
  }

  const canEdit = user && league.commissionerId === user.uid;

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-black'}`}>
      <Navbar />
      <div className="container mx-auto px-4 py-8 mt-16 flex pt-[50px]">
        {renderSidebar()}
        <main className="flex-grow relative">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 mb-4" role="alert">
              <strong className="font-bold">Error: </strong>
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {canEdit && !isEditing && (
            <button 
              onClick={handleEdit} 
              className="absolute top-2 right-4 bg-[#18f7b1] hover:bg-black text-black hover:text-white px-3 py-2 rounded-full transition-colors duration-300 flex items-center shadow-md"
              aria-label="Edit League"
            >
              <Pencil size={20} />
              <span className="ml-2 hidden sm:inline">Edit League</span>
            </button>
          )}
          <div className="flex-grow ml-4">
            <Routes>
              <Route path="" element={renderLeagueHome()} />
              <Route path="challenges" element={<Challenges league={league} />} />
              <Route 
                path="record-book" 
                element={<RecordBook league={league} updateLeague={handleUpdateLeague} />} 
              />
              <Route 
                path="settings" 
                element={<LeagueSettings league={league} updateLeague={handleUpdateLeague} />} 
              />
            </Routes>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default LeaguePage;