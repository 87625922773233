import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

interface MetaHeadProps {
  title: string;
  description: string;
  image?: string;
  url?: string;
  type?: string;
  keywords?: string[];
  schema?: object;
}

// Default SEO keywords
const defaultKeywords = [
  'fantasy football punishments',
  'best fantasy football punishments',
  'fantasy football loser',
  'fantasy punishments',
  'punishments for fantasy football',
  'fantasy punishment',
  'worst fantasy football punishments',
  'good fantasy football punishments',
  'funny fantasy football punishments',
  'fantasy football loser punishments',
  'last place fantasy football punishments',
  'best fantasy punishments 2024',
  'punishment ideas',
  'fantasy loser punishments',
  'last place fantasy football'
];

const MetaHead = ({ 
  title, 
  description, 
  image = "https://fantasyloser.com/assets/FL_Logo_Primary_Stacked_Rev.png",
  url = "https://fantasyloser.com",
  type = "website",
  keywords = defaultKeywords,
  schema
}: MetaHeadProps) => {
  const location = useLocation();
  const currentUrl = url || `https://fantasyloser.com${location.pathname}`;
  const fullTitle = `${title} | Fantasy Loser`;

  // Organization Schema
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://fantasyloser.com/#organization",
    "name": "Fantasy Loser",
    "url": "https://fantasyloser.com",
    "logo": {
      "@type": "ImageObject",
      "@id": "https://fantasyloser.com/#logo",
      "inLanguage": "en-US",
      "url": "https://fantasyloser.com/assets/favicon_360.png",
      "contentUrl": "https://fantasyloser.com/assets/favicon_360.png",
      "width": 500,
      "height": 200,
      "caption": "Fantasy Loser - Fantasy Football League Management Platform"
    },
    "image": {
      "@id": "https://fantasyloser.com/#logo"
    },
    "sameAs": [
      "https://discord.gg/H6TADUTph2"
    ]
  };

  // WebPage Schema
  const webPageSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": `${currentUrl}#webpage`,
    "url": currentUrl,
    "name": fullTitle,
    "isPartOf": {
      "@id": "https://fantasyloser.com/#website"
    },
    "about": {
      "@id": "https://fantasyloser.com/#organization"
    },
    "primaryImageOfPage": {
      "@id": "https://fantasyloser.com/#logo"
    },
    "datePublished": "2024-01-01T00:00:00+00:00",
    "dateModified": new Date().toISOString(),
    "description": description,
    "inLanguage": "en-US"
  };

  // Combine schemas
  const combinedSchema = [
    organizationSchema,
    webPageSchema,
    schema
  ].filter(Boolean);

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={(keywords || []).join(', ')} />
      
      {/* Technical SEO */}
      <link rel="canonical" href={currentUrl} />
      <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      
      {/* Open Graph */}
      <meta property="og:locale" content="en-US" />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Fantasy Loser" />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      
      {/* Mobile */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#18f7b1" />
      
      {/* Structured Data */}
      {combinedSchema.map((schemaItem, index) => (
        <script key={index} type="application/ld+json">
          {JSON.stringify(schemaItem)}
        </script>
      ))}
    </Helmet>
  );
};

// Example meta data for different pages
export const homePageMeta = {
  title: "Fantasy Loser",
  description: "Fantasy Loser is your ultimate platform for fantasy football punishment ideas, weekly challenges, and league management tools. From hilarious last-place punishments to engaging weekly challenges, make your fantasy football league more exciting.",
  keywords: [
    ...defaultKeywords,
    'fantasy football league ideas',
    'fantasy football engagement',
    'commissioner tools',
    'league management platform',
    'fantasy football community'
  ]
};

export const explorePageMeta = {
  title: "Browse Fantasy Football Punishments & Last Place Ideas",
  description: "Discover the best fantasy football punishments and last place ideas for your league. Browse our collection of hilarious, memorable, and creative punishments that will make losing unforgettable.",
  keywords: [
    ...defaultKeywords,
    'fantasy football league punishments',
    'creative fantasy football punishments',
    'fantasy football punishment list',
    'fantasy football loser ideas',
    'fantasy football last place punishments'
  ]
};

export default MetaHead;