import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getContentByType, updatePunishmentOrder } from '../services/firebase';
import { ContentItem } from '../types';
import { useAuth } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import { ArrowUp, ArrowDown, Search, ArrowUpRight, ChevronDown } from 'lucide-react';
import Navbar from '../components/Navbar';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';
import MetaHead from '../components/ui/MetaHead';

const explorePageMeta = {
  title: "Browse Fantasy Football Punishments & Last Place Ideas",
  description: "Discover the best fantasy football punishments and last place ideas for your league. Browse our collection of hilarious, memorable, and creative punishments that will make losing unforgettable. From tattoos to challenges, find the perfect punishment for your fantasy league losers.",
  keywords: [
    'fantasy football punishments',
    'best fantasy football punishments',
    'last place fantasy football punishments',
    'fantasy football loser punishments',
    'funny fantasy football punishments',
    'fantasy punishment ideas',
    'last place punishments',
    'fantasy football loser ideas',
    'creative fantasy punishments',
    'worst fantasy football punishments',
    'punishment suggestions',
    'fantasy sports consequences',
    'league punishment ideas',
    'fantasy football forfeits',
    'loser challenges fantasy football'
  ]
};

interface FLRatingOption {
  value: string;
  label: string;
  level: number;
}

// Constants
const FL_RATING_OPTIONS = [
  { value: 'Pee Wee (L1)', label: 'Pee Wee', level: 1, badge: require('../assets/badge_L1.png') },
  { value: 'Two Hand Touch (L2)', label: 'Two Hand Touch', level: 2, badge: require('../assets/badge_L2.png') },
  { value: 'Full Contact (L3)', label: 'Full Contact', level: 3, badge: require('../assets/badge_L3.png') },
  { value: 'All Pro Elite (L4)', label: 'The Big Leagues', level: 4, badge: require('../assets/badge_L4.png') },
  { value: 'No Mercy (L5)', label: 'No Mercy', level: 5, badge: require('../assets/badge_L5.png') },
];

// Helper Functions
const extractFLRatingLevel = (rating: string): number => {
  const match = rating.match(/Level\s*(\d+)/i);
  return match ? parseInt(match[1], 10) : 0;
};

// Components
interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  label: string;
}

const Slider: React.FC<SliderProps> = ({ value, onChange, min, max, label }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  
  const calculateValue = (clientX: number): number => {
    if (!sliderRef.current) return value;
    
    const rect = sliderRef.current.getBoundingClientRect();
    const x = clientX - rect.left;
    const percentage = Math.max(0, Math.min(1, x / rect.width));
    const newValue = Math.round(min + percentage * (max - min));
    
    return Math.max(min, Math.min(max, newValue));
  };

  const handleMove = (clientX: number) => {
    const newValue = calculateValue(clientX);
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault();
        handleMove(e.clientX);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (isDragging && e.touches[0]) {
        e.preventDefault();
        handleMove(e.touches[0].clientX);
      }
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, onChange, value, min, max]);

  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <div className="w-full">
      <div className="flex justify-center items-center mb-2">
        <label className="text-white text-xl font-antonio mr-2">{label}</label>
        <span className="text-white text-lg font-antonio">[{value}]</span>
      </div>
      <div 
        ref={sliderRef}
        className="relative h-4 flex items-center cursor-pointer"
        onMouseDown={(e) => {
          e.preventDefault();
          setIsDragging(true);
          handleMove(e.clientX);
        }}
        onTouchStart={(e) => {
          setIsDragging(true);
          handleMove(e.touches[0].clientX);
        }}
        onClick={(e) => {
          if (!isDragging) {
            handleMove(e.clientX);
          }
        }}
      >
        <div className="absolute w-full h-1 bg-white rounded flex items-center">
          <div
            className="absolute h-full bg-[#18f7b1] rounded"
            style={{ width: `${percentage}%` }}
          />
          {Array.from({ length: max - min + 1 }, (_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 rounded-full bg-black"
              style={{ left: `${((i + min) / (max - min)) * 100}%` }}
            />
          ))}
        </div>
        <div
          className="absolute w-5 h-5 rounded-full pointer-events-none
                     border-[2.5px] border-[#18f7b1] bg-[#1A1A1A]"
          style={{ left: `calc(${percentage}% - 10px)` }}
        />
      </div>
    </div>
  );
};


const ViewToggle: React.FC<{
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
}> = ({ viewMode, setViewMode }) => (
  <div className="flex items-center gap-2 bg-[#1A1A1A] rounded-lg p-1">
    <button
      onClick={() => setViewMode('grid')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'grid' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="grid grid-cols-2 gap-0.5 w-5 h-5">
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
    <button
      onClick={() => setViewMode('list')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'list' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="flex flex-col gap-0.5 w-5 h-5">
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
  </div>
);

const ExplorePage: React.FC = () => {
  const [punishments, setPunishments] = useState<ContentItem[]>([]);
  const [filteredPunishments, setFilteredPunishments] = useState<ContentItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [embarrassmentLevel, setEmbarrassmentLevel] = useState(0);
  const [riskLevel, setRiskLevel] = useState(0);
  const [longTermEffects, setLongTermEffects] = useState(0);
  const [selectedFLRating, setSelectedFLRating] = useState<string>('');
  const [isReordering, setIsReordering] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const navigate = useNavigate();
  const { userRole } = useAuth();
  const [displayCount, setDisplayCount] = useState(20);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isLevelMenuOpen, setIsLevelMenuOpen] = useState(false);

  useEffect(() => {
    // Only setup observer if we have more items to load
    if (filteredPunishments.length <= displayCount) return;
  
    const observer = new IntersectionObserver(
      (entries) => {
        // Check if loader is visible and we're not already loading
        const target = entries[0];
        if (target.isIntersecting && !isLoadingMore) {
          setIsLoadingMore(true);
          // Small delay to prevent rapid multiple loads
          setTimeout(() => {
            setDisplayCount(prev => prev + 12); // Load 12 more items
            setIsLoadingMore(false);
          }, 500);
        }
      },
      {
        root: null, // Use viewport as root
        rootMargin: '100px', // Start loading before reaching the end
        threshold: 0.1 // Trigger when even 10% of the loader is visible
      }
    );
  
    // Start observing the loader element
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
  
    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, [filteredPunishments.length, displayCount, isLoadingMore]);

  useEffect(() => {
    const fetchPunishments = async () => {
      try {
        const content = await getContentByType('punishment');
        setPunishments(content);
        setFilteredPunishments(content);
        
        const tags = Array.from(new Set(content.flatMap(item => item.tags || [])));
        setAvailableTags(tags);
        
    
      } catch (error) {
        console.error('Error fetching punishments:', error);
    
      }
    };

    fetchPunishments();
  }, []);

  useEffect(() => {
    const filtered = punishments.filter(item => {
      const selectedLevel = FL_RATING_OPTIONS.find(
        option => option.value === selectedFLRating
      )?.level || 0;
      
      const itemLevel = extractFLRatingLevel(item.flRating || '');
      
      return (
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedTags.length === 0 || selectedTags.every(tag => item.tags?.includes(tag))) &&
        (embarrassmentLevel === 0 || parseInt(item.embarrassmentLevel) >= embarrassmentLevel) &&
        (riskLevel === 0 || parseInt(item.riskLevel) >= riskLevel) &&
        (longTermEffects === 0 || parseInt(item.longTermEffects) >= longTermEffects) &&
        (selectedFLRating === '' || itemLevel === selectedLevel)
      );
    });
    setFilteredPunishments(filtered);
  }, [searchTerm, selectedTags, embarrassmentLevel, riskLevel, longTermEffects, selectedFLRating, punishments]);

  const handleCardClick = (item: ContentItem) => {
    navigate(`/punishments/${item.id}`);
  };

  const handleTagToggle = (tag: string) => {
    setSelectedTags(prev =>
      prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
    );
  };

  const handleReorder = async (itemId: string, direction: 'up' | 'down') => {
    const currentIndex = filteredPunishments.findIndex(item => item.id === itemId);
    if (currentIndex === -1) return;
  
    const newPunishments = [...filteredPunishments];
    const item = newPunishments[currentIndex];
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
  
    if (newIndex < 0 || newIndex >= newPunishments.length) return;
  
    newPunishments.splice(currentIndex, 1);
    newPunishments.splice(newIndex, 0, item);
  
    const updatedPunishments = newPunishments.map((item, index) => ({
      ...item,
      order: index
    }));
  
    await Promise.all(updatedPunishments.map((item) => 
      updatePunishmentOrder(item.id, item.order)
    ));
  
    setFilteredPunishments(updatedPunishments);
  };

  const renderGridView = () => {
    const extractLevel = (flRating: string | undefined): number => {
      if (!flRating) return 1;
      
      // Check for format like "Cute & Fluffy (Level 1)"
      const levelMatch = flRating.match(/Level\s*(\d+)/i);
      if (levelMatch) {
        return parseInt(levelMatch[1]);
      }
      
      // Fallback check for format like "L1"
      const shortMatch = flRating.match(/L(\d+)/i);
      if (shortMatch) {
        return parseInt(shortMatch[1]);
      }
      
      return 1; // Default to level 1 if no match found
    };
  
    const getBadgeForLevel = (level: number) => {
      try {
        return require(`../assets/badge_L${level}.png`);
      } catch (e) {
        console.error(`Badge image not found for level ${level}`);
        return null;
      }
    };
  
    return (
      <>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredPunishments.slice(0, displayCount).map((item, index) => {
            const level = extractLevel(item.flRating);
            
            return (
              <div 
                key={item.id} 
                className="relative aspect-[4/5] rounded-lg overflow-hidden cursor-pointer
                          transform transition-transform duration-300 hover:scale-[1.02]
                          border border-[#18f7b1]"
                onClick={() => !isReordering && handleCardClick(item)}
              >
                {isReordering && userRole === 'admin' && (
                  <div className="absolute top-4 right-4 z-20 flex gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReorder(item.id, 'up');
                      }}
                      className="p-1 bg-white rounded-full disabled:opacity-50"
                      disabled={index === 0}
                    >
                      <ArrowUp size={20} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReorder(item.id, 'down');
                      }}
                      className="p-1 bg-white rounded-full disabled:opacity-50"
                      disabled={index === filteredPunishments.length - 1}
                    >
                      <ArrowDown size={20} />
                    </button>
                  </div>
                )}
  
                <div 
                  className="absolute inset-0 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${item.cardImage || item.image || '/placeholder-image.jpg'})`,
                  }}
                />
                
                <div className="absolute inset-0 bg-gradient-to-t 
                            from-black via-black/50 to-transparent
                            from-[0%] via-[20%] to-[100%]" />
                
                <div className="absolute bottom-6 left-4 right-4 space-y-2">
                <h3 className="text-xl sm:text-xl md:text-[28px] lg:text-[36px] 
                             font-bold font-antonio leading-tight text-white">
                  {item.title}
                </h3>
                  
                  <div className="flex items-center gap-2">
                    <div className="flex items-center bg-black/50 rounded-full px-3 py-1.5">
                      <img 
                        src={getBadgeForLevel(level)}
                        alt={`Level ${level} Badge`}
                        className="w-6 h-6 mr-2"
                      />
                      <span className="text-white font-roboto">
                        Level {level}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {filteredPunishments.length > displayCount && (
        <div 
          ref={loaderRef} 
          className="w-full flex justify-center items-center py-8"
        >
          {isLoadingMore ? (
            <div className="w-8 h-8 border-4 border-[#18f7b1] border-t-transparent rounded-full animate-spin" />
          ) : (
            <div className="h-8" /> 
          )}
        </div>
      )}
      </>
    );
  };

  const renderListView = () => {
    const extractLevel = (flRating: string | undefined): number => {
      if (!flRating) return 1;
      const levelMatch = flRating.match(/Level\s*(\d+)/i);
      if (levelMatch) {
        return parseInt(levelMatch[1]);
      }
      const shortMatch = flRating.match(/L(\d+)/i);
      if (shortMatch) {
        return parseInt(shortMatch[1]);
      }
      return 1;
    };
  
    const getBadgeForLevel = (level: number) => {
      try {
        return require(`../assets/badge_L${level}.png`);
      } catch (e) {
        console.error(`Badge image not found for level ${level}`);
        return null;
      }
    };
  
    return (
      <>
        <div className="space-y-4">
          {filteredPunishments.slice(0, displayCount).map((item, index) => {
            const level = extractLevel(item.flRating);
            const badgeImage = getBadgeForLevel(level);
  
            return (
              <div
                key={item.id}
                onClick={() => !isReordering && handleCardClick(item)}
                className="relative bg-white rounded-lg overflow-hidden cursor-pointer
                          hover:bg-[#FFC526] transition-all duration-300 border-b-2 border-black/50"
              >
                {/* Mobile Layout */}
                <div className="block lg:hidden">
                  <div className="flex flex-col p-4">
                    {/* Image and Content */}
                    <div className="flex gap-4 mb-4">
                      {/* Image */}
                      <div className="w-32 h-32 flex-shrink-0">
                        <div 
                          className="w-full h-full rounded-lg bg-cover bg-center"
                          style={{
                            backgroundImage: `url(${item.cardImage || item.image || '/placeholder-image.jpg'})`,
                          }}
                        />
                      </div>
                      
                      {/* Title Section */}
                      <div className="flex flex-col flex-grow">
                        <h3 className="text-xl font-antonio text-black font-bold mb-2">
                          {item.title}
                        </h3>
                        
                        {badgeImage && (
                          <div className="flex items-center gap-2 bg-black/10 rounded-full px-3 py-1.5 w-fit">
                            <img 
                              src={badgeImage}
                              alt={`Level ${level} Badge`}
                              className="w-5 h-5"
                            />
                            <span className="text-black font-roboto text-sm">
                              Level {level}
                            </span>
                          </div>
                        )}
                        
                        <p className="text-black/70 text-sm mt-2 line-clamp-2">
                          {item.subtitle}
                        </p>
                      </div>
                    </div>
  
                    {/* Ratings - Horizontal Layout */}
                    <div className="flex gap-2 w-full">
                      {/* Shame Rating */}
                      <div className="flex-1 flex flex-col items-center justify-center bg-gray-200 rounded-xl py-2">
                        <div className="text-xl font-bold font-antonio text-black">
                          {item.embarrassmentLevel}/5
                        </div>
                        <div className="text-sm font-antonio text-black">
                          Shame
                        </div>
                      </div>
  
                      {/* Danger Rating */}
                      <div className="flex-1 flex flex-col items-center justify-center bg-gray-200 rounded-xl py-2">
                        <div className="text-xl font-bold font-antonio text-black">
                          {item.longTermEffects}/5
                        </div>
                        <div className="text-sm font-antonio text-black">
                          Danger
                        </div>
                      </div>
  
                      {/* Effort Rating */}
                      <div className="flex-1 flex flex-col items-center justify-center bg-gray-200 rounded-xl py-2">
                        <div className="text-xl font-bold font-antonio text-black">
                          {item.riskLevel}/5
                        </div>
                        <div className="text-sm font-antonio text-black">
                          Effort
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                {/* Desktop Layout - Keep existing */}
                <div className="hidden lg:flex p-6 gap-6">
                  
                  {/* Left Section - Image */}
                  <div className="w-48 h-48 flex-shrink-0">
                    <div 
                      className="w-full h-full rounded-lg bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${item.cardImage || item.image || '/placeholder-image.jpg'})`,
                      }}
                    />
                  </div>
  
                  {/* Middle Section - Title, Badge, and Subtitle */}
                  <div className="flex-grow">
                    <div className="flex items-center gap-4 mb-2">
                      {badgeImage && (
                        <div className="flex items-center gap-2 bg-black/10 rounded-full px-3 py-1.5">
                          <img 
                            src={badgeImage}
                            alt={`Level ${level} Badge`}
                            className="w-6 h-6"
                          />
                          <span className="text-black font-roboto flex-shrink-0">
                            Level {level}
                          </span>
                        </div>
                      )}
                      <h3 className="text-3xl font-antonio text-black font-bold">
                        {item.title}
                      </h3>
                    </div>
                    
                    <p className="text-black/70 text-base line-clamp-3">
                      {item.subtitle}
                    </p>
                  </div>
  
                  {/* Right Section - Ratings */}
                  <div className="flex gap-4 flex-shrink-0 items-center">
                    {/* Keep your existing rating boxes for desktop */}
                    {/* Shame Rating */}
                    <div className="flex flex-col items-center justify-center bg-gray-200 rounded-xl w-[146px] h-[85px]">
                      <div className="text-2xl font-bold font-antonio text-black">
                        {item.embarrassmentLevel}/5
                      </div>
                      <div className="text-xl font-antonio text-black">
                        Shame
                      </div>
                    </div>
  
                    {/* Danger Rating */}
                    <div className="flex flex-col items-center justify-center bg-gray-200 rounded-xl w-[146px] h-[85px]">
                      <div className="text-2xl font-bold font-antonio text-black">
                        {item.longTermEffects}/5
                      </div>
                      <div className="text-xl font-antonio text-black">
                        Danger
                      </div>
                    </div>
  
                    {/* Effort Rating */}
                    <div className="flex flex-col items-center justify-center bg-gray-200 rounded-xl w-[146px] h-[85px]">
                      <div className="text-2xl font-bold font-antonio text-black">
                        {item.riskLevel}/5
                      </div>
                      <div className="text-xl font-antonio text-black">
                        Effort
                      </div>
                    </div>
                  </div>
  
                  {/* Reorder Controls */}
                  {isReordering && userRole === 'admin' && (
                    <div className="flex lg:flex-col gap-2 justify-center">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReorder(item.id, 'up');
                        }}
                        disabled={index === 0}
                        className="p-1 bg-black/5 rounded hover:bg-black/10 
                                 disabled:opacity-50 disabled:cursor-not-allowed"
                        aria-label="Move up"
                      >
                        ↑
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReorder(item.id, 'down');
                        }}
                        disabled={index === filteredPunishments.length - 1}
                        className="p-1 bg-black/5 rounded hover:bg-black/10 
                                 disabled:opacity-50 disabled:cursor-not-allowed"
                        aria-label="Move down"
                      >
                        ↓
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {filteredPunishments.length > displayCount && (
          <div className="flex justify-center mt-8">
            <button
              onClick={() => setDisplayCount(prev => prev + 10)}
              className="px-8 py-3 bg-white text-black border-2 border-black rounded-lg 
                       font-antonio text-xl transition-all duration-300
                       hover:bg-black hover:text-white"
            >
              View More Punishments
            </button>
          </div>
        )}
      </>
    );
  };
  
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <MetaHead
        title={explorePageMeta.title}
        description={explorePageMeta.description}
        keywords={explorePageMeta.keywords}
        url="https://fantasyloser.com/explore"
      />
      
      {/* Hero Section */}
      <div className="bg-[#1A1A1A] py-8 w-full pt-[50px] min-h-[350px]">
        <div className="container mx-auto px-4 pt-[50px]">
          <div className="max-w-6xl mx-auto space-y-8">
            <h1 className="text-4xl sm:text-6xl md:text-8xl font-antonio text-white text-center">
              Hall of Punishments
            </h1>
            
            <p className="text-white text-xl font-antonio text-center">
            The ultimate collection of punishments — whether you’re in a casual league or surrounded by fantasy sickos.
            </p>
  
            
            
          </div>
        </div>
      </div>
  
      {/* Main Content Section */}
      <div className="bg-white flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-wrap items-center gap-3 mb-6">
              {/* Search Input */}
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search Punishments"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-11 pr-4 py-3 rounded-[15px] border-2 border-black
                           font-antonio text-lg focus:outline-none focus:border-[#18f7b1]
                           placeholder:text-black/50"
                />
                <Search 
                  className="absolute left-4 top-1/2 -translate-y-1/2 text-black" 
                  size={20} 
                />
              </div>
  
              {/* Filter Button */}
              <button
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                className="px-6 py-3 rounded-[15px] border-2 border-black
                         font-antonio text-lg hover:bg-[#18f7b1] transition-colors
                         flex items-center gap-2 whitespace-nowrap"
              >
                Filter
                <ChevronDown 
                  className={`transform transition-transform duration-200 ${isFiltersOpen ? 'rotate-180' : ''}`}
                  size={20}
                />
              </button>
  
              {/* Level Selector */}
              <div className="relative">
              <button
                onClick={() => setIsLevelMenuOpen(!isLevelMenuOpen)}
                className="px-6 py-3 rounded-[15px] border-2 border-black
                        font-antonio text-lg hover:bg-[#18f7b1] transition-colors
                        flex items-center gap-2 whitespace-nowrap"
              >
                <div className="flex items-center gap-2">
                  {selectedFLRating ? (
                    <>
                      <img 
                        src={FL_RATING_OPTIONS.find(opt => opt.value === selectedFLRating)?.badge} 
                        alt="Level Badge" 
                        className="w-6 h-6"
                      />
                      <span>{FL_RATING_OPTIONS.find(opt => opt.value === selectedFLRating)?.label}</span>
                    </>
                  ) : (
                    <span className="text-gray-500">Select FL Rating Level</span>
                  )}
                </div>
                <ChevronDown 
                  className={`transform transition-transform duration-200 ${isLevelMenuOpen ? 'rotate-180' : ''}`}
                  size={20}
                />
              </button>
  
                {/* Level Dropdown Menu */}
                {isLevelMenuOpen && (
                  <div className="absolute left-0 right-0 mt-2 py-2 bg-white border-2 border-black rounded-lg
                                shadow-lg z-50 min-w-[200px]">
                    {/* Default/Placeholder Option */}
                    <button
                      onClick={() => {
                        setSelectedFLRating('');
                        setIsLevelMenuOpen(false);
                      }}
                      className="w-full px-4 py-2 flex items-center gap-3 hover:bg-[#18f7b1]
                                transition-colors text-gray-500"
                    >
                      <span className="font-antonio">Select FL Rating Level</span>
                    </button>
                    
                    {/* Divider */}
                    <div className="my-1 border-b border-gray-200" />

                    {/* Actual Options */}
                    {FL_RATING_OPTIONS.map((option) => (
                      <button
                        key={option.value}
                        onClick={() => {
                          setSelectedFLRating(option.value);
                          setIsLevelMenuOpen(false);
                        }}
                        className="w-full px-4 py-2 flex items-center gap-3 hover:bg-[#18f7b1]
                                transition-colors"
                      >
                        <img 
                          src={option.badge} 
                          alt={`Level ${option.level} Badge`} 
                          className="w-6 h-6"
                        />
                        <span className="font-antonio">{option.label}</span>
                      </button>
                    ))}
                  </div>
                )}
              </div>
  
              {userRole === 'admin' && (
                <button
                  onClick={() => setIsReordering(!isReordering)}
                  className="px-6 py-3 rounded-full border-2 border-black
                           font-antonio text-lg bg-[#18f7b1] hover:bg-[#0fd494] 
                           transition-colors whitespace-nowrap"
                >
                  {isReordering ? 'Finish Reordering' : 'Reorder Punishments'}
                </button>
              )}

            <a href="https://h4hcbpgn1q9.typeform.com/to/E21Gfvu6"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 px-6 py-2.5 bg-[#1a1a1a] border-[#18f7b1] border-[1px]
                       text-white rounded-[15px]  transition-colors font-antonio text-xl"
            >
              Submit a Punishment
              <ArrowUpRight className="w-5 h-5" />
            </a>
  
              <ViewToggle viewMode={viewMode} setViewMode={setViewMode} />
            </div>
  
            {/* Filter Dropdown */}
            <div 
              className={`transition-all duration-300 overflow-hidden bg-white rounded-lg mb-6
                       border-2 border-black ${isFiltersOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6">
                <div>
                  <h3 className="text-black font-antonio mb-2">Shame Level</h3>
                  <input
                    type="range"
                    min="0"
                    max="5"
                    value={embarrassmentLevel}
                    onChange={(e) => setEmbarrassmentLevel(Number(e.target.value))}
                    className="w-full"
                  />
                  <div className="text-black text-center mt-2 font-antonio">{embarrassmentLevel}/5</div>
                </div>
  
                <div>
                  <h3 className="text-black font-antonio mb-2">Risk Level</h3>
                  <input
                    type="range"
                    min="0"
                    max="5"
                    value={riskLevel}
                    onChange={(e) => setRiskLevel(Number(e.target.value))}
                    className="w-full"
                  />
                  <div className="text-black text-center mt-2 font-antonio">{riskLevel}/5</div>
                </div>
  
                <div>
                  <h3 className="text-black font-antonio mb-2">Long-term Effects</h3>
                  <input
                    type="range"
                    min="0"
                    max="5"
                    value={longTermEffects}
                    onChange={(e) => setLongTermEffects(Number(e.target.value))}
                    className="w-full"
                  />
                  <div className="text-black text-center mt-2 font-antonio">{longTermEffects}/5</div>
                </div>
              </div>
            </div>
  
            {/* Content */}
            {viewMode === 'grid' ? renderGridView() : renderListView()}
  
            {filteredPunishments.length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-500 text-lg">
                  No punishments found matching your criteria.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};
  export default ExplorePage;
