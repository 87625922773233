import React, { useState, useRef, useEffect } from 'react';
import { Search, X, Share2, Check } from 'lucide-react';
import { WheelSegment } from '../types/wheel';
import { ContentItem } from '../types';
import { useNavigate } from 'react-router-dom';
import { saveWheelData, saveResultData, getWheelData, getResultData } from '../services/WheelSharingService';

interface WheelOfShameProps {
  selectedPunishments: WheelSegment[];
  onSearchClick: () => void;
  onLoadSharedWheel?: (punishments: WheelSegment[]) => void;
  selectedSegment: WheelSegment | null;
  onSpinComplete: (segment: WheelSegment) => void;
  onCloseResult: () => void;
  punishments: ContentItem[];
  isSpinnable: boolean;
}

const WheelOfShame: React.FC<WheelOfShameProps> = ({
  selectedPunishments,
  onSearchClick,
  onLoadSharedWheel,
  selectedSegment,
  onSpinComplete,
  onCloseResult,
  punishments,
  isSpinnable
}) => {
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [canvasSize, setCanvasSize] = useState(800);
  const initialLoadDone = useRef(false);
  const [copied, setCopied] = useState(false);
  const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);

  const colors = ['#18f7b1', '#1a1a1a'];

  useEffect(() => {
    const updateCanvasSize = () => {
      // Get viewport dimensions
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
    
      // Calculate the shortest viewport dimension to maintain aspect ratio
      const minViewportDim = Math.min(viewportWidth, viewportHeight);
      
      // Calculate relative base size - 80% of shortest viewport dimension
      const relativeSize = minViewportDim * 0.8;
      
      // Apply minimum and maximum constraints
      // Min size: 280px to ensure wheel is always readable
      // Max size: 1000px to prevent wheel from becoming too large
      const size = Math.min(Math.max(relativeSize, 280), 1000);
      
      // If viewport width is small, further constrain to 90% of viewport width
      // This ensures the wheel doesn't overflow on mobile
      if (viewportWidth < 640) {
        const mobileSize = Math.min(viewportWidth * 0.9, size);
        setCanvasSize(mobileSize);
        return;
      }
    
      setCanvasSize(size);
    };
  
    // Initial size calculation
    updateCanvasSize();
  
    // Add resize listener
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  const getBadgeForLevel = (level: number) => {
    try {
      return require(`../assets/badge_${level}.png`);
    } catch (e) {
      console.error(`Badge image not found for level ${level}`);
      return null;
    }
  };

  const extractLevel = (flRating: string | undefined): number => {
    if (!flRating) return 1;
    
    // Check for format like "Cute & Fluffy (Level 1)"
    const levelMatch = flRating.match(/Level\s*(\d+)/i);
    if (levelMatch) {
      return parseInt(levelMatch[1]);
    }
    
    // Fallback check for format like "L1"
    const shortMatch = flRating.match(/L(\d+)/i);
    if (shortMatch) {
      return parseInt(shortMatch[1]);
    }
    
    return 1; // Default to level 1 if no match found
  };

  const handlePunishmentClick = (id: string) => {
    window.open(`/punishments/${id}`, '_blank');
  };

  const getFullPunishmentDetails = (segmentId: string) => {
    return punishments.find(p => p.id === segmentId);
  };

  useEffect(() => {
    if (!initialLoadDone.current) {
      const loadWheelDataFromParams = async () => {
        const params = new URLSearchParams(window.location.search);
        const wheelId = params.get('wheel');
        const resultId = params.get('result');
  
        if (wheelId && onLoadSharedWheel) {
          try {
            const wheelData = await getWheelData(wheelId);
            if (wheelData) {
              onLoadSharedWheel(wheelData.segments);
            }
          } catch (error) {
            console.error('Error loading wheel data:', error);
          }
        }
  
        if (resultId) {
          try {
            const resultData = await getResultData(resultId);
            if (resultData && onLoadSharedWheel) {
              onLoadSharedWheel(resultData.punishments);
              onSpinComplete(resultData.result);
              window.history.replaceState({}, '', '/wheelofshame');
            }
          } catch (error) {
            console.error('Error loading result data:', error);
          }
        }
      };
  
      loadWheelDataFromParams();
      initialLoadDone.current = true;
    }
  }, [onLoadSharedWheel, onSpinComplete]);

  useEffect(() => {
    drawWheel();
  }, [selectedPunishments, rotation, canvasSize]);

  const drawWheel = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const padding = 20;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = (Math.min(centerX, centerY) - padding);

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (selectedPunishments.length === 0) {
      const segments = 12;
      const anglePerSegment = (2 * Math.PI) / segments;

      for (let i = 0; i < segments; i++) {
        const startAngle = i * anglePerSegment + rotation;
        const endAngle = (i + 1) * anglePerSegment + rotation;
        
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.fillStyle = colors[i % 2];
        ctx.fill();
        ctx.closePath();
      }
    } else {
      const anglePerSegment = (2 * Math.PI) / selectedPunishments.length;

      selectedPunishments.forEach((punishment, i) => {
        const startAngle = i * anglePerSegment + rotation;
        const endAngle = (i + 1) * anglePerSegment + rotation;
        
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.fillStyle = colors[i % 2];
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(
          centerX + Math.cos(startAngle) * radius,
          centerY + Math.sin(startAngle) * radius
        );
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();
      });

      // Scale font size based on canvas size
      const fontSize = Math.max(12, Math.floor(canvasSize * 0.03));
      ctx.save();
      ctx.font = `${fontSize}px antonio`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      selectedPunishments.forEach((punishment, i) => {
        const angle = i * anglePerSegment + anglePerSegment / 2 + rotation;
        const textRadius = radius * 0.65;
        const x = centerX + Math.cos(angle) * textRadius;
        const y = centerY + Math.sin(angle) * textRadius;
        
        ctx.save();
        ctx.translate(x, y);
        
        let textRotation = angle;
        if (angle > Math.PI / 2 && angle < 3 * Math.PI / 2) {
          textRotation += Math.PI;
        }
        ctx.rotate(textRotation);
        
        ctx.fillStyle = colors[(i + 1) % 2];
        ctx.fillText(punishment.title, 0, 0);
        
        ctx.restore();
      });
      ctx.restore();
    }

    // Draw center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius * 0.15, 0, 2 * Math.PI);
    ctx.fillStyle = '#1a1a1a';
    ctx.fill();
    ctx.strokeStyle = '#18f7b1';
    ctx.lineWidth = 4;
    ctx.stroke();
    ctx.closePath();
  };

  const spinWheel = () => {
    if (!isSpinnable || isSpinning || selectedPunishments.length === 0) return;
  
    setIsSpinning(true);
    const spinDuration = 4000;
    const startTime = performance.now();
    
    const baseSpins = 5 + Math.random() * 3;
    const totalSpins = baseSpins * 2 * Math.PI;
    const segmentAngle = (2 * Math.PI) / selectedPunishments.length;
    const randomOffset = Math.random() * segmentAngle;
    const endRotation = totalSpins + randomOffset;
  
    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / spinDuration, 1);
      
      const easeOut = (t: number) => 1 - Math.pow(1 - t, 3);
      const currentRotation = endRotation * easeOut(progress);
      
      setRotation(currentRotation);
  
      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setIsSpinning(false);
        
        const finalRotation = currentRotation % (2 * Math.PI);
        const indicatorAngle = (((-finalRotation + Math.PI * 2) % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
        const winningIndex = Math.floor(indicatorAngle / segmentAngle);
        const winner = selectedPunishments[winningIndex];
        
        onSpinComplete(winner);
      }
    };
  
    requestAnimationFrame(animate);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full p-0 sm:p-4">
      <div className="relative w-full flex justify-center items-center py-8">
      <div 
        className="relative aspect-square" 
        style={{ 
          width: canvasSize, 
          height: canvasSize,
          maxWidth: '95vw',
          maxHeight: '95vh'
        }}
      >
        <canvas
          ref={canvasRef}
          width={canvasSize}
          height={canvasSize}
          className={`w-full h-full ${isSpinnable ? 'cursor-pointer' : 'cursor-default'}`}
          onClick={spinWheel}
        />
          <div className="absolute top-1/2 right-0 -translate-y-1/2">
            <div 
              className="w-0 h-0 
                        border-t-[15px] sm:border-t-[20px] md:border-t-[25px] border-t-transparent 
                        border-b-[15px] sm:border-b-[20px] md:border-b-[25px] border-b-transparent 
                        border-r-[20px] sm:border-r-[30px] md:border-r-[35px] border-r-[#18f7b1]
                        relative"
              style={{
                filter: 'drop-shadow(-1px 0 0 black) drop-shadow(1px 0 0 black) drop-shadow(0 -1px 0 black) drop-shadow(0 1px 0 black)'
              }}
            />
          </div>
        </div>
      </div>

      {selectedSegment && (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 overflow-y-auto">
    <div className="bg-white rounded-xl w-full max-w-[742px] mx-4 overflow-hidden">
      {/* Header Section */}
      <div className="relative pt-8 px-4 sm:px-8">
        <button
          onClick={onCloseResult}
          className="absolute top-4 right-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Close modal"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-left mb-6">
          <h2 className="text-3xl sm:text-5xl font-antonio font-bold mb-2">
            The Wheel Has Spoken
          </h2>
          <p className="text-base sm:text-lg text-gray-600">
            Your punishment has been chosen...
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4 sm:p-8">
        {/* Punishment Card */}
        <div className="bg-gray-50 rounded-xl overflow-hidden mb-8">
          <div className="flex flex-col sm:flex-row">
            {/* Image Section */}
            <div className="w-full sm:w-72 h-48 sm:h-auto relative">
              {getFullPunishmentDetails(selectedSegment.id)?.image ? (
                <>
                  <div 
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${getFullPunishmentDetails(selectedSegment.id)?.image})`
                    }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                </>
              ) : selectedSegment.id.startsWith('custom-') ? (
                <div className="absolute inset-0 flex items-center justify-center bg-black">
                  <img 
                    src={require("../assets/FL_Logo_FL_Icon_Primary_2.png")}
                    alt="Custom Punishment"
                    className="w-3/4 h-3/4 object-contain opacity-50"
                  />
                </div>
              ) : (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
                  <span className="text-gray-400">No image</span>
                </div>
              )}
              
                {/* Badge */}
                <div className="absolute bottom-4 right-4">
                <img 
                  src={getBadgeForLevel(extractLevel(selectedSegment.flRating))}
                  alt={`Level ${extractLevel(selectedSegment.flRating)} Badge`}
                  className="w-8 h-8"
                />
              </div>
            </div>

            {/* Content Section */}
            <div className="flex-1 p-6">
              <h3 className="text-2xl sm:text-3xl font-antonio font-bold mb-4">
                {selectedSegment.title}
              </h3>
              <p className="text-gray-600 text-base sm:text-lg">
                {selectedSegment.id.startsWith('custom-') 
                  ? selectedSegment.subtitle 
                  : getFullPunishmentDetails(selectedSegment.id)?.subtitle}
              </p>
              {!selectedSegment.id.startsWith('custom-') && (
                <p className="text-[#18f7b1] mt-4 font-antonio cursor-pointer" 
                   onClick={() => handlePunishmentClick(selectedSegment.id)}>
                  Click to view full details ↗
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Share Buttons */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <button
            onClick={async () => {
              if (!selectedPunishments?.length) return;
              
              setIsGeneratingUrl(true);
              try {
                const wheelId = await saveWheelData("Wheel of Shame", selectedPunishments);
                const url = `${window.location.origin}/wheelofshame/?wheel=${wheelId}`;
                
                await navigator.clipboard.writeText(url);
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              } catch (error) {
                console.error('Error generating share link:', error);
                alert('Failed to generate share link. Please try again.');
              } finally {
                setIsGeneratingUrl(false);
              }
            }}
            disabled={isGeneratingUrl}
            className="py-4 px-6 bg-white border-2 border-black rounded-lg 
                     hover:bg-gray-50 transition-colors font-antonio text-lg
                     flex items-center justify-center gap-2"
          >
            {copied ? (
              <>
                <Check className="w-5 h-5" />
                Copied!
              </>
            ) : isGeneratingUrl ? (
              <>
                <div className="w-5 h-5 border-2 border-t-transparent border-black rounded-full animate-spin"></div>
                Generating...
              </>
            ) : (
              <>
                <Share2 className="w-5 h-5" />
                Share Wheel
              </>
            )}
          </button>
          <button
            onClick={async () => {
              if (!selectedPunishments?.length || !selectedSegment) return;
              
              setIsGeneratingUrl(true);
              try {
                const resultId = await saveResultData(
                  "Wheel of Shame", 
                  selectedPunishments, 
                  selectedSegment
                );
                const url = `${window.location.origin}/wheelofshame/?result=${resultId}`;
                
                await navigator.clipboard.writeText(url);
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              } catch (error) {
                console.error('Error sharing result:', error);
                alert('Failed to share result. Please try again.');
              } finally {
                setIsGeneratingUrl(false);
              }
            }}
            disabled={isGeneratingUrl}
            className="py-4 px-6 bg-black text-white rounded-lg 
                      hover:bg-[#18f7b1] hover:text-black transition-colors font-antonio text-lg
                      flex items-center justify-center gap-2"
          >
            {copied ? (
              <>
                <Check className="w-5 h-5" />
                Copied!
              </>
            ) : isGeneratingUrl ? (
              <>
                <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                Generating...
              </>
            ) : (
              <>
                <Share2 className="w-5 h-5" />
                Share Result
              </>
            )}
          </button>
        </div>
      </div>
    </div>

    {/* Copied Notification */}
    {copied && (
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 
                   bg-black text-white px-4 py-2 rounded-lg text-center z-50">
        Copied to clipboard!
      </div>
    )}
  </div>
  
)}
    </div>
  );
};

export default WheelOfShame;