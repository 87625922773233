import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { MarkdownField } from '../components/ui/MarkDown';

const defaultTermsOfService = `# Terms of Service

Last Updated: January 25, 2025

## Agreement to Terms
By accessing and using Fantasy Loser's website and services, you agree to be bound by these Terms of Service and all applicable laws and regulations.

[Rest of the terms of service content...]`;

const TermsOfService: React.FC = () => {
  const { userRole } = useAuth();
  const [content, setContent] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'legal', 'terms-of-service');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setContent(data.content);
          setEditedContent(data.content);
        } else {
          // Initialize with default content
          await setDoc(docRef, { 
            content: defaultTermsOfService,
            lastUpdated: new Date()
          });
          setContent(defaultTermsOfService);
          setEditedContent(defaultTermsOfService);
        }
      } catch (error) {
        console.error('Error fetching terms of service:', error);
        setError('Failed to load terms of service content. Please try again later.');
      }
    };

    fetchContent();
  }, []);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError(null);
      
      const docRef = doc(db, 'legal', 'terms-of-service');
      await setDoc(docRef, {
        content: editedContent,
        lastUpdated: new Date()
      });
      
      setContent(editedContent);
      setIsEditing(false);
      alert('Terms of Service updated successfully');
    } catch (error) {
      console.error('Error saving terms of service:', error);
      setError('Failed to save changes. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    if (editedContent !== content) {
      setShowConfirmDialog(true);
    } else {
      setIsEditing(false);
    }
  };

  if (content === null) {
    return (
      <div className="min-h-screen flex flex-col bg-white">
        <Navbar />
        <main className="flex-grow container mx-auto px-4 py-8 mt-16">
          <div className="flex justify-center items-center h-full">
            {error || 'Loading...'}
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16">
        {userRole === 'admin' && !isEditing && (
          <button 
            onClick={() => setIsEditing(true)}
            className="mb-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          >
            Edit Terms of Service
          </button>
        )}
        
        {error && (
          <div className="mb-4 text-red-500">{error}</div>
        )}
        
        {isEditing ? (
          <div className="space-y-4 mb-8">
            <MarkdownField
              value={editedContent}
              onChange={(value) => setEditedContent(value || '')}
              label="Edit Terms of Service"
              minHeight={500}
            />
            <div className="flex space-x-4">
              <button
                onClick={handleSave}
                disabled={isSaving}
                className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
              <button
                onClick={handleCancel}
                disabled={isSaving}
                className="px-4 py-2 border border-gray-300 hover:bg-gray-100 rounded disabled:opacity-50"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="prose prose-lg max-w-4xl">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        )}

        {showConfirmDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
              <h3 className="text-lg font-semibold mb-2">Discard Changes?</h3>
              <p className="text-gray-600 mb-4">
                You have unsaved changes. Are you sure you want to discard them?
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowConfirmDialog(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                >
                  Continue Editing
                </button>
                <button
                  onClick={() => {
                    setEditedContent(content);
                    setIsEditing(false);
                    setShowConfirmDialog(false);
                  }}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Discard Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default TermsOfService;