// src/components/ui/ScrollToTop.tsx
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    // Force scroll to top with both methods
    window.scrollTo(0, 0);
    
    // For Safari and some mobile browsers
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    
    // Wait a small amount of time and scroll again to ensure it happened
    // after any content rendering
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }, 50);
    
    return () => clearTimeout(timeoutId);
  }, [pathname]);
  
  return null;
};

export default ScrollToTop;