import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trash2 } from 'lucide-react';
import Navbar from '../components/Navbar';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { createLeague, getLeaguesByCommissioner, deleteLeague, League } from '../services/firebase';
import Footer from '../components/Footer';

const CommissionerDashboard: React.FC = () => {
  const { user } = useAuth();
  const { theme } = useTheme();
  const [leagues, setLeagues] = useState<League[]>([]);
  const [newLeagueName, setNewLeagueName] = useState('');
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteLeagueId, setDeleteLeagueId] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      fetchLeagues();
    }
  }, [user]);

  const fetchLeagues = async () => {
    if (user) {
      try {
        const fetchedLeagues = await getLeaguesByCommissioner(user.uid);
        setLeagues(fetchedLeagues);
      } catch (error) {
        console.error('Error fetching leagues:', error);
        setError('Failed to fetch leagues. Please try again.');
      }
    }
  };

  const handleCreateLeague = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
  
    if (!user) {
      setError('You must be logged in to create a league.');
      return;
    }
  
    if (!newLeagueName.trim()) {
      setError('League name cannot be empty.');
      return;
    }
  
    if (isPasswordProtected && !password.trim()) {
      setError('Password cannot be empty for protected leagues.');
      return;
    }
  
    try {
      const leagueData: Omit<League, 'id'> & { password?: string } = {
        name: newLeagueName,
        commissionerId: user.uid,
        commish: user.displayName || 'Unknown',
        isPasswordProtected,
        yearStarted: '',
        mostRecentChamp: '',
        recentLoser:'',
        leagueUrl: '',
        recordBookLastUpdated:1, 
        punishment: {
          title: '',
          link: '',
          status: 'pending',
          selectedPunishment: '',
          customPunishment: '',
        },
        inviteCode: '',
        mostRecentLoser: {
          name: '',
          fantasySeason: '',
          record: '',
          winPercentage: '',
          totalSeasonPoints: '',
          totalPointsRanking: '',
          imageUrl: ''
        },
        deadbeatTracker: [],
        videoLibrary: [],
        playerProfiles: [],
        recordBook: [],
      };
  
      if (isPasswordProtected) {
        leagueData.password = password;
      }
  
      await createLeague(leagueData);
      setNewLeagueName('');
      setIsPasswordProtected(false);
      setPassword('');
      fetchLeagues();
    } catch (error) {
      console.error('Error creating league:', error);
      setError('Failed to create league. Please try again.');
    }
  };

  const handleDeleteLeague = async (leagueId: string, leagueName: string) => {
    if (!window.confirm(`Are you sure you want to delete "${leagueName}"? This action cannot be undone.`)) {
      return;
    }

    setIsDeleting(true);
    setDeleteLeagueId(leagueId);
    setError(null);

    try {
      await deleteLeague(leagueId);
      setLeagues(prevLeagues => prevLeagues.filter(league => league.id !== leagueId));
    } catch (error) {
      console.error('Error deleting league:', error);
      setError('Failed to delete league. Please try again.');
    } finally {
      setIsDeleting(false);
      setDeleteLeagueId(null);
    }
  };

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-[#333333] text-white' : 'bg-white text-[#333333] '}`}>
      <Navbar/>
      <main className="container mx-auto px-4 py-8 mt-16 pt-[50px]">
        <h1 className={`text-4xl font-bold mb-8 ${theme === 'dark' ? 'text-[#18f7b1]' : 'text-[#0fd494]'}`}>
          Commissioner Dashboard
        </h1>
        
        <section className="mb-12 bg-black rounded-lg p-6">
          <h2 className={`text-2xl font-semibold mb-4 text-[#18f7b1]`}>
            Your Leagues
          </h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleCreateLeague} className="mb-6">
            <input
              type="text"
              value={newLeagueName}
              onChange={(e) => setNewLeagueName(e.target.value)}
              placeholder="New League Name"
              className="mr-2 p-2 border rounded text-black w-full md:w-auto mb-2 md:mb-0"
            />
            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                id="isPasswordProtected"
                checked={isPasswordProtected}
                onChange={(e) => setIsPasswordProtected(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="isPasswordProtected" className="text-white">Password Protected</label>
            </div>
            {isPasswordProtected && (
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                className="mt-2 p-2 border rounded text-black w-full md:w-auto"
              />
            )}
            <button 
              type="submit" 
              className="bg-[#18f7b1] text-black px-4 py-2 rounded hover:bg-[#0fd494] transition duration-300 w-full md:w-auto mt-2"
            >
              Create League
            </button>
          </form>
          <ul className="space-y-2">
            {leagues.map((league) => (
              <li key={league.id} className="bg-gray-800 rounded p-3 flex justify-between items-center">
                <Link 
                  to={`/league/${league.id}`} 
                  className="text-white hover:text-[#18f7b1] transition duration-300"
                >
                  {league.name}
                </Link>
                <div className="flex items-center space-x-4">
                  {league.isPasswordProtected && (
                    <span className="text-[#18f7b1] text-sm">🔒 Protected</span>
                  )}
                  <button
                    onClick={() => handleDeleteLeague(league.id, league.name)}
                    disabled={isDeleting && deleteLeagueId === league.id}
                    className={`text-gray-400 hover:text-red-500 transition-colors duration-300 p-2 
                      rounded-full hover:bg-red-500/10 disabled:opacity-50 disabled:cursor-not-allowed`}
                    title="Delete League"
                  >
                    <Trash2 size={18} className={isDeleting && deleteLeagueId === league.id ? 'opacity-50' : ''} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default CommissionerDashboard;