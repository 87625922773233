import { db, auth } from './firebase';
import { collection, addDoc, getDoc, doc } from 'firebase/firestore';
import { WheelSegment } from '../types/wheel';

// Type for the wheel data to store
interface WheelData {
  wheelName: string;
  segments: WheelSegment[];
  createdAt: Date;
  createdBy?: string;  // Optional to support anonymous sharing
}

// Type for the result data to store
interface ResultData {
  wheelName: string;
  punishments: WheelSegment[];
  result: WheelSegment;
  createdAt: Date;
  createdBy?: string;  // Optional to support anonymous sharing
}

export const saveWheelData = async (wheelName: string, segments: WheelSegment[]): Promise<string> => {
  try {
    const wheelData: WheelData = {
      wheelName,
      segments,
      createdAt: new Date()
    };
    
    const docRef = await addDoc(collection(db, 'wheelConfigurations'), wheelData);
    return docRef.id;
  } catch (error) {
    console.error('Error saving wheel configuration:', error);
    throw new Error('Failed to save wheel configuration');
  }
};

export const saveResultData = async (wheelName: string, punishments: WheelSegment[], result: WheelSegment): Promise<string> => {
  try {
    const resultData: ResultData = {
      wheelName,
      punishments,
      result,
      createdAt: new Date()
    };
    
    const docRef = await addDoc(collection(db, 'wheelResults'), resultData);
    return docRef.id;
  } catch (error) {
    console.error('Error saving wheel result:', error);
    throw new Error('Failed to save wheel result');
  }
};

export const getWheelData = async (id: string): Promise<WheelData | null> => {
  try {
    const docRef = doc(db, 'wheelConfigurations', id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data() as WheelData;
    } else {
      console.log('No wheel configuration found with that ID');
      return null;
    }
  } catch (error) {
    console.error('Error fetching wheel configuration:', error);
    return null;
  }
};

export const getResultData = async (id: string): Promise<ResultData | null> => {
  try {
    const docRef = doc(db, 'wheelResults', id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data() as ResultData;
    } else {
      console.log('No wheel result found with that ID');
      return null;
    }
  } catch (error) {
    console.error('Error fetching wheel result:', error);
    return null;
  }
};