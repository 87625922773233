import React, { createContext, useContext, useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

interface MarkdownContextType {
  renderContent: (content: string) => React.ReactNode;
  isMarkdownEnabled: boolean;
  setMarkdownEnabled: (enabled: boolean) => void;
}

const MarkdownContext = createContext<MarkdownContextType | undefined>(undefined);

interface MarkdownProviderProps {
  children: React.ReactNode;
}

export const MarkdownProvider: React.FC<MarkdownProviderProps> = ({ children }) => {
  const [isMarkdownEnabled, setMarkdownEnabled] = useState(false);

  const isMarkdownContent = (content: string): boolean => {
    // Basic check for markdown syntax
    const markdownPatterns = [
      /[*_]{1,2}[^*_]+[*_]{1,2}/, // bold/italic
      /#+\s/, // headers
      /\[.+\]\(.+\)/, // links
      /```[\s\S]*```/, // code blocks
      /^\s*[-*+]\s/, // lists
      /^\s*\d+\.\s/ // numbered lists
    ];

    return markdownPatterns.some(pattern => pattern.test(content));
  };

  const renderContent = (content: string): React.ReactNode => {
    if (!content) return null;

    // If markdown is disabled or content doesn't contain markdown, return plain text
    if (!isMarkdownEnabled || !isMarkdownContent(content)) {
      return <span className="whitespace-pre-wrap">{content}</span>;
    }

    // Render as markdown
    return (
      <div className="markdown-display">
        <MDEditor.Markdown
          source={content}
          rehypePlugins={[[rehypeSanitize]]}
        />
      </div>
    );
  };

  const value = {
    renderContent,
    isMarkdownEnabled,
    setMarkdownEnabled
  };

  return (
    <MarkdownContext.Provider value={value}>
      {children}
    </MarkdownContext.Provider>
  );
};

export const useMarkdown = () => {
  const context = useContext(MarkdownContext);
  if (context === undefined) {
    throw new Error('useMarkdown must be used within a MarkdownProvider');
  }
  return context;
};

export default MarkdownProvider;