import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getActiveCustomChallenges } from '../components/weeklyChallenges/customChallenge';
import { CustomChallenge } from '../components/weeklyChallenges/customChallenge';
import Footer from '../components/Footer';
import { Search, Grid, List, ArrowUpRight } from 'lucide-react';
import Navbar from '../components/Navbar';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';
import MetaHead from '../components/ui/MetaHead';

const challengesPageMeta = {
  title: "Weekly Fantasy Football Challenges & Competitions",
  description: "Take on exciting weekly fantasy football challenges designed to keep your league engaged. From player performance challenges to creative team competitions.",
  keywords: [
    'fantasy football challenges',
    'weekly fantasy challenges',
    'fantasy league competitions',
    'fantasy football activities',
    'league engagement ideas',
  ]
};

const ViewToggle: React.FC<{
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
}> = ({ viewMode, setViewMode }) => (
  <div className="flex items-center gap-2 bg-[#1A1A1A] rounded-lg p-1">
    <button
      onClick={() => setViewMode('grid')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'grid' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="grid grid-cols-2 gap-0.5 w-5 h-5">
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
        <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
    <button
      onClick={() => setViewMode('list')}
      className={`p-2 rounded-lg transition-colors ${
        viewMode === 'list' 
          ? 'bg-[#18f7b1]' 
          : 'hover:bg-white/5'
      }`}
    >
      <div className="flex flex-col gap-0.5 w-5 h-5">
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
        <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-white'}`} />
      </div>
    </button>
  </div>
);

const WeeklyChallengesPage: React.FC = () => {
  const [challenges, setChallenges] = useState<CustomChallenge[]>([]);
  const [filteredChallenges, setFilteredChallenges] = useState<CustomChallenge[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [mostRecentWeek, setMostRecentWeek] = useState<number | null>(null);
  const navigate = useNavigate();

  const [displayCount, setDisplayCount] = useState(20);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);


  

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const fetchedChallenges = await getActiveCustomChallenges();
        setChallenges(fetchedChallenges);
        setFilteredChallenges(fetchedChallenges);
      } catch (error) {
        console.error('Error fetching challenges:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChallenges();
  }, []);

  useEffect(() => {
    if (filteredChallenges.length <= displayCount) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoadingMore) {
          setIsLoadingMore(true);
          setTimeout(() => {
            setDisplayCount(prev => prev + 12);
            setIsLoadingMore(false);
          }, 500);
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [filteredChallenges.length, displayCount, isLoadingMore]);

  // Reset display count when filters change
  useEffect(() => {
    setDisplayCount(20);
  }, [searchTerm]);

  useEffect(() => {
    const filtered = challenges.filter(challenge => 
      challenge.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      challenge.subtitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      challenge.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChallenges(filtered);
  }, [searchTerm, challenges]);

  const handleCardClick = (challenge: CustomChallenge) => {
    navigate(`/challenges/${challenge.id}`);
  };

  const renderGridView = () => (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredChallenges.slice(0, displayCount).map((challenge) => (
          <div 
          key={challenge.id} 
          className="relative aspect-[4/5] rounded-lg overflow-hidden cursor-pointer
                    transform transition-transform duration-300 hover:scale-[1.02]
                    border border-[#18f7b1] bg-gray-900"
          onClick={() => handleCardClick(challenge)}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src={challenge.image || '/placeholder-image.jpg'}
              alt={challenge.title}
              className="w-full h-full object-fill"
              style={{
                objectPosition: 'center',
                backgroundColor: '#1a1a1a'
              }}
            />
          </div>
          
          <div className="absolute inset-0 bg-gradient-to-t 
                          from-black via-black/50 to-transparent
                          from-[0%] via-[20%] to-[100%] z-[1]" />
        
          {challenge.week === mostRecentWeek && (
            <div className="absolute top-4 left-4 bg-[#18f7b1] text-black 
                          px-3 py-1.5 rounded-full font-bold z-[2]">
              Active
            </div>
          )}
        
          <div className="absolute bottom-6 left-4 right-4 space-y-2 z-[2]">
            <div className="flex items-center gap-2">
              <div className="bg-black/50 rounded-full px-3 py-1.5">
                <span className="text-lg text-[#FFC526] font-antonio">
                  Week {challenge.week}
                </span>
              </div>
            </div>
        
            <h3 className="text-[32px] font-bold font-antonio leading-tight text-white">
              {challenge.title}
            </h3>
          </div>
        </div>
        ))}
      </div>

      {/* Infinite scroll loader */}
      {filteredChallenges.length > displayCount && (
        <div 
          ref={loaderRef} 
          className="w-full flex justify-center items-center py-8"
        >
          {isLoadingMore && (
            <div className="w-8 h-8 border-4 border-[#18f7b1] border-t-transparent 
                          rounded-full animate-spin" />
          )}
        </div>
      )}
    </>
  );

  const renderListView = () => (
    <div className="space-y-4">
      {filteredChallenges.map((challenge) => (
        <div
          key={challenge.id}
          onClick={() => handleCardClick(challenge)}
          className="relative bg-[#1A1A1A] rounded-lg overflow-hidden cursor-pointer
                    hover:shadow-lg transition-all duration-300 border border-[#18f7b1]"
        >
          <div className="flex items-center p-6 gap-8">
            <div className="flex-shrink-0 w-24 text-center">
              <div className="text-white/60 text-sm">Week</div>
              <div className="text-2xl font-antonio text-[#18f7b1] font-bold">
                {challenge.week}
              </div>
            </div>
  
            <div className="flex-grow min-w-0">
              <h3 className="text-3xl font-antonio text-white mb-2 truncate">
                {challenge.title}
              </h3>
              <p className="text-white/80 text-base line-clamp-2">
                {challenge.description}
              </p>
              
              {challenge.formulaGroups && challenge.formulaGroups.length > 0 && (
                <div className="flex gap-2 mt-2">
                  {challenge.formulaGroups.map((group, index) => (
                    <span key={index} className="bg-white/10 text-white/60 px-2 py-1 rounded text-sm">
                      {group.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
  
            {challenge.week === mostRecentWeek && (
              <div className="flex-shrink-0">
                <span className="bg-[#18f7b1] text-black px-4 py-2 rounded-full font-bold">
                  Active
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col min-w-screen">
      <Navbar />
      <MetaHead
        title={challengesPageMeta.title}
        description={challengesPageMeta.description}
        keywords={challengesPageMeta.keywords}
        url="https://fantasyloser.com/challenges"
      />
      
      <div className="bg-[#1A1A1A] py-8 w-full pt-[50px]">
        <div className="container mx-auto px-4 pt-[50px]">
          <div className="max-w-6xl mx-auto space-y-8">
            <h1 className="text-4xl sm:text-6xl md:text-8xl font-antonio text-white text-center">
              Weekly Challenges
            </h1>

            <h2 className="text-xl font-antonio text-white text-center">
              A layer of gamification beyond the weekly fantasy matchups, designed to keep your league engaged with new ways to compete during the season.
            </h2>

            <div className="relative">
              <input
                type="text"
                placeholder="Search Challenges..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-black/50 text-white pl-12 pr-4 py-3 rounded-lg
                         border border-white/10 focus:border-[#18f7b1] transition-colors
                         placeholder:text-white/50 font-antonio"
              />
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50" size={20} />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-6xl mx-auto">
            <div className="flex justify-between items-center mb-6">
              <Link
                to='https://h4hcbpgn1q9.typeform.com/to/oDKBcyJn'
                className="inline-flex items-center justify-center gap-2 
                       px-4 py-2
                       max-w-[200px] 
                       text-base
                       font-antonio
                       bg-[#1a1a1a] text-white
                       border-2 border-black
                       rounded-lg
                       transition-colors duration-300
                       hover:bg-white hover:text-black
                       whitespace-nowrap
                       mt-6 sm:mt-0 sm:ml-4"
              >
                <span>Submit a Challenge</span>
                <ArrowUpRight className="w-5 h-5" />
              </Link>
              <ViewToggle viewMode={viewMode} setViewMode={setViewMode} />
            </div>
           
            {isLoading ? (
              <StyledLoadingSpinner />
            ) : (
              viewMode === 'grid' ? renderGridView() : renderListView()
            )}

            {filteredChallenges.length === 0 && !isLoading && (
              <div className="text-center py-12">
                <p className="text-gray-500 text-lg">
                  No challenges found matching your search.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default WeeklyChallengesPage;
