import React, { useState, useEffect, useRef } from 'react';
import { getContentByType } from '../services/firebase';
import { ContentItem } from '../types';
import WheelOfShame from '../components/WheelOfShame';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Search, X, Check, RotateCcw, PenLine, Filter, Share2, Eye, Mail, MessageSquare, Link } from 'lucide-react';
import { saveWheelData } from '../services/WheelSharingService';

import { WheelSegment } from '../types/wheel';

const PunishmentModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSelect: (punishment: WheelSegment) => void;
  selectedPunishments: WheelSegment[];
}> = ({ isOpen, onClose, onSelect, selectedPunishments }) => {
  const [punishments, setPunishments] = useState<ContentItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLevel, setSelectedLevel] = useState<string>('');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [showFilters, setShowFilters] = useState(false);
  const [filteredPunishments, setFilteredPunishments] = useState<ContentItem[]>([]);
  const [embarrassmentLevel, setEmbarrassmentLevel] = useState(0);
  const [riskLevel, setRiskLevel] = useState(0);
  const [longTermEffects, setLongTermEffects] = useState(0);

  const extractLevel = (flRating: string | undefined): number => {
    if (!flRating) return 1;
    
    // Check for format like "Cute & Fluffy (Level 1)"
    const levelMatch = flRating.match(/Level\s*(\d+)/i);
    if (levelMatch) {
      return parseInt(levelMatch[1]);
    }
    
    // Fallback check for format like "L1"
    const shortMatch = flRating.match(/L(\d+)/i);
    if (shortMatch) {
      return parseInt(shortMatch[1]);
    }
    
    return 1; // Default to level 1 if no match found
  };

  const getBadgeForLevel = (level: number) => {
    try {
      return require(`../assets/badge_${level}.png`);
    } catch (e) {
      console.error(`Badge image not found for level ${level}`);
      return null;
    }
  };

  useEffect(() => {
    const fetchPunishments = async () => {
      try {
        const content = await getContentByType('punishment');
        setPunishments(content);
        setFilteredPunishments(content);
      } catch (error) {
        console.error('Error fetching punishments:', error);
      }
    };

    if (isOpen) {
      fetchPunishments();
    }
  }, [isOpen]);

  useEffect(() => {
    const filtered = punishments.filter(item => {
      // Basic search filter
      const matchesSearch = item.title.toLowerCase().includes(searchTerm.toLowerCase());
      
      // Level filter - extract level number from selected level for comparison
      const selectedLevelNum = selectedLevel ? parseInt(selectedLevel.replace('L', '')) : 0;
      const itemLevelNum = extractLevel(item.flRating);
      const matchesLevel = selectedLevel === '' || itemLevelNum === selectedLevelNum;
  
      // Other filters
      const meetsEmbarrassment = embarrassmentLevel === 0 || parseInt(item.embarrassmentLevel) >= embarrassmentLevel;
      const meetsRisk = riskLevel === 0 || parseInt(item.riskLevel) >= riskLevel;
      const meetsEffort = longTermEffects === 0 || parseInt(item.longTermEffects) >= longTermEffects;
  
      return matchesSearch && matchesLevel && meetsEmbarrassment && meetsRisk && meetsEffort;
    });
  
    setFilteredPunishments(filtered);
  }, [searchTerm, selectedLevel, embarrassmentLevel, riskLevel, longTermEffects, punishments]);

  const isPunishmentSelected = (id: string) => {
    return selectedPunishments.some(p => p.id === id);
  };


  // Reuse the Slider component from Explore page
  const Slider: React.FC<{
    label: string;
    value: number;
    onChange: (value: number) => void;
    min: number;
    max: number;
  }> = ({ label, value, onChange, min, max }) => {
    const percentage = ((value - min) / (max - min)) * 100;

    return (
      <div className="w-full">
        <div className="flex justify-between items-center mb-2">
          <label className="text-gray-700 font-antonio">{label}</label>
          <span className="text-gray-700 font-antonio">[{value}]</span>
        </div>
        <div className="relative h-4 flex items-center">
          <div className="absolute w-full h-1 bg-gray-200 rounded">
            <div
              className="absolute h-full bg-[#18f7b1] rounded"
              style={{ width: `${percentage}%` }}
            />
          </div>
          <input
            type="range"
            min={min}
            max={max}
            value={value}
            onChange={(e) => onChange(Number(e.target.value))}
            className="absolute w-full opacity-0 cursor-pointer"
          />
          <div
            className="absolute w-4 h-4 rounded-full bg-white border-2 border-[#18f7b1] pointer-events-none"
            style={{ left: `calc(${percentage}% - 8px)` }}
          />
        </div>
      </div>
    );
  };

  const renderGridView = () => (
    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      {filteredPunishments.map((item) => {
        const isSelected = isPunishmentSelected(item.id);
        const level = extractLevel(item.flRating);
  
        return (
          <div 
            key={item.id} 
            className={`relative aspect-[4/5] rounded-lg overflow-hidden cursor-pointer
                      transform transition-transform duration-300 hover:scale-[1.02]
                      ${isSelected ? 'ring-2 ring-[#18f7b1]' : 'ring-1 ring-gray-200'}`}
            onClick={() => onSelect({
              id: item.id,
              title: item.title,
              color: '#000000',
              flRating: item.flRating,
              level: level
            })}
          >
            <div 
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${item.image || '/placeholder-image.jpg'})`,
              }}
            />
            
            <div className="absolute inset-0 bg-gradient-to-t 
                          from-black via-black/50 to-transparent" />
  
            {/* Updated Checkbox */}
            <div className="absolute top-2 sm:top-4 right-2 sm:right-4 w-6 h-6 rounded border-2 border-white
                           transition-all duration-200 flex items-center justify-center
                           ${isSelected ? 'bg-[#18f7b1] border-[#18f7b1]' : 'bg-black/30'}"
            >
              {isSelected && <Check className="w-4 h-4 text-black bg-[#18f7b1] border-[#18f7b1]" />}
            </div>
  
            <div className="absolute bottom-4 sm:bottom-6 left-2 sm:left-4 right-2 sm:right-4">
              <h3 className="text-white text-lg sm:text-2xl font-bold font-antonio leading-tight text-balance max-w-[200px] ">
                {item.title}
              </h3>
            </div>
  
            <div className="absolute bottom-2 sm:bottom-4 right-2 sm:right-4 z-10">
              <img 
                src={getBadgeForLevel(level)}
                alt={`Level ${level} Badge`}
                className="w-8 sm:w-12 h-8 sm:h-12 object-contain"
              />
            </div>
          </div>
        );
      })}
    </div>
  );


  const LevelSelect: React.FC<{
    value: string;
    onChange: (value: string) => void;
  }> = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    return (
      <div className="relative" ref={selectRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="border rounded-lg px-4 py-2 bg-gray-50 w-full text-left flex items-center justify-between border-2 border-black"
        >
          <span className="flex items-center">
            {value ? (
              <>
                <img 
                  src={getBadgeForLevel(parseInt(value.replace('L', '')))}
                  alt={`Level ${value.replace('L', '')}`}
                  className="w-6 h-6 mr-2"
                />
                Level {value.replace('L', '')}
              </>
            ) : (
              'All Levels'
            )}
          </span>
          <svg
            className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
  
        {isOpen && (
          <div className="absolute top-full left-0 right-0 mt-1 bg-white border rounded-lg shadow-lg z-50">
            <div
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                onChange('');
                setIsOpen(false);
              }}
            >
              All Levels
            </div>
            {[1, 2, 3, 4, 5].map((level) => (
              <div
                key={level}
                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                onClick={() => {
                  onChange(`L${level}`);
                  setIsOpen(false);
                }}
              >
                <img 
                  src={getBadgeForLevel(level)}
                  alt={`Level ${level}`}
                  className="w-6 h-6 mr-2"
                />
                Level {level}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
 
  const renderListView = () => (
    <div className="space-y-4 mt-4">
      {filteredPunishments.map((item) => {
        const isSelected = isPunishmentSelected(item.id);
        const level = extractLevel(item.flRating);
        
        return (
          <div
            key={item.id}
            onClick={() => onSelect({
              id: item.id,
              title: item.title,
              color: '#000000',
              flRating: item.flRating,
              level: level
            })}
            className={`relative bg-white rounded-lg overflow-hidden cursor-pointer
                      hover:bg-gray-50 transition-all duration-300 border-b-2 border-black/50
                      ${isSelected ? 'border-[#18f7b1] border-2' : ''}`}
          >
            <div className="flex flex-col gap-2 sm:gap-6 p-4">
              {/* Top Section - Title, Rating and Selection */}
              <div className="flex justify-between items-start">
                <div className="flex gap-4 items-center">
                  <div>
                    <img 
                      src={getBadgeForLevel(level)}
                      alt={`Level ${level} Badge`}
                      className="w-8 sm:w-10 h-8 sm:h-10 object-contain"
                    />
                  </div>
                  <h3 className="text-lg sm:text-2xl font-antonio text-black font-bold">
                    {item.title}
                  </h3>
                </div>

                {/* Selection Check  */}
                <div className="w-6 h-6 rounded border-2 border-white
                             transition-all duration-200 flex items-center justify-center
                             ${isSelected ? 'bg-[#18f7b1] border-[#18f7b1]' : 'bg-black/30'}"
                >
                  {isSelected && <Check className="w-4 h-4 text-black bg-[#18f7b1] border-[#18f7b1]" />}
                </div>
              </div>

              {/* Middle Section - Description */}
              <div>
                <p className="text-black/70 text-sm sm:text-base line-clamp-2">
                  {item.subtitle}
                </p>
              </div>

              {/* Bottom Section - Metrics */}
              <div className="grid grid-cols-3 gap-2 sm:gap-4 pt-2 border-t border-black/10">
                {/* Shame Rating */}
                <div className="flex flex-col items-center bg-gray-300 rounded-xl p-2 
                              h-[60px] sm:h-[74px] justify-center">
                  <div className="text-base sm:text-2xl font-bold font-antonio text-black">
                    {item.embarrassmentLevel}/5
                  </div>
                  <div className="text-xs sm:text-lg font-antonio text-black text-center">
                    Shame
                  </div>
                </div>

                {/* Danger Rating */}
                <div className="flex flex-col items-center bg-gray-300 rounded-xl p-2 
                              h-[60px] sm:h-[74px] justify-center">
                  <div className="text-base sm:text-2xl font-bold font-antonio text-black">
                    {item.riskLevel}/5
                  </div>
                  <div className="text-xs sm:text-lg font-antonio text-black text-center">
                    Danger
                  </div>
                </div>

                {/* Effort Rating */}
                <div className="flex flex-col items-center bg-gray-300 rounded-xl p-2 
                              h-[60px] sm:h-[74px] justify-center">
                  <div className="text-base sm:text-2xl font-bold font-antonio text-black">
                    {item.longTermEffects}/5
                  </div>
                  <div className="text-xs sm:text-lg font-antonio text-black text-center">
                    Effort  
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-5xl max-h-[90vh] overflow-hidden flex flex-col">
        {/* Header */}
        <div className="flex flex-col sm:flex-row items-center justify-between p-4 sm:p-6 border-b">
          <div>
            <h2 className="text-xl sm:text-2xl font-bold font-antonio">Select Punishments</h2>
            <p className="text-gray-500 text-sm sm:text-base">{selectedPunishments.length} punishments selected</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 mt-2 sm:mt-0 hover:bg-gray-50 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Main Content */}
        <div className="p-4 sm:p-6 overflow-y-auto flex-1">
          {/* Search */}
          <div className="relative mb-4 border-2 border-black rounded-xl">
            <input
              type="text"
              placeholder="Search punishments..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-gray-50 rounded-lg"
            />
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          </div>
          
          <div className="flex flex-col gap-2 sm:gap-4">
            <div className="flex gap-2 items-center">
            {/* Filter Toggle */}
            <button
              onClick={() => setShowFilters(!showFilters)}
              className={`px-4 py-2 rounded-lg transition-colors flex items-center space-x-2 border-2 border-black ${
                showFilters ? 'bg-[#18f7b1] text-black' : 'bg-gray-50 text-black'
              }`}
            >
              <Filter size={20} />
              <span>Filter</span>
            </button>
            
            {/* Level Filter */}
            <LevelSelect
              value={selectedLevel}
              onChange={setSelectedLevel}
            />

            {/* View Toggle */}
            <div className="flex bg-gray-900 rounded-lg p-1">
              <button
                onClick={() => setViewMode('grid')}
                className={`p-2 rounded-lg transition-colors ${
                  viewMode === 'grid' 
                    ? 'bg-[#18f7b1] text-black' 
                    : 'text-white hover:text-gray-200'
                }`}
              >
                <div className="grid grid-cols-2 gap-0.5 w-5 h-5">
                  <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-current'}`} />
                  <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-current'}`} />
                  <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-current'}`} />
                  <div className={`${viewMode === 'grid' ? 'bg-black' : 'bg-current'}`} />
                </div>
              </button>
              <button
                onClick={() => setViewMode('list')}
                className={`p-2 rounded-lg transition-colors ${
                  viewMode === 'list' 
                    ? 'bg-[#18f7b1] text-black' 
                    : 'text-white hover:text-gray-200'
                }`}
              >
                <div className="flex flex-col gap-0.5 w-5 h-5">
                  <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-current'}`} />
                  <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-current'}`} />
                  <div className={`h-1.5 ${viewMode === 'list' ? 'bg-black' : 'bg-current'}`} />
                </div>
              </button>
            </div>
          </div>

          {/* Filters Section */}
          {showFilters && (
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4 p-4 bg-gray-50 rounded-lg">
              <Slider
                label="Shame"
                value={embarrassmentLevel}
                onChange={setEmbarrassmentLevel}
                min={0}
                max={5}
              />
              <Slider
                label="Danger"
                value={riskLevel}
                onChange={setRiskLevel}
                min={0}
                max={5}
              />
              <Slider
                label="Effort"
                value={longTermEffects}
                onChange={setLongTermEffects}
                min={0}
                max={5}
              />
            </div>
          )}

          {/* Grid/List View */}
          <div className="mt-4">
            {viewMode === 'grid' ? renderGridView() : renderListView()}
          </div>
        </div>

        {/* Footer */}
        <div className="p-4 border-t">
          <button
            onClick={onClose}
            className="w-full sm:w-auto ml-auto bg-white border-2 border-black 
                      text-black py-2 sm:py-3 px-8 sm:px-12 rounded-lg 
                      hover:bg-gray-50 transition-colors font-bold 
                      text-base sm:text-lg font-antonio"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

const CustomPunishmentModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (punishment: WheelSegment) => void;
}> = ({ isOpen, onClose, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [level, setLevel] = useState<number>(1);
  const [isLevelOpen, setIsLevelOpen] = useState(false);
  const levelSelectRef = useRef<HTMLDivElement>(null);

  const getBadgeForLevel = (level: number) => {
    try {
      return require(`../assets/badge_${level}.png`);
    } catch (e) {
      console.error(`Badge image not found for level ${level}`);
      return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (levelSelectRef.current && !levelSelectRef.current.contains(event.target as Node)) {
        setIsLevelOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const customPunishment: WheelSegment = {
      id: `custom-${Date.now()}`,
      title: title.trim(),
      level,
      color: '#000000',
      flRating: `L${level}`,
      subtitle: description.trim() 
    };
    onSubmit(customPunishment);
    setTitle('');
    setDescription('');
    setLevel(1);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-[618.69px] w-full min-h-[337px] border-8 border-gray-200 rounded-[50px]">
        <div className="flex flex-col items-center mb-6">
          <h2 className="text-6xl font-bold font-antonio mb-10 mt-2 text-center">
            Write your own punishment
          </h2>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Title Input */}
          <div className="mb-6">
            <input
              id="punishment-title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#18f7b1] 
                       focus:border-transparent bg-gray-50 border-2 border-black"
              placeholder="Enter punishment title"
              required
            />
          </div>

          {/* Description Input */}
          <div className="mb-6">
            <textarea
              id="punishment-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#18f7b1] 
                       focus:border-transparent bg-gray-50 border-2 border-black resize-none"
              placeholder="Enter punishment description"
              rows={4}
              required
            />
          </div>

          {/* Level Select */}
          <div className="mb-6" ref={levelSelectRef}>
            <div className="relative">
              <button
                type="button"
                onClick={() => setIsLevelOpen(!isLevelOpen)}
                className="w-full p-3 border rounded-lg bg-gray-50 flex items-center justify-between border-2 border-black font-roboto"
              >
                <span className="flex items-center">
                  <img 
                    src={getBadgeForLevel(level)}
                    alt={`Level ${level}`}
                    className="w-6 h-6 mr-2"
                  />
                  Level {level}
                </span>
                <svg
                  className={`w-4 h-4 transition-transform ${isLevelOpen ? 'rotate-180' : ''}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {isLevelOpen && (
                <div className="absolute top-full left-0 right-0 mt-1 bg-white border 
                             rounded-lg shadow-lg z-50">
                  {[1, 2, 3, 4, 5].map((lvl) => (
                    <div
                      key={lvl}
                      className="p-3 hover:bg-gray-100 cursor-pointer flex items-center"
                      onClick={() => {
                        setLevel(lvl);
                        setIsLevelOpen(false);
                      }}
                    >
                      <img 
                        src={getBadgeForLevel(lvl)}
                        alt={`Level ${lvl}`}
                        className="w-6 h-6 mr-2"
                      />
                      Level {lvl}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border-2 border-black rounded-lg hover:bg-gray-50 
                     transition-colors font-medium font-antonio"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-black text-white font-antonio rounded-lg hover:bg-[#0fd494] 
                     transition-colors font-medium"
            >
              Add Punishment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const NamingModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  initialName: string;
}> = ({ isOpen, onClose, onSubmit, initialName }) => {
  const [name, setName] = useState(initialName);

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim()) {
      onSubmit(name);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-[50px] p-6 max-w-[618.69px] w-full min-h-[337px] border-8 border-gray-200">
        <div className="flex flex-col items-center mb-6">
          <h2 className="text-6xl font-bold font-antonio mb-10 mt-2 text-center">
            Name your wheel
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="wheel-name" className="block text-sm font-bold text-gray-700 mb-2">
              Wheel Name
            </label>
            <input
              id="wheel-name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#18f7b1] 
                       focus:border-transparent bg-gray-50 border-2 border-black font-roboto"
              placeholder="Enter wheel name"
              required
            />
          </div>

          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border-2 border-black rounded-lg hover:bg-gray-50 
                     transition-colors font-medium font-antonio"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-black text-white rounded-lg hover:bg-[#0fd494] 
                     transition-colors font-medium font-antonio"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ViewPunishmentsModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  punishments: WheelSegment[];
  wheelName: string;
}> = ({ isOpen, onClose, punishments, wheelName }) => {
  const [fullPunishmentDetails, setFullPunishmentDetails] = useState<{[key: string]: ContentItem}>({});

  const extractLevel = (flRating: string | undefined): number => {
    if (!flRating) return 1;
    
    const levelMatch = flRating.match(/Level\s*(\d+)/i);
    if (levelMatch) {
      return parseInt(levelMatch[1]);
    }
    
    const shortMatch = flRating.match(/L(\d+)/i);
    if (shortMatch) {
      return parseInt(shortMatch[1]);
    }
    
    return 1;
  };

  useEffect(() => {
    const fetchPunishmentDetails = async () => {
      try {
        const content = await getContentByType('punishment');
        const detailsMap = content.reduce((acc: {[key: string]: ContentItem}, item) => {
          acc[item.id] = item;
          return acc;
        }, {});
        setFullPunishmentDetails(detailsMap);
      } catch (error) {
        console.error('Error fetching punishment details:', error);
      }
    };

    if (isOpen) {
      fetchPunishmentDetails();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const getBadgeForLevel = (level: number) => {
    try {
      return require(`../assets/badge_${level}.png`);
    } catch (e) {
      console.error(`Badge image not found for level ${level}`);
      return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        <div className="flex items-center justify-between p-4 border-b">
          <div>
            <h2 className="text-5xl font-bold font-antonio px-4">{wheelName}</h2>
            <p className="text-gray-500 text-md px-4">{punishments.length} punishments in wheel</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-50 rounded-full"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 overflow-y-auto">
          <div className="space-y-4">
            {punishments.map((punishment) => {
              const fullDetails = fullPunishmentDetails[punishment.id];
              const level = extractLevel(fullDetails?.flRating || punishment.flRating);
              const isCustomPunishment = punishment.id.startsWith('custom-');
              
              return (
                <div
                  key={punishment.id}
                  onClick={() => {
                    if (!isCustomPunishment) {
                      window.open(`/punishments/${fullDetails?.slug || punishment.id}`, '_blank');
                    }
                  }}
                  className={`bg-white rounded-lg overflow-hidden border border-gray-200 
                             ${!isCustomPunishment ? 'hover:border-[#18f7b1] cursor-pointer' : 'cursor-default'} 
                             transition-colors`}
                >
                  <div className="flex p-3 gap-3">
                    {/* Image Section */}
                    <div className="flex-shrink-0 w-[80px] h-[100px] bg-gray-100 rounded overflow-hidden">
                      {fullDetails?.image ? (
                        <img 
                          src={fullDetails.image as string} 
                          alt={punishment.title}
                          className="w-full h-full object-cover"
                        />
                      ) : isCustomPunishment ? (
                        <div className="w-full h-full flex items-center justify-center bg-black">
                          <img 
                            src={require("../assets/FL_Logo_FL_Icon_Primary_2.png")}
                            alt="Custom Punishment"
                            className="w-3/4 h-3/4 object-contain opacity-50"
                          />
                        </div>
                      ) : (
                        <div className="w-full h-full flex items-center justify-center bg-gray-200">
                          <span className="text-gray-400 text-xs">No image</span>
                        </div>
                      )}
                    </div>

                    {/* Content Section */}
                    <div className="flex-grow min-w-0">
                      {/* Title and Level Badge Row */}
                      <div className="flex items-start gap-2 mb-1">
                        {/* Title Container */}
                        <div className="flex-grow min-w-0">
                          <h3 className="text-3xl font-antonio text-black font-bold line-clamp-1">
                            {punishment.title}
                          </h3>
                        </div>
                        
                        {/* Level Badge - Now with fixed height */}
                        <div className="flex-shrink-0">
                          <div className="flex items-center gap-1 bg-black rounded-full px-2 h-7">
                            <img 
                              src={getBadgeForLevel(level)}
                              alt={`Level ${level} Badge`}
                              className="w-4 h-4 object-contain flex-shrink-0"
                            />
                            <span className="text-md text-white/80 font-roboto whitespace-nowrap">
                              Level {level}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* Subtitle */}
                      <p className="text-gray-600 text-lg font-roboto line-clamp-2">
                        {fullDetails?.subtitle || punishment.subtitle || ''}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="p-4 border-t mt-auto">
          <button
            onClick={onClose}
            className="w-full bg-[#18f7b1] text-black py-2 rounded hover:bg-[#0fd494] 
                     transition-colors font-bold text-base"
          >
            Close
          </button>
        </div>
      </div>
    </div>  
  );
};

const ShareButton: React.FC<{
  selectedPunishments: WheelSegment[];
  wheelName: string;
}> = ({ selectedPunishments, wheelName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);

  const generateWheelUrl = async () => {
    setIsGeneratingUrl(true);
    try {
      const wheelId = await saveWheelData(wheelName, selectedPunishments);
      return `${window.location.origin}/wheelofshame/?wheel=${wheelId}`;
    } catch (error) {
      console.error('Error generating wheel URL:', error);
      alert('Failed to generate share link. Please try again.');
      return '';
    } finally {
      setIsGeneratingUrl(false);
    }
  };

  const handleCopyLink = async () => {
    const wheelUrl = await generateWheelUrl();
    if (!wheelUrl) return;
    
    try {
      await navigator.clipboard.writeText(wheelUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      alert('Failed to copy to clipboard. Please try again.');
    }
  };

  const handleEmailShare = async () => {
    const wheelUrl = await generateWheelUrl();
    if (!wheelUrl) return;
    
    const subject = `Check out my Wheel of Shame: ${wheelName}`;
    const body = `I created a Wheel of Shame and wanted to share it with you: ${wheelUrl}`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="inline-flex items-center px-3 sm:px-6 py-2 sm:py-3 
                  bg-white border-2 border-black rounded-lg 
                  hover:bg-gray-50 font-roboto transition-colors 
                  text-sm sm:text-base font-bold whitespace-nowrap"
      >
        <Share2 className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
        <span className="hidden sm:inline">Share Wheel with your league</span>
        <span className="sm:hidden">Share Wheel</span>
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full">
            <div className="flex items-center justify-between p-6 border-b">
              <div>
                <h2 className="text-2xl font-bold font-antonio">Share Wheel</h2>
                <p className="text-sm text-gray-500 mt-1">{wheelName}</p>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="p-2 hover:bg-gray-50 rounded-full"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <div className="p-6 space-y-4">
              <button
                onClick={handleCopyLink}
                disabled={isGeneratingUrl}
                className={`w-full py-3 px-4 rounded-lg border-2 
                          flex items-center justify-center gap-2 font-antonio
                          ${copied 
                            ? 'bg-[#18f7b1] border-[#18f7b1] text-black' 
                            : 'border-black hover:bg-gray-50'}`}
              >
                {copied ? (
                  <>
                    <Check className="w-5 h-5" />
                    Copied!
                  </>
                ) : isGeneratingUrl ? (
                  <>
                    <div className="w-5 h-5 border-2 border-t-transparent border-black rounded-full animate-spin"></div>
                    Generating...
                  </>
                ) : (
                  <>
                    <Link className="w-5 h-5" />
                    Copy Link
                  </>
                )}
              </button>

              <button
                onClick={handleEmailShare}
                disabled={isGeneratingUrl}
                className="w-full py-3 px-4 rounded-lg border-2 border-black
                         hover:bg-gray-50 transition-colors font-antonio
                         flex items-center justify-center gap-2 disabled:opacity-50"
              >
                {isGeneratingUrl ? (
                  <>
                    <div className="w-5 h-5 border-2 border-t-transparent border-black rounded-full animate-spin"></div>
                    Generating...
                  </>
                ) : (
                  <>
                    <Mail className="w-5 h-5" />
                    Share via Email
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {copied && !isModalOpen && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 
                      bg-black text-white px-4 py-2 rounded-lg text-center z-50">
          Copied to clipboard!
        </div>
      )}
    </>
  );
};

const WheelOfShamePage: React.FC = () => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [selectedPunishments, setSelectedPunishments] = useState<WheelSegment[]>([]);
  const [availablePunishments, setAvailablePunishments] = useState<ContentItem[]>([]);
  const [wheelName, setWheelName] = useState('Wheel of Shame');
  const [isNamingModalOpen, setIsNamingModalOpen] = useState(false);
  const [isSpinningPage, setIsSpinningPage] = useState(false);
  const [isWheelSpinnable, setIsWheelSpinnable] = useState(false);
  const [isViewPunishmentsModalOpen, setIsViewPunishmentsModalOpen] = useState(false);

  const [spinResult, setSpinResult] = useState<WheelSegment | null>(null);

  const initialLoadDone = useRef(false);


  const handleLoadSharedWheel = (punishments: WheelSegment[]) => {
    setSelectedPunishments(punishments);
  };


  const handleSpinComplete = (result: WheelSegment) => {
    setSpinResult(result);
  };

  const handleCloseResult = () => {
    setSpinResult(null);
  };
  

  const getBadgeForLevel = (level: number) => {
    try {
      return require(`../assets/badge_${level}.png`);
    } catch (e) {
      console.error(`Badge image not found for level ${level}`);
      return null;
    }
  };

  const extractLevel = (flRating: string | undefined): number => {
    if (!flRating) return 1;
    
    // Check for format like "Cute & Fluffy (Level 1)"
    const levelMatch = flRating.match(/Level\s*(\d+)/i);
    if (levelMatch) {
      return parseInt(levelMatch[1]);
    }
    
    // Fallback check for format like "L1"
    const shortMatch = flRating.match(/L(\d+)/i);
    if (shortMatch) {
      return parseInt(shortMatch[1]);
    }
    
    return 1; // Default to level 1 if no match found
  };

  const handleContinueToSpin = () => {
    if (selectedPunishments.length > 0) {
      setIsNamingModalOpen(true);
    } else {
      alert("Please select at least one punishment before continuing.");
    }
  };

  const handleNameSubmit = (name: string) => {
    setWheelName(name);
    setIsNamingModalOpen(false);
    setIsSpinningPage(true);
    setIsWheelSpinnable(true); // Allow spinning on the spin page
  };

  useEffect(() => {
    const fetchPunishments = async () => {
      try {
        const content = await getContentByType('punishment');
        setAvailablePunishments(content);
      } catch (error) {
        console.error('Error fetching punishments:', error);
      }
    };
    fetchPunishments();
  }, []);

  useEffect(() => {
    // Only run URL parameter handling on initial load
    if (!initialLoadDone.current) {
      const params = new URLSearchParams(window.location.search);
      const wheelData = params.get('wheel');
  
      if (wheelData) {
        try {
          const decodedData = JSON.parse(atob(wheelData));
          if (decodedData.wheelName) {
            setWheelName(decodedData.wheelName);
          }
          if (decodedData.segments) {
            setSelectedPunishments(decodedData.segments);
          }
          setIsSpinningPage(true);  // Ensure we show the spinning view
          setIsWheelSpinnable(true);
        } catch (error) {
          console.error('Error parsing wheel data:', error);
        }
      }
      
      initialLoadDone.current = true;
    }
  }, []);

  const handleRandomSelection = () => {
    const maxRandomPunishments = 1; // You can adjust this number
    const availableIds = availablePunishments
      .filter(p => !selectedPunishments.some(sp => sp.id === p.id))
      .map(p => p);

    if (availableIds.length === 0) return;

    const shuffled = [...availableIds].sort(() => 0.5 - Math.random());
    const selectedCount = Math.min(maxRandomPunishments, shuffled.length);
    const randomlySelected = shuffled.slice(0, selectedCount);

    const newPunishments = randomlySelected.map(punishment => ({
      id: punishment.id,
      title: punishment.title,
      color: '#000000',
      flRating: punishment.flRating,
      level: parseInt(punishment.flRating?.match(/L(\d+)/)?.[1] || '1')
    }));

    setSelectedPunishments(prev => [...newPunishments, ...prev ]);
  };

  const handlePunishmentSelect = (punishment: WheelSegment) => {
    setSelectedPunishments(prev => {
      const exists = prev.some(p => p.id === punishment.id);
      if (exists) {
        return prev.filter(p => p.id !== punishment.id);
      }
      return [punishment, ...prev];
    });
  };

  const handleRemovePunishment = (id: string) => {
    setSelectedPunishments(prev => prev.filter(p => p.id !== id));
  };

 
  if (isSpinningPage) {
    return (
      <div className="min-h-screen flex flex-col bg-white ">
        <Navbar />
        {/* Reduce top padding and adjust relative container */}
        <main className="flex-grow px-4  relative mt-10 pt-[80px]">
          <div className="max-w-[1400px] mx-auto ">
            {/* Header Section with reduced bottom margin */}
            <div className="flex justify-between items-start mb-2 sm:mb-4 ">
              <div>
                <h1 className="text-2xl font-bold mb-2 font-antonio">Wheel of Shame</h1>
                <h1 className="text-4xl sm:text-6xl font-bold font-antonio">{wheelName}</h1>
              </div>
              
              <button
            onClick={() => setIsViewPunishmentsModalOpen(true)}
            className="inline-flex items-center px-3 sm:px-6 py-2 sm:py-3 
                     bg-gray-200 font-bold rounded-lg font-antonio
                     text-sm sm:text-base whitespace-nowrap" // Added whitespace-nowrap
          >
            <span className="inline">View Punishments</span>
          </button>

            </div>
  
            {/* Main Content - Add relative positioning for share button */}
            <div className="relative">
              {/* Share Button - Positioned relative to wheel container */}
              <div className="absolute bottom-10 sm:bottom-24 left-0 z-10">
                <ShareButton 
                  selectedPunishments={selectedPunishments}
                  wheelName={wheelName}
                />
              </div>
  
              {/* Wheel container with right alignment */}
              <div className="flex justify-end">
                <div className="w-full sm:w-auto -mx-4 sm:mx-0 -mt-4 sm:-mt-8">
                  <WheelOfShame 
                    selectedPunishments={selectedPunishments || []}
                    onSearchClick={() => {}}
                    onLoadSharedWheel={() => {}}
                    selectedSegment={spinResult}
                    onSpinComplete={handleSpinComplete}
                    onCloseResult={handleCloseResult}
                    punishments={availablePunishments}
                    isSpinnable={isWheelSpinnable}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
  
        <ViewPunishmentsModal 
          isOpen={isViewPunishmentsModalOpen}
          onClose={() => setIsViewPunishmentsModalOpen(false)}
          punishments={selectedPunishments}
          wheelName={wheelName}
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Navbar />
      <main className="flex-grow lg:px-80 md:px-20 px-10 py-8 pt-[80px]">
  <div className="max-w-[2000px] mx-auto ">
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-start">
      {/* Left Column */}
<div className="">
  <div className="mb-8 max-w-xl"> {/* Added max-w-xl to constrain width */}
    <h1 className="text-6xl lg:text-8xl font-bold mb-4 font-antonio">
      Wheel of Shame
    </h1>
    <p className="text-gray-800 mb-8 font-roboto text-lg lg:text-xl">
      Lorem ipsum dolor sit amet consectetur. Cursus ut pellentesque
      turpis imperdiet ut lacinia et. Hendrerit turpis massa nisi tincidunt
      maecenas faucibus.
    </p>

    {/* Action Buttons */}
    <div className="flex flex-wrap gap-4 mb-8">
      <button 
        onClick={() => setIsSearchModalOpen(true)}
        className="inline-flex items-center px-4 py-2 bg-white border-2 border-black rounded-lg hover:bg-gray-50 font-roboto"
      >
        <Search className="w-5 h-5 mr-2" />
        Search Punishments
      </button>
      
      <button 
        onClick={handleRandomSelection}
        className="inline-flex items-center px-4 py-2 bg-white border-2 border-black rounded-lg hover:bg-gray-50 font-roboto" 
      >
        <RotateCcw className="w-5 h-5 mr-2" />
        Random Selection
      </button>
      
      <button 
        onClick={() => setIsCustomModalOpen(true)}
        className="inline-flex items-center px-4 py-2 bg-white border-2 border-black rounded-lg hover:bg-gray-50 font-roboto"
      >
        <PenLine className="w-5 h-5 mr-2" />
        Create Your Own
      </button>
    </div>

    {/* Selected Punishments List */}
    {selectedPunishments.length > 0 && (
      <div className="max-w-md"> {/* Added max-w-md to constrain pill width */}
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">
            Selected Punishments ({selectedPunishments.length})
          </h3>
          <button
            onClick={() => {
              if (window.confirm('Are you sure you want to remove all punishments from the wheel?')) {
                setSelectedPunishments([]);
              }
            }}
            className="text-sm font-medium text-gray-500 hover:text-red-600 
                      flex items-center gap-1 transition-colors duration-200"
          >
            <X className="w-4 h-4" />
            Clear all
          </button>
        </div>

        <div className="grid grid-cols-1 gap-3 max-h-[300px] overflow-y-auto mb-4">
          {selectedPunishments.map((punishment) => {
            const level = extractLevel(punishment.flRating);
            
            return (
              <div
                key={punishment.id}
                className="relative bg-gray-100 rounded-full overflow-hidden hover:bg-gray-300 transition-colors"
              >
                {/* Pill Content Container */}
                <div className="relative flex items-center py-2 pl-2 pr-8"> {/* Adjusted padding */}
                  {/* Badge Circle */}
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-black/5 mr-3">
                    <img 
                      src={getBadgeForLevel(level)}
                      alt={`Level ${level} Badge`}
                      className="w-full h-full object-contain p-0.5"
                    />
                  </div>

                  {/* Title */}
                  <div className="flex-grow min-w-0">
                    <h4 className="font-medium text-sm text-gray-900 truncate">
                      {punishment.title}
                    </h4>
                  </div>

                  {/* Remove Button */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemovePunishment(punishment.id);
                    }}
                    className="absolute right-2 top-1/2 -translate-y-1/2 p-1 
                            hover:bg-gray-200 rounded-full transition-colors"
                  >
                    <X size={14} className="text-gray-500" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        {/* Continue Button */}
        <button
          onClick={handleContinueToSpin}
          className="w-full bg-white border-2 border-black text-black py-3 
                   rounded-lg hover:bg-black hover:text-white transition-colors 
                   text-lg mt-4 p-6 font-antonio"
        >
          Continue 
        </button>
      </div>
    )}
  </div>

            </div>

            {/* Wheel - Right Side */}
            <div className="relative">
              <div className=" mx-auto">
              <WheelOfShame 
                selectedPunishments={selectedPunishments}
                onSearchClick={() => setIsSearchModalOpen(true)}
                onLoadSharedWheel={handleLoadSharedWheel}
                selectedSegment={spinResult}
                onSpinComplete={handleSpinComplete}
                onCloseResult={handleCloseResult}
                punishments={availablePunishments}
                isSpinnable={false} // Wheel is not spinnable on this page
              />
              </div>
            </div>
          </div>
        </div>
       
      </main>
      <Footer />
      {/* Modals */}
      <NamingModal
        isOpen={isNamingModalOpen}
        onClose={() => setIsNamingModalOpen(false)}
        onSubmit={handleNameSubmit}
        initialName={wheelName}
      />

      <PunishmentModal
        isOpen={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        onSelect={handlePunishmentSelect}
        selectedPunishments={selectedPunishments}
      />

      <CustomPunishmentModal
        isOpen={isCustomModalOpen}
        onClose={() => setIsCustomModalOpen(false)}
        onSubmit={handlePunishmentSelect}
      />
    </div>
  );
};

export default WheelOfShamePage;