import React, { useEffect, useRef } from 'react';
import { Pencil, X } from 'lucide-react';
import ChallengeCalculator from '../weeklyChallenges/challengeCalculator';

interface EditChallengeModalProps {
  challengeId?: string;
  isAdmin: boolean;
  onSaveSuccess?: () => void;
  buttonClassName?: string;
}

const EditChallengeModal: React.FC<EditChallengeModalProps> = ({
  challengeId,
  isAdmin,
  onSaveSuccess,
  buttonClassName = 'bg-[#18f7b1] hover:bg-[#0fd494] text-black px-4 py-2 rounded-lg flex items-center gap-2'
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isAdmin) {
    return null;
  }

  const handleSaveSuccess = () => {
    setIsOpen(false);
    if (onSaveSuccess) {
      onSaveSuccess();
    }
  };

  return (
    <>
      <button 
        onClick={() => setIsOpen(true)} 
        className={buttonClassName}
      >
        <Pencil className='text-red' size={20} />
        <span className='text-red'>Edit Challenge</span>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div 
            ref={modalRef}
            className="bg-white rounded-lg w-[90vw] max-w-[1200px] max-h-[90vh] overflow-y-auto relative"
          >
            <div className="sticky top-0 bg-white p-4 border-b flex justify-between items-center">
              <div>
                <h2 className="text-xl font-bold">Edit Challenge</h2>
                <p className="text-gray-600">Make changes to the challenge content below.</p>
              </div>
              <button
                onClick={() => setIsOpen(false)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X size={20} />
              </button>
            </div>
            
            <div className="p-4">
              <ChallengeCalculator 
                challengeId={challengeId} 
                onSaveSuccess={handleSaveSuccess}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditChallengeModal;