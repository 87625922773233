import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';

interface LeagueRegistrationSliderProps {
  isOpen: boolean;
  onClose: () => void;
}

const LeagueRegistrationSlider: React.FC<LeagueRegistrationSliderProps> = ({ 
  isOpen, 
  onClose 
}) => {
  const [mounted, setMounted] = useState(false);
  const [isCommissioner, setIsCommissioner] = useState<boolean | null>(null);
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Handle mounting animation
  useEffect(() => {
    if (isOpen) {
      setMounted(true);
    } else {
      const timer = setTimeout(() => {
        setMounted(false);
        setIsCommissioner(null); // Reset state when closing
        setIsSignedUp(false);
        setEmail('');
        setMessage('');
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      // Add email to newsletter collection
      const docRef = await addDoc(collection(db, 'newsletter'), {
        email: email.toLowerCase().trim(),
        subscribedAt: new Date(),
        source: 'registration_slider'
      });
      
      setMessage('Successfully subscribed!');
      setEmail('');
      // Only set signup to true after successful Firebase operation
      setIsSignedUp(true);
    } catch (error: any) {
      console.error('Newsletter subscription error:', error);
      setMessage(`Error: ${error.message}`);
      setIsSignedUp(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommissionerClick = () => {
    onClose(); // Close the slider
    navigate('/signup'); // Navigate to sign-up page
  };

  if (!mounted) return null;

  const renderNewsletterForm = () => (
    <div className="min-h-full py-16 px-4 md:px-16 flex flex-col justify-center">
      <div className="max-w-2xl mx-auto w-full">
        <h1 className="font-antonio text-white text-3xl sm:text-4xl md:text-[48px] mb-6 font-bold">
          Sign up for the Fantasy Loser newsletter and receive draft order ideas, punishment content, and updates on new features.
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6 mt-8 md:mt-20">
          <div className="relative">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example: HingleMcCringleberry@pennstate.edu"
              className="w-full bg-transparent border border-white/20 rounded-lg py-3 px-4 
                      placeholder-gray-500 focus:outline-none focus:border-[#18f7b1]
                      transition-colors font-antonio text-white"
              required
            />
          </div>

          <button 
            type="submit" 
            disabled={isLoading}
            className="w-auto border-2 border-white text-white px-6 sm:px-10 py-3 sm:py-4 
                    rounded-[10px] hover:text-black hover:bg-white hover:border-black 
                    transition-colors duration-300 font-antonio text-lg sm:text-xl
                    disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>

          {message && (
            <p className={`text-sm ${
              message.startsWith('Error') ? 'text-red-500' : 'text-[#18f7b1]'
            }`}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );

  const renderCommissionerChoice = () => (
    <div className="min-h-full py-16 px-4 md:px-16 flex flex-col justify-center">
      <div className="max-w-xl mx-auto w-full text-left">
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-6 mb-6 sm:mb-8">
          <h1 className="font-antonio text-[#18f7b1] text-4xl sm:text-5xl md:text-[60px] font-bold leading-tight sm:leading-[80px]">
            IT'S GOOD!
          </h1>
          <img 
            src={require('../../assets/giphy-1 1.png')} 
            alt="Fantasy Loser It's Good" 
            className="mt-4 sm:mt-0 w-[200px] h-[160px] sm:w-[264px] sm:h-[210px]"
          />
        </div>
        <h2 className="font-antonio text-white text-2xl sm:text-3xl md:text-[48px] mb-4 leading-tight sm:leading-[48px]">
          Thanks for joining the Fantasy Loser community—big things are coming.
        </h2>
        <h2 className="font-antonio text-white text-2xl sm:text-3xl md:text-[48px] mb-4 leading-tight sm:leading-[48px]">
          Want early access to help shape the future of Fantasy Loser?
        </h2>
        
        <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 mt-8 sm:mt-12">
          <button
            onClick={handleCommissionerClick}
            className="border-2 border-[#18f7b1] text-white px-6 py-3 sm:px-8 sm:py-4 
                     rounded-[10px] hover:bg-transparent hover:text-[#18f7b1] transition-colors duration-300
                     font-antonio text-lg sm:text-xl"
          >
            Waitlist for early access
          </button>
          
          <button
            onClick={onClose}
            className="border-2 border-white text-white px-6 py-3 sm:px-8 sm:py-4 
                     rounded-[10px] hover:bg-white hover:text-black transition-colors duration-300
                     font-antonio text-lg sm:text-xl"
          >
            No thanks
          </button>
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    if (!isSignedUp) {
      return renderNewsletterForm();
    }
    
    if (isCommissioner === null) {
      return renderCommissionerChoice();
    }
    
    return null; // Remove typeform rendering since we're navigating instead
  };

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      {/* Backdrop */}
      <div 
        className={`absolute inset-0 bg-black/50 transition-opacity duration-500 ease-in-out 
          ${isOpen ? 'opacity-100' : 'opacity-0'}`}
        onClick={onClose}
      />
      
      {/* Panel */}
      <div 
        className={`absolute top-0 right-0 h-full w-full md:w-[712px] bg-[#1A1A1A] 
          transform transition-transform duration-500 ease-in-out rounded-3xl 
          ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 left-4 sm:top-8 sm:left-8 p-2 text-black bg-[#18f7b1] hover:bg-white 
                    transition-colors rounded-full flex items-center justify-center w-10 h-10 group z-50"
          aria-label="Close registration panel"
        >
          <X 
            size={24} 
            className="group-hover:text-black transition-colors"
          />
        </button>

        {/* Content */}
        <div className="h-full overflow-y-auto">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default LeagueRegistrationSlider;
