import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomChallengeById } from '../components/weeklyChallenges/customChallenge';
import { CustomChallenge } from '../components/weeklyChallenges/customChallenge';
import { useTheme } from '../contexts/ThemeContext';
import { Share2, Calculator } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';
import MetaHead from '../components/ui/MetaHead';
import { useAuth } from '../contexts/AuthContext';
import EditChallengeModal from '../components/ui/EditChallengeModal';

const availableStats = [
  { id: 'qb_pts', name: 'QB Fantasy Points', category: 'QB Total' },
  { id: 'rb_pts', name: 'RB Fantasy Points', category: 'RB Total' },
  { id: 'wr_pts', name: 'WR Fantasy Points', category: 'WR Total' },
  { id: 'te_pts', name: 'TE Fantasy Points', category: 'TE Total' },
  { id: 'k_pts', name: 'K Fantasy Points', category: 'K Total' },
  { id: 'def_pts', name: 'DEF Fantasy Points', category: 'DEF Total' },
  { id: '4', name: 'Passing Yards', category: 'Passing' },
  { id: '5', name: 'Passing TDs', category: 'Passing' },
  { id: '6', name: 'Interceptions', category: 'Passing' },
  { id: '9', name: 'Rushing Yards', category: 'Rushing' },
  { id: '10', name: 'Rushing TDs', category: 'Rushing' },
  { id: '11', name: 'Receptions', category: 'Receiving' },
  { id: '12', name: 'Receiving Yards', category: 'Receiving' },
  { id: '13', name: 'Receiving TDs', category: 'Receiving' },
  { id: '18', name: 'Fumbles Lost', category: 'Misc' },
  { id: '32', name: 'Sacks', category: 'Defense' },
  { id: '33', name: 'Interceptions', category: 'Defense' },
  { id: '34', name: 'Fumble Recoveries', category: 'Defense' },
  { id: '35', name: 'Defensive TDs', category: 'Defense' },
  { id: '36', name: 'Safeties', category: 'Defense' },
  { id: '37', name: 'Blocked Kicks', category: 'Defense' },
  { id: '38', name: 'Points Allowed', category: 'Defense' },
  { id: '19', name: 'FG Made 0-19', category: 'Kicking' },
  { id: '20', name: 'FG Made 20-29', category: 'Kicking' },
  { id: '21', name: 'FG Made 30-39', category: 'Kicking' },
  { id: '22', name: 'FG Made 40-49', category: 'Kicking' },
  { id: '23', name: 'FG Made 50+', category: 'Kicking' },
  { id: 'fg_total', name: 'Total FG Made', category: 'Kicking' },
  { id: '29', name: 'PAT Made', category: 'Kicking' },
  { id: '30', name: 'PAT Missed', category: 'Kicking' },
  { id: 'FG_TOTAL_MISSED', name: 'Total Field Goals Missed', category: 'Kicking' },
];

const ChallengeDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [challenge, setChallenge] = useState<CustomChallenge | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { theme } = useTheme();
  const [isSubtitleExpanded, setIsSubtitleExpanded] = useState(false);
  const { user, userRole } = useAuth();
  const isAdmin = userRole === 'admin';

  const getStatName = (statId: string) => {
    const stat = availableStats.find(s => s.id === statId);
    return stat ? stat.name : statId;
  };

  const handleEditSuccess = () => {
    // Refresh the challenge data
    if (id) {
      const fetchChallenge = async () => {
        setIsLoading(true);
        try {
          const fetchedChallenge = await getCustomChallengeById(id);
          setChallenge(fetchedChallenge);
        } catch (error) {
          console.error('Error fetching challenge:', error);
        }
        setIsLoading(false);
      };
      fetchChallenge();
    }
  };

  useEffect(() => {
    const fetchChallenge = async () => {
      if (id) {
        try {
          const fetchedChallenge = await getCustomChallengeById(id);
          setChallenge(fetchedChallenge);
        } catch (error) {
          console.error('Error fetching challenge:', error);
        }
        setIsLoading(false);
      }
    };

    fetchChallenge();
  }, [id]);

  const renderTruncatedSubtitle = (text: string | undefined) => {
    if (!text) return null;
    
    const characterLimit = 300;
    const needsTruncation = text.length > characterLimit;
    const truncatedText = needsTruncation && !isSubtitleExpanded 
      ? `${text.slice(0, characterLimit).trim()}...` 
      : text;

  
  
    return (
      <div className="mb-6">
        <p className="text-xl text-gray-200 max-w-3xl font-antonio">
          {truncatedText}
        </p>
        {needsTruncation && (
          <button
            onClick={() => setIsSubtitleExpanded(!isSubtitleExpanded)}
            className="text-[#18f7b1] hover:text-[#0fd494] font-antonio transition-colors duration-200"
          >
            {isSubtitleExpanded ? 'Show Less' : 'Read More'}
          </button>
        )}
      </div>
    );
  };

  if (isLoading) {
    return <StyledLoadingSpinner />;
  }

  if (!challenge) {
    return <div>Challenge not found</div>;
  }

  return (
    <div className={`min-h-screen flex flex-col ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-[#333333]'}`}>
      <Navbar />
      {challenge && (
        <MetaHead
          title={`${challenge.title} - Week ${challenge.week} Fantasy Challenge`}
          description={`Take on the ${challenge.title} fantasy football challenge for Week ${challenge.week}. ${challenge.subtitle || challenge.description}`}
          image={challenge.image || undefined}
          url={`https://fantasyloser.com/challenges/${challenge.id}`}
          type="article"
          keywords={[
            'weekly fantasy challenge',
            'fantasy football competition',
            'fantasy league challenge',
            'week ' + challenge.week + ' challenge',
            challenge.title.toLowerCase(),
            'fantasy football activity',
          ]}
        />
      )}

      <main className="flex-grow pt-[50px]">
      <div className="w-full bg-[#1a1a1a]">
          <div className="w-full lg:container mx-auto px-4">
            <div className="flex flex-col lg:flex-row min-h-[600px] lg:min-h-[815px] lg:items-stretch">
              {/* Right Column - Image */}
              <div className="w-full lg:w-1/2 order-first lg:order-last">
                <div className="h-[400px] lg:h-full w-full relative bg-gray-900">
                  {challenge.image ? (
                    <img
                      src={challenge.image}
                      alt={challenge.title}
                      className="w-full h-full object-fill"
                      style={{
                        objectPosition: 'center',
                        backgroundColor: '#1a1a1a'
                      }}
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-800 flex items-center justify-center">
                      <span className="text-gray-400 text-lg">No image available</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Left Column - Content */}
              <div className="w-full lg:w-1/2 py-8 lg:py-16 lg:pr-8 flex flex-col justify-center">
                <div className="max-w-xl">
                  <div className="flex flex-wrap items-center gap-3 mb-6">
                  {isAdmin && challenge?.id && (  
                   
                      <EditChallengeModal
                        challengeId={challenge.id}
                        isAdmin={isAdmin}
                        onSaveSuccess={handleEditSuccess}
                      />
         
                  )}
                    <span className="bg-[#18f7b1] text-black px-4 py-2 rounded-[10px] text-lg font-bold">
                      Week {challenge.week}
                    </span>
                    {challenge.status === 'active' && (
                    <span className="bg-white text-black px-4 py-2 rounded-[10px] text-lg font-bold">
                      Active Challenge
                    </span>
                  )}
                  </div>

                  <h1 className="text-4xl lg:text-7xl xl:text-8xl font-bold mb-6 font-antonio text-white leading-tight">
                    {challenge.title}
                  </h1>

                  <div className="relative overflow-hidden transition-all duration-300">
                    {renderTruncatedSubtitle(challenge.subtitle || challenge.description)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 py-12 max-w-4xl">
          <div className="space-y-12">
            

          {challenge.formulaGroups && challenge.formulaGroups.length > 0 && (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <h2 className="text-6xl font-bold mb-6 font-antonio uppercase">The Challenge</h2>
      
      <p className="text-xl mb-8 font-roboto text-gray-700">
        {challenge.description}
      </p>
      
      <div className="relative">
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
          <img
            className="w-[full] h-full object-contain"
            src={require("../assets/challengedetailbackground.png")}
            alt="Challenge Detail Background"
          />
        </div>

        <div className="relative z-10">
          {challenge.formulaGroups.map((group, groupIndex) => (
            <div key={groupIndex} className="flex flex-col items-center space-y-4">
              {group.steps.map((step, stepIndex) => (
                <React.Fragment key={stepIndex}>
                  <div className={`text-4xl font-antonio ${
                    step.type === 'operator' ? 'text-gray-500' : 'font-bold'
                  } text-center py-4 uppercase`}>
                    {step.type === 'operator' 
                      ? (step.value === '*' ? 'X' : step.value)
                      : step.type === 'stat' && step.playerStat
                        ? `${step.playerStat.position}${step.playerStat.positionRank} ${getStatName(step.playerStat.statId)}`
                        : step.value}
                  </div>
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )}

            <div>
              <div className="space-y-6">
              {challenge.stakes && (
  <div className="container mx-auto px-4 py-12 max-w-4xl">
    <h2 className="text-6xl font-bold mb-8 font-antonio uppercase">
      The Stakes
    </h2>

    <p className="text-xl mb-12 font-roboto text-gray-700">
      Your choice for what works for your league. Our recommended options for this week, but as always, feel free to cook up your own.
    </p>

    <div className="space-y-12">
      {challenge.stakes.map((stake, index) => (
        <div key={index} className="bg-gray-50 rounded-[xl] p-8">
          {/* Option Number */}
          <div className="inline-block bg-[#18f7b1] text-black px-4 py-2 rounded-[10px] text-lg font-bold mb-4 font-antonio">
            Option {index + 1}
          </div>

          {/* Stake Title */}
          <h3 className="text-3xl font-bold mb-4 font-antonio">
            {stake.title}
          </h3>

          {/* Stake Description */}
          <p className="text-xl leading-relaxed text-gray-700 font-roboto">
            {stake.description}
          </p>
        </div>
      ))}
    </div>
  </div>
)}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ChallengeDetailPage;