import React, { useState, useRef, useEffect } from 'react';
import { ContentItem} from '../types';
import { ArrowLeftCircle, ArrowRightCircle } from 'lucide-react';

interface ContentCarouselProps {
  items: (ContentItem)[];
  onItemClick: (item: ContentItem ) => void;
}

const ContentCarousel: React.FC<ContentCarouselProps> = ({ 
  items, 
  onItemClick,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [orderedItems, setOrderedItems] = useState<(ContentItem )[]>([]);

  useEffect(() => {
    const sorted = [...items].sort((a, b) => {
      const aOrder = ('order' in a && typeof a.order === 'number') ? a.order : 0;
      const bOrder = ('order' in b && typeof b.order === 'number') ? b.order : 0;
      
      // Debug log to verify order values
      console.log(`Comparing items - a: ${aOrder}, b: ${bOrder}`);
      
      return aOrder - bOrder;
    });
  
    // Debug log to verify sorted array
    console.log('Sorted items:', sorted.map(item => ({
      id: item.id,
      order: 'order' in item ? item.order : undefined,
      title: item.title
    })));
  
    setOrderedItems(sorted);
  }, [items]);
  

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    if (!carouselRef.current) return;
    
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (!carouselRef.current) return;
    const scrollAmount = 300;
    const newScrollLeft = direction === 'left' 
      ? carouselRef.current.scrollLeft - scrollAmount
      : carouselRef.current.scrollLeft + scrollAmount;
    
    carouselRef.current.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  if (items.length === 0) {
    return <div className="text-center text-gray-400">No items to display</div>;
  }

 

  return (
    <div className="relative w-screen -ml-[50vw] left-1/2 px-4">
      <div className="max-w-[2000px] mx-auto">
        
        <div 
          ref={carouselRef}
          className="flex overflow-x-auto hide-scrollbar gap-4 mb-6"
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
            scrollSnapType: 'x mandatory',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          {orderedItems.map((item, index) => (
                <div 
                key={item.id || `item-${index}`}
                className="flex-shrink-0 w-[300px] sm:w-[350px] lg:w-[400px]"
                style={{ scrollSnapAlign: 'start' }}
              >
                <div className="p-4">
                  <div 
                    onClick={() => onItemClick(item)}
                    className="relative aspect-[4/5] rounded-lg overflow-hidden cursor-pointer
                           transform transition-transform duration-300 hover:scale-[1.02]
                           border border-[#18f7b1]"
                  >
                    <div 
                      className="absolute inset-0 bg-cover bg-center" 
                      style={{
                        backgroundImage: `url(${'cardImage' in item ? item.cardImage : (item.image || '/placeholder-image.jpg')})`,
                      }}
                    />
                    
                    <div className="absolute inset-0 bg-gradient-to-t 
                                from-black via-black/50 to-transparent
                                from-[0%] via-[20%] to-[100%]" />
  
                    <div className="absolute bottom-6 left-4 right-4 space-y-2">
                      <h3 className="text-xl sm:text-xl md:text-[28px] lg:text-[36px] 
                                   font-bold font-antonio leading-tight text-white">
                        {item.title}
                      </h3>
                      
                      {'flRating' in item && item.flRating && (
                        <div className="flex items-center gap-2">
                          <div className="flex items-center bg-black/50 rounded-full px-3 py-1.5">
                            <img 
                              src={require(`../assets/badge_${(() => {
                                const match = item.flRating.match(/Level\s*(\d+)/i);
                                return match ? match[1] : '1';
                              })()}.png`)}
                              alt={`Level ${(() => {
                                const match = item.flRating.match(/Level\s*(\d+)/i);
                                return match ? match[1] : '1';
                              })()} Badge`}
                              className="w-6 h-6 mr-2"
                            />
                            <span className="text-white font-roboto">
                              Level {(() => {
                                const match = item.flRating.match(/Level\s*(\d+)/i);
                                return match ? match[1] : '1';
                              })()}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
          ))}
        </div>

        <div className="flex justify-center gap-4 mt-4">
          <button 
            onClick={() => handleScroll('left')}
            className="hover:scale-105 transition-transform duration-200"
            aria-label="Previous"
          >
            <ArrowLeftCircle strokeWidth="1.3" className="w-12 h-12 text-black" />
          </button>
          <button 
            onClick={() => handleScroll('right')}
            className="hover:scale-105 transition-transform duration-200"
            aria-label="Next"
          >
            <ArrowRightCircle strokeWidth="1.3" className="w-12 h-12 text-black" />
          </button>
        </div>
      </div>

      <style>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </div>
  );
};

export default ContentCarousel;