import React, { useState, useEffect } from 'react';
import { League } from '../../services/firebase';
import { useYahooAuth } from '../../contexts/YahooAuthContext';
import { Link } from 'react-router-dom';
import CustomChallengeDisplay from '../weeklyChallenges/challengeCalculatorDisplay';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import ChallengeHistory from '../weeklyChallenges/challengeHistory';

interface ChallengesProps {
  league: League;
}

const Challenges: React.FC<ChallengesProps> = ({ league }) => {
  const { yahooToken, refreshYahooToken, yahooIsAuthenticated } = useYahooAuth();
  const { userRole } = useAuth();
  const [activeTab, setActiveTab] = useState<'weekly' | 'custom'>('weekly');
  const [hasActiveChallenge, setHasActiveChallenge] = useState(false);

  useEffect(() => {
    const checkActiveChallenge = async () => {
      try {
        const challengeDoc = await getDoc(doc(db, 'weeklyChallenge', 'current'));
        setHasActiveChallenge(challengeDoc.exists());
      } catch (error) {
        console.error('Error checking active challenge:', error);
      }
    };

    checkActiveChallenge();
  }, []);

  const renderAuthenticationWarning = () => (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
      <p className="font-bold">Yahoo Account Not Connected</p>
      <p>Please connect a Yahoo account to import your teams and participate in challenges.</p>
      <Link 
        to={`/league/${league.id}`} 
        className="text-blue-500 hover:underline mt-2 inline-block"
      >
        Go to league settings to connect
      </Link>
    </div>
  );

  const renderNoTeamsWarning = () => (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
      <p className="font-bold">No Teams Found</p>
      <p>
        Your Yahoo account is connected, but no teams were found for this league. 
        Please make sure you've selected the correct Yahoo league in your league settings.
      </p>
      <Link 
        to={`/league/${league.id}`} 
        className="text-blue-500 hover:underline mt-2 inline-block"
      >
        Go to league settings
      </Link>
    </div>
  );

  const renderChallengeContent = () => {
    if (!yahooIsAuthenticated) {
      return renderAuthenticationWarning();
    }

    if (!league.yahooLeague?.teams?.length) {
      return renderNoTeamsWarning();
    }

    return (
      <div className="space-y-6">
        {/* Simple Navigation */}
        <div className="flex border-b border-gray-200">
          <button
            onClick={() => setActiveTab('weekly')}
            className={`px-4 py-2 font-medium text-sm -mb-px ${
              activeTab === 'weekly'
                ? 'border-b-2 border-[#18f7b1] text-[#18f7b1]'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Weekly Challenges
          </button>
          <button
            onClick={() => setActiveTab('custom')}
            className={`px-4 py-2 font-medium text-sm -mb-px ${
              activeTab === 'custom'
                ? 'border-b-2 border-[#18f7b1] text-[#18f7b1]'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            Custom Challenges
          </button>
        </div>

        {/* Challenge Content */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          {activeTab === 'weekly' ? (
            <div>
              {hasActiveChallenge ? (
                <CustomChallengeDisplay
                  yahooLeague={league.yahooLeague}
                  yahooToken={yahooToken!}
                  refreshYahooToken={refreshYahooToken}
                />
              ) : (
                <div className="text-center py-8">
                  <h3 className="text-xl font-semibold mb-2">No Active Weekly Challenge</h3>
                  <p className="text-gray-600">
                    There is currently no active weekly challenge. Check back later or contact your commissioner.
                  </p>
                  {userRole === 'admin' && (
                    <Link
                      to="/admin/challenges"
                      className="mt-4 inline-block bg-[#18f7b1] text-black px-4 py-2 rounded-lg hover:bg-[#0fd494] transition-colors"
                    >
                      Create Challenge
                    </Link>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="text-center py-8">
              <h3 className="text-xl font-semibold mb-2">Custom League Challenges</h3>
              <p className="text-gray-600 mb-4">
                Create and manage custom challenges for your league.
              </p>
              <Link
                to={`/league/${league.id}/challenges/create`}
                className="inline-block bg-[#18f7b1] text-black px-4 py-2 rounded-lg hover:bg-[#0fd494] transition-colors"
              >
                Create Custom Challenge
              </Link>
            </div>
          )}
        </div>

        {/* Challenge History Section */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Challenge History</h3>
          <div className="space-y-4">
            <p className="text-gray-600 text-center py-4">
            <ChallengeHistory leagueId={league.yahooLeague.league_key} />
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Challenges</h1>
      </div>
      {renderChallengeContent()}
    </div>
  );
};

export default Challenges;