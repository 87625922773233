import React, { useState, useEffect } from 'react';
import { Share2, Calculator, RefreshCw } from 'lucide-react';
import axios from 'axios';
import { doc, getDoc, runTransaction, query, where, orderBy, limit, getDocs, collection, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { YahooLeague } from '../../types/yahoo';
import { availableStats } from './challengeCalculator';

// Types
interface CustomChallenge {
  id: string;
  title: string;
  subtitle?: string;
  description: string;
  week: number;
  stakes: Array<{
    title: string;
    description: string;
  }>;
  formulaGroups: Array<{
    name: string;
    description: string;
    steps: Array<{
      type: 'stat' | 'operator' | 'number';
      value: string | number;
      playerStat?: {
        position: string;
        positionRank: number;
        statId: string;
      };
    }>;
  }>;
  status: 'active' | 'inactive';
  createdAt: Date;
}

interface CustomChallengeDisplayProps {
  yahooLeague: YahooLeague;
  yahooToken: string;
  refreshYahooToken: () => Promise<string>;
}

interface PlayerStats {
  [key: string]: { [key: string]: number };
}

interface CalculationResult {
  name: string;
  result: number;
  breakdown: string;
  details: any;
}

interface TeamResults {
  [teamKey: string]: {
    teamName: string;
    calculations: CalculationResult[];
    totalPoints: number;
  };
}

const CustomChallengeDisplay: React.FC<CustomChallengeDisplayProps> = ({
  yahooLeague,
  yahooToken,
  refreshYahooToken
}) => {
  const [challenge, setChallenge] = useState<CustomChallenge | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<TeamResults>({});
  const [isRefreshing, setIsRefreshing] = useState(false);

  

  // Fetch latest active challenge
  useEffect(() => {
    const fetchLatestChallenge = async () => {
      try {
        const challengeQuery = query(
          collection(db, 'customChallenges'),
          where('status', '==', 'active'),
          orderBy('createdAt', 'desc'),
          limit(1)
        );

        const querySnapshot = await getDocs(challengeQuery);
        
        if (!querySnapshot.empty) {
          const latestChallenge = {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data()
          } as CustomChallenge;
          setChallenge(latestChallenge);
        } else {
          setError('No active challenges found');
        }
      } catch (err) {
        console.error('Error fetching latest challenge:', err);
        setError('Failed to load challenge');
      }
    };

    fetchLatestChallenge();
  }, []);

  // Helper function to get stat value from Yahoo API response
  const extractStatValue = (playerStats: Element[], statId: string): number => {
    // First check if we're even getting player stats
    if (!playerStats || playerStats.length === 0) {
      console.warn('No player stats found');
      return 0;
    }
  
    // Handle special stat IDs
    if (statId.endsWith('_pts')) {
      // Look for the points in the player_points element
      const pointsElement = Array.from(playerStats).find(stat => 
        stat.getElementsByTagName("player_points").length > 0
      );
      if (pointsElement) {
        const total = pointsElement.getElementsByTagName("total")[0]?.textContent;
        return parseFloat(total || '0');
      }
      return 0;
    }
  
    // For regular stats, search through the stat elements
    const statElement = Array.from(playerStats).find(stat => {
      const statIdElement = stat.getElementsByTagName("stat_id")[0];
      return statIdElement && statIdElement.textContent === statId;
    });
  
    if (statElement) {
      const value = statElement.getElementsByTagName("value")[0]?.textContent;
      return parseFloat(value || '0');
    }
  
    console.warn(`Stat ID ${statId} not found in player stats`);
    return 0;
  };

  const getStatValue = (playerStats: Element[], statId: string): string | null => {
    for (const stat of Array.from(playerStats)) {
      const currentStatId = stat.getElementsByTagName("stat_id")[0]?.textContent;
      if (currentStatId === statId) {
        return stat.getElementsByTagName("value")[0]?.textContent;
      }
    }
    return null;
  };

  // Calculate formula results for a group
  const calculateFormulaGroup = (
    group: CustomChallenge['formulaGroups'][0],
    playerStats: PlayerStats
  ): CalculationResult => {
    let result = 0;
    let currentOp = '+';
    let breakdown = '';
    let details: any = {};
  
    // Debug log the input stats
    console.log('Calculating formula with stats:', playerStats);
  
    for (let i = 0; i < group.steps.length; i++) {
      const step = group.steps[i];
      let value = 0;
  
      if (step.type === 'stat' && step.playerStat) {
        const { position, positionRank, statId } = step.playerStat;
        // For kickers, always use 'K' position regardless of rank
        const posKey = position === 'K' ? 'K' : `${position}${positionRank}`;
        
        // Debug log the stat lookup
        console.log('Looking up stat:', {
          position,
          positionRank,
          statId,
          posKey,
          availableStats: playerStats[posKey]
        });
  
        if (playerStats[posKey]) {
          value = playerStats[posKey][statId] || 0;
          
          // Debug log the found value
          console.log(`Found value for ${posKey} stat ${statId}:`, value);
          
          const statName = availableStats.find(s => s.id === statId)?.name || 'Unknown Stat';
          breakdown += `${posKey} ${statName}: ${value}`;
          
          details[posKey] = {
            ...details[posKey],
            [statName]: value
          };
        } else {
          console.warn(`No stats found for position ${posKey}`);
          breakdown += `${posKey} Not Found`;
        }
      } else if (step.type === 'number') {
        value = step.value as number;
        breakdown += value;
      } else if (step.type === 'operator') {
        currentOp = step.value as string;
        breakdown += ` ${currentOp} `;
        continue;
      }
  
      // Debug log the calculation step
      console.log('Calculation step:', {
        currentOp,
        value,
        currentResult: result
      });
  
      // Perform calculation
      switch (currentOp) {
        case '+':
          result += value;
          break;
        case '-':
          result -= value;
          break;
        case '*':
          result *= value;
          break;
        case '/':
          result = value !== 0 ? result / value : 0;
          break;
      }
  
      // Log the result after this step
      console.log('Result after step:', result);
    }
  
    return {
      name: group.name,
      result,
      breakdown,
      details
    };
  };

  const getPlayerName = (playerElement: Element): string => {
    const nameElement = playerElement.getElementsByTagName("name")[0];
    if (!nameElement) {
      console.warn('No name element found for player');
      return 'Unknown Player';
    }
    const fullName = nameElement.getElementsByTagName("full")[0]?.textContent;
    return fullName || 'Unknown Player';
  };

  const getPlayerPosition = (playerElement: Element): string => {
    const selectedPosition = playerElement.getElementsByTagName("selected_position")[0];
    if (!selectedPosition) {
      console.warn('No selected_position element found for player');
      return '';
    }
    const position = selectedPosition.getElementsByTagName("position")[0]?.textContent;
    return position || '';
  };
  

  // Fetch team stats from Yahoo
  const fetchTeamStats = async (teamId: string): Promise<PlayerStats> => {
    if (!challenge) {
      throw new Error('No active challenge');
    }
  
    let token = yahooToken;
    if (!token) {
      token = await refreshYahooToken();
    }
  
    // Explicitly use challenge.week for both the roster and stats request
    const rosterUrl = `https://fantasysports.yahooapis.com/fantasy/v2/team/${yahooLeague.league_key}.t.${teamId}/roster;week=${challenge.week}/players/stats;type=week;week=${challenge.week}`;
    
    console.log(`Fetching stats for team ${teamId} for week ${challenge.week}`);
  
    try {
      const response = await axios.post('/.netlify/functions/yahoo-api-proxy', {
        url: rosterUrl,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  
      // Log the week we're getting data for
      console.log(`Fetching data for challenge week: ${challenge.week}`);
      
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "text/xml");
      
      // Verify the week in the response
      const responseWeek = xmlDoc.querySelector("week")?.textContent;
      console.log(`Response week from Yahoo: ${responseWeek}`);
      
      if (responseWeek && parseInt(responseWeek) !== challenge.week) {
        console.warn(`Warning: Response week (${responseWeek}) does not match challenge week (${challenge.week})`);
      }
  
      const playerStats: PlayerStats = {};
      const players = Array.from(xmlDoc.getElementsByTagName("player"));
      
      console.log(`Found ${players.length} players in week ${challenge.week} response`);
  
      players.forEach((player, idx) => {
        const selectedPosition = getPlayerPosition(player);
        const playerName = getPlayerName(player);
        
        console.log(`Processing player ${playerName} (${selectedPosition})`);
    
        if (!selectedPosition || selectedPosition === 'BN' || selectedPosition === 'IR') {
          return;
        }
    
        const statsNode = player.getElementsByTagName("stats")[0];
        if (!statsNode) {
          console.log(`No stats node found for ${playerName}`);
          return;
        }
    
        playerStats[selectedPosition] = {};
        
        // Initialize all stats to 0
        availableStats.forEach(statDef => {
          playerStats[selectedPosition][statDef.id] = 0;
        });
    
        // Process stat nodes
        const statNodes = Array.from(statsNode.getElementsByTagName("stat"));
        console.log(`Found ${statNodes.length} stats for ${playerName} (${selectedPosition})`);
    
        // Special handling for kickers
        if (selectedPosition === 'K') {
          let totalFieldGoals = 0;
          let totalMissedFieldGoals = 0;
    
          statNodes.forEach(stat => {
            const statId = stat.getElementsByTagName("stat_id")[0]?.textContent;
            const value = parseFloat(stat.getElementsByTagName("value")[0]?.textContent || "0");
    
            // Log each kicker stat
            console.log(`Kicker stat ID: ${statId}, value: ${value}`);
    
            // Add to total field goals for made kicks
            if (["19", "20", "21", "22", "23"].includes(statId || '')) {
              totalFieldGoals += value;
            }
    
            // Add to missed field goals
            if (["24", "25", "26", "27", "28"].includes(statId || '')) {
              totalMissedFieldGoals += value;
            }
    
            playerStats[selectedPosition][statId || ''] = value;
          });
    
          // Set calculated totals
          playerStats[selectedPosition]['fg_total'] = totalFieldGoals;
          playerStats[selectedPosition]['FG_TOTAL_MISSED'] = totalMissedFieldGoals;
    
          console.log(`Kicker ${playerName} totals:`, {
            totalFieldGoals,
            totalMissedFieldGoals,
            patMade: playerStats[selectedPosition]['29'],
            patMissed: playerStats[selectedPosition]['30']
          });
        } else {
          // Normal stat processing for non-kickers
          statNodes.forEach(stat => {
            const statId = stat.getElementsByTagName("stat_id")[0]?.textContent;
            const value = parseFloat(stat.getElementsByTagName("value")[0]?.textContent || "0");
            
            if (statId) {
              playerStats[selectedPosition][statId] = value;
            }
          });
        }
    
        // Handle fantasy points
        const pointsNode = player.getElementsByTagName("player_points")[0];
        if (pointsNode) {
          const totalPoints = pointsNode.getElementsByTagName("total")[0]?.textContent;
          if (totalPoints) {
            const points = parseFloat(totalPoints);
            console.log(`${playerName} fantasy points: ${points}`);
            playerStats[selectedPosition][`${selectedPosition.toLowerCase()}_pts`] = points;
          }
        }
      });
    
      return playerStats;
    
  
    } catch (error) {
      console.error(`Error fetching team stats for week ${challenge.week}:`, error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
      }
      throw error;
    }
  };
  const saveToHistory = async (results: TeamResults, isRefresh = false) => {
    if (!challenge) {
      console.error('No active challenge found');
      return;
    }
  
    try {
      const existingQuery = query(
        collection(db, 'challengeHistory'),
        where('challengeId', '==', challenge.id),
        where('leagueId', '==', yahooLeague.league_key),
        where('week', '==', challenge.week)
      );
  
      const existingDocs = await getDocs(existingQuery);
  
      const historyEntry = {
        challengeId: challenge.id,
        challengeTitle: challenge.title,
        leagueId: yahooLeague.league_key,
        week: challenge.week,
        timestamp: new Date(),
        results: Object.entries(results).map(([teamKey, result]) => ({
          teamKey,
          teamName: result.teamName,
          totalPoints: result.totalPoints,
          calculations: result.calculations,
          isWinner: Object.keys(results)[0] === teamKey,
          isLoser: Object.keys(results)[Object.keys(results).length - 1] === teamKey
        }))
      };
  
      if (!existingDocs.empty && !isRefresh) {
        console.log('Challenge results already exist in history');
        return;
      }
  
      if (!existingDocs.empty && isRefresh) {
        // Update existing document
        const docId = existingDocs.docs[0].id;
        await updateDoc(doc(db, 'challengeHistory', docId), historyEntry);
        console.log('Challenge results updated in history');
      } else {
        // Create new document
        await addDoc(collection(db, 'challengeHistory'), historyEntry);
        console.log('Challenge results saved to history');
      }
    } catch (error) {
      console.error('Failed to save to history:', error);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await calculateResults(true);
    } catch (error) {
      console.error('Error refreshing results:', error);
      setError('Failed to refresh challenge results');
    } finally {
      setIsRefreshing(false);
    }
  };

  // Calculate results for all teams
  const calculateResults = async (isRefresh = false) => {
    if (!challenge) return;
    
    if (!isRefresh) {
      setLoading(true);
    }
    setError(null);
  
    try {
      // Validate required data
      if (!yahooLeague?.teams?.length) {
        throw new Error('No teams found in Yahoo league data');
      }
  
      const calculatedResults: TeamResults = {};
  
      for (const team of yahooLeague.teams) {
        console.log(`Calculating results for team: ${team.name}`); // Debug log
        
        try {
          const playerStats = await fetchTeamStats(team.team_id);
          console.log('Fetched player stats:', playerStats); // Debug log
          
          const calculations = challenge.formulaGroups.map(group => {
            const result = calculateFormulaGroup(group, playerStats);
            console.log(`Formula group "${group.name}" result:`, result); // Debug log
            return result;
          });
  
          const totalPoints = calculations.reduce((sum, calc) => sum + calc.result, 0);
  
          calculatedResults[team.team_key] = {
            teamName: team.name,
            calculations,
            totalPoints
          };
        } catch (teamError) {
          console.error(`Error calculating results for team ${team.name}:`, teamError);
          // Continue with other teams instead of failing completely
          calculatedResults[team.team_key] = {
            teamName: team.name,
            calculations: [],
            totalPoints: 0
          };
        }
      }
  
      // Only update results if we have any
      if (Object.keys(calculatedResults).length > 0) {
        const sortedResults = Object.fromEntries(
          Object.entries(calculatedResults)
            .sort(([, a], [, b]) => b.totalPoints - a.totalPoints)
        );
        
        setResults(sortedResults);
  
        if (Object.keys(sortedResults).length > 0) {
          await saveToHistory(sortedResults, isRefresh);
        }
      } else {
        throw new Error('No results calculated for any teams');
      }
    } catch (error) {
      console.error('Error calculating results:', error);
      setError(error instanceof Error ? error.message : 'Failed to calculate challenge results');
    } finally {
      if (!isRefresh) {
        setLoading(false);
      }
    }
  };

  // Calculate results when challenge or league data changes
  useEffect(() => {
    if (challenge) {
      calculateResults();
    }
  }, [challenge, yahooLeague]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#18f7b1]"></div>
      </div>
    );
  }

  if (error || !challenge) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-4">
        <p className="text-red-700">{error || 'No active challenge found'}</p>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4">
      {/* Challenge Header Section */}
      <div className="bg-white shadow-lg rounded-xl overflow-hidden mb-8">
        <div className="bg-[#18f7b1] p-6">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-2xl font-bold">{challenge.title}</h3>
              {challenge.subtitle && (
                <p className="text-lg mt-2 text-gray-800">{challenge.subtitle}</p>
              )}
            </div>
            <div className="flex items-center gap-2">
  <button 
    onClick={handleRefresh}
    disabled={isRefreshing}
    className={`p-2 hover:bg-black hover:text-white rounded-full transition-colors duration-200 
      ${isRefreshing ? 'opacity-50 cursor-not-allowed' : ''}`}
    title="Refresh results"
  >
    <RefreshCw 
      size={24} 
      className={`${isRefreshing ? 'animate-spin' : ''}`}
    />
  </button>
  <button 
    className="p-2 hover:bg-black hover:text-white rounded-full transition-colors duration-200"
  >
    <Share2 size={24} />
  </button>
</div>
          </div>
        </div>
        
        <div className="p-6">
          <p className="text-lg mb-4">{challenge.description}</p>
          
          {challenge.stakes.length > 0 && (
            <div className="mt-4">
              <h4 className="font-bold text-lg mb-2">Stakes:</h4>
              <div className="space-y-2">
                {challenge.stakes.map((stake, index) => (
                  <div key={index} className="bg-gray-50 p-3 rounded">
                    <h5 className="font-semibold">{stake.title}</h5>
                    <p>{stake.description}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Results Grid Section */}
      <div className="grid md:grid-cols-2 gap-6">
        {Object.entries(results).map(([teamKey, teamResult], index) => (
          <div 
            key={teamKey}
            className={`rounded-lg p-6 ${
              index === 0 
                ? 'bg-green-50 border-2 border-green-500' 
                : index === Object.keys(results).length - 1
                  ? 'bg-red-50 border-2 border-red-500'
                  : 'bg-white border-2 border-gray-200'
            }`}
          >
            <div className="flex justify-between items-center mb-4">
              <div>
                <h4 className="text-xl font-bold">{teamResult.teamName}</h4>
                {index === 0 && (
                  <span className="inline-block bg-green-500 text-white text-sm font-bold px-2 py-1 rounded mt-1">
                    Winner 🏆
                  </span>
                )}
                {index === Object.keys(results).length - 1 && (
                  <span className="inline-block bg-red-500 text-white text-sm font-bold px-2 py-1 rounded mt-1">
                    Loser 💀
                  </span>
                )}
              </div>
              <span className="text-3xl font-bold">
                {teamResult.totalPoints.toFixed(2)}
              </span>
            </div>

            <div className="space-y-4">
              {teamResult.calculations.map((calc, calcIndex) => (
                <div key={calcIndex} className="bg-white p-4 rounded shadow">
                  <div className="flex items-center gap-2 mb-2">
                    <Calculator size={16} />
                    <h5 className="font-semibold">{calc.name}</h5>
                  </div>
                  <p className="text-sm text-gray-600 mb-2">
                    {calc.breakdown}
                  </p>
                  <div className="font-mono bg-gray-50 p-2 rounded">
                    Result: {calc.result.toFixed(2)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomChallengeDisplay;