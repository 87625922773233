import React, { useState, useEffect, useRef } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useSpring, animated } from 'react-spring';
import LeagueRegistrationSlider from '../components/ui/LeagueRegistrationSlider';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ContentItem } from '../types';
import { getRandomContent } from '../services/firebase';
import ContentCarousel from '../components/ContentCarousel';

// Define TypeScript interfaces
interface TimeLeft {
  days: number;
  hours: number;
  mins: number;
  secs: number;
}

interface Option {
  text: string;
  isActive: boolean;
  symbol: string;
  symbolColor: string;
}

const HomePage: React.FC = () => {
  const [carouselItems, setCarouselItems] = useState<ContentItem[]>([]);
  const navigate = useNavigate();
  // State for countdown timer
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 1,
    hours: 21,
    mins: 36,
    secs: 42
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

   useEffect(() => {
      const fetchContent = async () => {
        try {
          const content = await getRandomContent(20);
          setCarouselItems(content);
        } catch (error) {
          console.error('Error fetching content:', error);
        }
      };
      fetchContent();
    }, []);

      const handleItemClick = (item: ContentItem ) => {
        if ('type' in item) {
          navigate(`/punishments/${item.id}`);
        } else {
          navigate(`/`);
        }
      };

  // Calculate time until the 2025 season
  useEffect(() => {
    // Set target date for 2025 season
    const targetDate = new Date('2025-09-04T00:00:00');
    
    const updateTimer = () => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();
      
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const mins = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const secs = Math.floor((difference % (1000 * 60)) / 1000);
        
        setTimeLeft({ days, hours, mins, secs });
      }
    };
    
    // Initial call
    updateTimer();
    
    // Update every second
    const timerId = setInterval(updateTimer, 1000);
    
    return () => clearInterval(timerId);
  }, []);

  // Format numbers to always have two digits
  const formatNumber = (num: number): string => {
    return num.toString().padStart(2, '0');
  };

  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [isScrollLocked, setIsScrollLocked] = useState(true);
  const sectionRef = useRef<HTMLElement | null>(null);
  const lastScrollY = useRef(0);
  const touchTotalY = useRef(0);
  const scrollThreshold = 5; // Minimum scroll amount to trigger animation change
  
  // Options to cycle through
  const options: Option[] = [
    { text: "DRAFTING", isActive: false, symbol: "✕", symbolColor: "text-red-600" },
    { text: "WINNING", isActive: false, symbol: "✕", symbolColor: "text-red-600" },
    { text: "WATCHING YOUR FRIENDS SUFFER", isActive: true, symbol: "✓", symbolColor: "text-green-500" }
  ];

   // Fix: Create individual springs for each option
   const option1Props = useSpring({
    opacity: activeOptionIndex >= 0 ? 1 : 0,
    transform: activeOptionIndex >= 0 ? 'translateY(0)' : 'translateY(20px)',
    config: { mass: 1, tension: 180, friction: 12 },
    delay: 0
  });
  
  const option2Props = useSpring({
    opacity: activeOptionIndex >= 1 ? 1 : 0,
    transform: activeOptionIndex >= 1 ? 'translateY(0)' : 'translateY(20px)',
    config: { mass: 1, tension: 180, friction: 12 },
    delay: 200
  });
  
  const option3Props = useSpring({
    opacity: activeOptionIndex >= 2 ? 1 : 0,
    transform: activeOptionIndex >= 2 ? 'translateY(0)' : 'translateY(20px)',
    config: { mass: 1, tension: 180, friction: 12 },
    delay: 400
  });
  
  // Store all props in an array for easy access
  const optionProps = [option1Props, option2Props, option3Props];

  // Check if device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Initial check
    checkIfMobile();
    
    // Add resize listener
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Auto-animate on mobile
  useEffect(() => {
    if (isMobile && !animationComplete) {
      // Set up auto animation for mobile
      let animationTimeout: NodeJS.Timeout;
      const animate = (step: number) => {
        if (step <= 3) {
          // Update progress to appropriate value for each step
          const progress = step === 1 ? 0.2 : step === 2 ? 0.5 : 1.0;
          updateProgressAndOptions(progress);
          
          // Schedule next step
          animationTimeout = setTimeout(() => animate(step + 1), 800);
        }
      };
      
      // Start animation after a brief delay
      animationTimeout = setTimeout(() => animate(1), 500);
      
      return () => {
        clearTimeout(animationTimeout);
      };
    }
  }, [isMobile, animationComplete]);

  // Function to update animation progress
  const updateProgressAndOptions = (newProgress: number) => {
    const clampedProgress = Math.min(Math.max(newProgress, 0), 1);
    setScrollProgress(clampedProgress);
    
    // Calculate which option should be active based on progress
    // Using more discrete steps for clearer animation triggers
    let newActiveIndex = -1;
    if (clampedProgress >= 0.15) newActiveIndex = 0;
    if (clampedProgress >= 0.45) newActiveIndex = 1;
    if (clampedProgress >= 0.75) newActiveIndex = 2;
    
    if (newActiveIndex !== activeOptionIndex) {
      setActiveOptionIndex(newActiveIndex);
    }
    
    // Check if animation is complete
    if (clampedProgress >= 0.9 && !animationComplete) {
      setAnimationComplete(true);
      
      // Small delay before unlocking scroll to ensure animation is visible
      setTimeout(() => {
        setIsScrollLocked(false);
      }, 300);
    }
  };

  // Lock/unlock scrolling - only on desktop
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    if (isScrollLocked && !isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalStyle;
    }
    
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isScrollLocked, isMobile]);

  // Handle wheel events to control the animation - only on desktop
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (!animationComplete && !isMobile) {
        e.preventDefault();
        
        // Update virtual scroll position
        const newScrollY = lastScrollY.current + e.deltaY;
        lastScrollY.current = newScrollY;
        
        // Calculate progress (0 to 1) based on cumulative scroll
        const totalScrollNeeded = 800; // Reduced from 1000 for greater sensitivity
        const newProgress = newScrollY / totalScrollNeeded;
        
        updateProgressAndOptions(newProgress);
      }
    };
    
    // Only add listener on desktop
    if (!isMobile) {
      // Use passive: false to be able to prevent default
      window.addEventListener('wheel', handleWheel, { passive: false });
      
      return () => {
        window.removeEventListener('wheel', handleWheel);
      };
    }
  }, [activeOptionIndex, animationComplete, isMobile]);

  // Also handle touch events for desktop tablets
  useEffect(() => {
    if (isMobile) return; // Skip for mobile since we auto-animate
    
    let touchStartY = 0;
    
    const handleTouchStart = (e: TouchEvent) => {
      if (!animationComplete) {
        e.preventDefault();
        touchStartY = e.touches[0].clientY;
      }
    };
    
    const handleTouchMove = (e: TouchEvent) => {
      if (!animationComplete) {
        e.preventDefault();
        
        const touchY = e.touches[0].clientY;
        const deltaY = touchStartY - touchY;
        touchStartY = touchY;
        
        // Accumulate touch movement with amplification factor
        // Higher multiplier makes it much more sensitive on tablets
        touchTotalY.current += deltaY * 3.5;
        
        // Calculate progress (0 to 1) based on cumulative touch movement
        const totalTouchNeeded = 50; 
        const newProgress = touchTotalY.current / totalTouchNeeded;
        
        updateProgressAndOptions(newProgress);
      }
    };
    
    // Also handle touch end to improve tablet experience
    const handleTouchEnd = () => {
      // If we're close to a threshold but not quite there, snap to the next option
      if (!animationComplete) {
        // Current progress is between options, snap to next
        if (activeOptionIndex === 0 && scrollProgress < 0.45) {
          updateProgressAndOptions(0.45);
        } else if (activeOptionIndex === 1 && scrollProgress < 0.75) {
          updateProgressAndOptions(0.75);
        } else if (activeOptionIndex === 2 && scrollProgress < 0.9) {
          updateProgressAndOptions(1.0);
        }
      }
    };
    
    if (!isMobile) {
      window.addEventListener('touchstart', handleTouchStart, { passive: false });
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('touchend', handleTouchEnd, { passive: false });
      
      return () => {
        window.removeEventListener('touchstart', handleTouchStart);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [activeOptionIndex, animationComplete, scrollProgress, isMobile]);

  // Add a way to skip animation with button tap on desktop
  const skipAnimation = () => {
    updateProgressAndOptions(1.0);
  };

  return (
    <main className="flex flex-col">
      <Navbar />
      <section ref={sectionRef} className="relative min-h-[100vh] bg-[#1a1a1a] overflow-hidden mt-0">
        <div className="container mx-auto px-4 flex flex-col md:flex-row min-h-[100vh]">
          {/* Left side content */}
          <div className="w-full md:w-1/2 flex flex-col justify-center items-start p-2 md:p-12 pt-20 md:pt-12 relative z-30">
          <h1 className="font-bold text-white leading-tight font-antonio tracking-tight">
            <span className="block relative">
              <span className="relative z-10 text-[60px] sm:text-[100px] md:text-[120px] inline-block">BEST PART OF</span>
              <span className="absolute inset-0 bg-gradient-to-r z-0"></span>
            </span>
  
            {/* Mobile version - only visible on screens smaller than sm breakpoint */}
            <div className="block sm:hidden">
              <span className="block mt-2 relative">
                <span className="relative z-10 text-[60px] inline-block">FANTASY</span>
                <span className="absolute inset-0 bg-gradient-to-r z-0"></span>
              </span>
              <span className="block mt-2 relative">
                <span className="relative z-10 text-[60px] inline-block">FOOTBALL?</span>
                <span className="absolute inset-0 bg-gradient-to-r z-0"></span>
              </span>
            </div>
            
            {/* Desktop version - only visible on sm breakpoint and above */}
            <span className="hidden sm:block mt-2 relative">
              <span className="relative z-10 text-[60px] sm:text-[100px] md:text-[120px] inline-block whitespace-nowrap">FANTASY FOOTBALL?</span>
              <span className="absolute inset-0 bg-gradient-to-r z-0"></span>
            </span>
          </h1>
            
            <div className="mt-8 md:mt-16 w-full">
              {/* Options now stack vertically instead of replacing each other */}
              {options.map((option, index) => (
                <animated.div 
                  key={index}
                  style={optionProps[index]}
                  className="mb-4 flex items-center"
                >
                  <span className={`${option.symbolColor} text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mr-4`}>
                    {option.symbol}
                  </span>
                  <span className={`text-[#18f7b1] text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold font-antonio tracking-tight drop-shadow-[0_0_8px_rgba(24,247,177,0.3)]`}>
                    {option.text}
                  </span>
                </animated.div>
              ))}
            </div>
            
            {/* Scroll instruction with clickable/tappable button on desktop */}
            {!animationComplete && !isMobile && (
              <div className="mt-8 text-white opacity-80 flex flex-col items-center">
                <p className="mb-2">Scroll down to continue</p>
                <button 
                  onClick={skipAnimation}
                  className="animate-bounce p-2 bg-[#18f7b1]/20 rounded-full hover:bg-[#18f7b1]/30 transition-colors"
                  aria-label="Skip animation"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </button>
              </div>
            )}
          </div>
          
          {/* Right side Instagram video */}
          <div className="w-full md:w-1/2 flex items-center justify-center py-8 md:py-0">
            <div
              className="w-[280px] sm:w-[340px] h-[540px] sm:h-[600px] bg-gray-900 rounded-2xl overflow-hidden shadow-xl relative mx-auto"
            >
              {/* Instagram video with custom controls */}
              <div className="absolute inset-0 bg-gradient-to-b from-[#18f7b1]/20 to-[#18f7b1]/50"></div>
              <img 
                src={require("../assets/FL_Project_02.gif")}
                className="w-full h-full object-cover relative z-10"
                alt="Fantasy loser animation"
              />
              
              {/* Instagram-style overlay elements */}
              <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-between p-4 z-20">
                {/* Top overlay elements */}
                <div className="flex justify-end items-center">
                  <span className="bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded-full">
                    @FantasyLoser
                  </span>
                </div>
                
                {/* Bottom section with text and user info */}
                <div className="text-white">
                  <p className="text-sm font-medium">
                    Will this be you this season? 
                  </p>
                  <div className="flex items-center mt-2 bg-black/20">
                    <div className="w-8 h-8 bg-[#18f7b1] rounded-full overflow-hidden mr-2 flex items-center justify-center shadow-lg">
                      <span className="text-black font-bold text-xs">FL</span>
                    </div>
                    <div>
                      <p className="font-bold text-sm">Zach, 26</p>
                      <p className="text-xs text-gray-300">Cleveland, FL</p>
                      <p className="text-xs text-[#18f7b1] font-medium">realized he made a mistake</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
    
        <section className="py-8 sm:py-12 lg:py-16 px-4 sm:px-8 md:px-16 bg-white text-black relative min-h-[600px] sm:min-h-[800px] md:min-h-[950px] overflow-hidden">
          {/* White overlay for better text contrast */}
          <div className="absolute inset-0 bg-white/60 z-0"></div>
          
          <div className="absolute inset-0 z-0">
            {/* Left image - positioned differently on mobile vs desktop */}
            <div className="absolute top-[32%] -left-[10%] sm:top-[15%] md:top-[20%] md:left-[5%] lg:-left-[1%] z-0 transform -rotate-6">
              <div className="w-[180px] sm:w-[250px] md:w-[300px] lg:w-[400px] relative">
                {/* White gradient overlay for image */}
                <div className="absolute inset-0 bg-gradient-to-tr from-transparent via-white/10 to-white/40 z-10"></div>
                <img 
                  src={require('../assets/Mike League 9.png')}
                  alt="Fantasy football league members" 
                  className="w-full h-auto object-contain relative"
                />
              </div>
            </div>
            
            {/* Right image - repositioned below button on mobile */}
            <div className="hidden md:block absolute md:bottom-[20%] md:right-[1%] lg:right-[1%] z-0">
              <div className="w-[300px] lg:w-[400px] relative">
                {/* Light overlay for right image */}
                <div className="absolute inset-0 bg-gradient-to-tl from-transparent via-white/10 to-white/50 z-10"></div>
                <img 
                  src={require('../assets/Mike League 10.png')}
                  alt="Fantasy football league members" 
                  className="w-full h-auto object-contain"
                />
              </div>
            </div>
          </div>

          <div className="mx-auto max-w-6xl text-center relative z-20 pt-10 md:pt-16">
            <div className="mb-8 md:mb-16">
              <p className="text-[32px] md:text-[60px] text-[#474747] text-center leading-tight font-bold font-antonio">
                Fantasy football isn't just a game. It's war. It's tradition. And for one unlucky soul each season... it's absolute misery. Launching for the 2025 season, Fantasy Loser is your <span className="text-black">go-to hub for savage
                punishments, weekly challenges, and tools to keep your league engaged.</span>
              </p>
            </div>
            
            <h3 className="text-[32px] md:text-[60px] font-bold mt-16 md:mt-24 lg:mt-32 text-[#474747] font-antonio">
              Because the leagues that punish, prosper.
            </h3>
            
            <div className="flex justify-center mt-10 md:mt-16">
              <button 
                onClick={() => setIsRegistrationOpen(true)}
                className="text-white mt-10 text-center text-lg sm:text-xl md:text-2xl w-full sm:w-auto sm:max-w-[220px] font-antonio bg-[#1a1a1a] border border-primary-500 hover:border-[#18f7b1] transition-colors duration-300 py-2 sm:py-4 px-4 sm:px-8 rounded-md"
              >
                JOIN US
              </button>
            </div>
          </div>
          
          {/* Mobile-only right image positioned below JOIN US button */}
          <div className="relative mt-10 flex justify-center md:hidden -right-[20%]">
            <div className="w-[180px] sm:w-[250px] relative z-0">
              {/* Light overlay for mobile image */}
              <div className="absolute inset-0 bg-gradient-to-t from-transparent to-white/50 z-10"></div>
              <img 
                src={require('../assets/Mike League 10.png')}
                alt="Fantasy football league members" 
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </section>

      {/* Countdown Section */}
      <section className="py-8 sm:py-12 lg:py-16 px-6 sm:px-8 md:px-16 bg-[#1a1a1a] text-white text-center justify-center min-h-[1000px] md:min-h-[2000px]">
        <div className="mb-8 sm:mb-12 lg:mb-16">
          <div className="flex justify-center">
            <h2 
              className="text-[100px] sm:text-[140px] md:text-[197px] lg:text-[240px] font-antonio font-bold mb-4 cursor-pointer"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="w-[200px] h-[120px] sm:w-[280px] sm:h-[160px] md:w-[451px] md:h-[271px] inline-flex items-center justify-center">
                {!isHovered ? "JOY" : (
                  <span className="text-[#1a1a1a] bg-[#FFC526] w-full h-full inline-flex items-center justify-center rounded-[10px] md:rounded-[20px]">
                    PAIN
                  </span>
                )}
              </div>
            </h2>
          </div>
          
          <p className="text-lg sm:text-xl md:text-2xl mb-4 sm:mb-5 text-gray-400 font-antonio text-center">
            {!isHovered ? "/joi/ (noun)" : (
              <span className="text-[#FFC526]">/pān/ (noun)</span>
            )}
          </p>
          
          <div className="mb-10 sm:mb-12 md:mb-16">
            <p className="text-xl sm:text-2xl md:text-2xl text-center mb-2 sm:mb-3 font-bold font-antonio">
              {!isHovered ? "Definition:" : (
                <span className="text-[#FFC526]">Definition:</span>
              )}
            </p>
            <p className="text-lg sm:text-xl md:text-2xl text-center font-antonio px-4 sm:px-6 leading-snug">
              {!isHovered ? (
                <span className="text-gray-300">
                  Watching your league's last-place finisher suffer on an epic scale
                </span>
              ) : (
                <span className="text-[#FFC526]">
                  When your first round pick is out for the season
                </span>
              )}
            </p>
          </div>
        </div>
          
        <h3 className="text-[50px] sm:text-[60px] md:text-[80px] font-bold mb-3 sm:mb-3 font-antonio mt-[300px] sm:mt-[300px] md:mt-[400px]">COUNTDOWN TO CHAOS</h3>
        <p className="text-xl sm:text-xl md:text-2xl mb-6 text-gray-400 font-antonio">(countdown to 2025 season)</p>
        
        <div className="flex justify-center space-x-4 sm:space-x-6 md:space-x-8 mb-12 sm:mb-12 md:mb-16">
          <div className="flex flex-col items-center">
            <div className="text-[50px] sm:text-[70px] md:text-[100px] font-antonio font-bold mb-1 sm:mb-1 md:mb-2 bg-gradient-to-b from-white to-gray-500 bg-clip-text text-transparent">
              {formatNumber(timeLeft.days)}
            </div>
            <span className="text-sm sm:text-base uppercase font-antonio">Days</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-[50px] sm:text-[70px] md:text-[100px] font-antonio font-bold mb-1 sm:mb-1 md:mb-2 bg-gradient-to-b from-white to-gray-500 bg-clip-text text-transparent">
              {formatNumber(timeLeft.hours)}
            </div>
            <span className="text-sm sm:text-base uppercase font-antonio">Hours</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-[50px] sm:text-[70px] md:text-[100px] font-antonio font-bold mb-1 sm:mb-1 md:mb-2 bg-gradient-to-b from-white to-gray-500 bg-clip-text text-transparent">
              {formatNumber(timeLeft.mins)}
            </div>
            <span className="text-sm sm:text-base uppercase font-antonio">Mins</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-[50px] sm:text-[70px] md:text-[100px] font-antonio font-bold mb-1 sm:mb-1 md:mb-2 bg-gradient-to-b from-white to-gray-500 bg-clip-text text-transparent">
              {formatNumber(timeLeft.secs)}
            </div>
            <span className="text-sm sm:text-base uppercase font-antonio">Secs</span>
          </div>
        </div>
        
        <p className="text-lg sm:text-xl md:text-2xl mb-6  max-w-md sm:max-w-xl md:max-w-2xl mx-auto font-antonio px-4 sm:px-6 leading-snug">
          The 2025 season is closing in. That means new leagues, fresh rivalries, 
          and punishments so god-awful they'll become the stuff of legend.
        </p>
        
        <p className="text-xl sm:text-2xl md:text-3xl text-[#FFD700]  mb-6 font-knewave">Are you ready?</p>
        
        <Link target="_blank" to="https://shop.fantasyloser.com" className="inline-block text-white mt-6 sm:mt-8 md:mt-10 text-center text-lg sm:text-xl md:text-2xl w-[280px] sm:w-[300px] md:w-[320px] font-antonio bg-transparent border-2 border-primary-500 hover:border-[#18f7b1] transition-colors duration-300 py-3 sm:py-4 md:py-5 px-6 sm:px-8 md:px-10 rounded-md mb-20">
          Browse 2025 Merch
        </Link>
      </section>

      {/* Ultimate Loser Punishments Section */}
      <section className="py-8 sm:py-12 lg:py-16 px-8 md:px-16 bg-white text-black">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-12">
            {/* Shield Logo - Positioned to straddle sections */}
            <div className="relative">
              <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-[110%]">
                <img 
                  src={require('../assets/Group 1171276406.png')}
                  alt="Fantasy Loser Shield Logo" 
                  className="w-[150px] h-[180px] md:w-[184.57px] md:h-[217.3px]"
                />
              </div>
            </div>
            <h2 className="text-[60px] md:text-[80px] font-bold mb-2 font-antonio mt-20">Ultimate Loser Punishments</h2>
            <p className="text-[24px] text-gray-600 font-antonio">
              Your league's loser deserves only the worst. Explore our collection of hilarious 
              and/or utterly brutal punishment ideas.
            </p>
          </div>
          
          {/* Social media cards grid */}
          <ContentCarousel
            items={carouselItems}
            onItemClick={handleItemClick}
          />
          <div className="flex justify-center space-x-4 mt-10">
            <Link to="/punishments" className="text-white mt-10 text-center text-lg sm:text-xl md:text-2xl w-full sm:w-auto sm:max-w-[300px] font-antonio bg-[#1a1a1a] border border-primary-500 hover:border-[#18f7b1] transition-colors duration-300 py-2 sm:py-4 px-4 sm:px-8 rounded-md">
              Browse Punishments
            </Link>
            <Link to="https://h4hcbpgn1q9.typeform.com/to/E21Gfvu6" className="text-[#1a1a1a] mt-10 text-center text-lg sm:text-xl md:text-2xl w-full sm:w-auto sm:max-w-[300px] font-antonio bg-transparent border border-primary-500 hover:border-[#18f7b1] transition-colors duration-300 py-2 sm:py-4 px-4 sm:px-8 rounded-md">
              Submit Your Own
            </Link >
          </div>
        </div>
      </section>

      {/* Coming Soon Section */}
      <section className='py-16 px-8 md:px-16 bg-[#1a1a1a] text-white relative min-h-[913px] hmd:min-h-[1030px] -mb-[60px] flex items-center z-0 overflow-hidden'>
        <div className="max-w-[1500px] mx-auto relative z-10 w-full">
          <div className="flex flex-col md:flex-row md:items-center">
            {/* Text content - Left side with stronger wrapping */}
            <div className="z-20 relative md:w-[30%] lg:w-[35%] xl:w-[40%] md:ml-[5%] lg:ml-[8%] xl:ml-[10%]">
              <h2 className="text-[60px] md:text-[70px] lg:text-[80px] font-bold leading-none mb-6 font-antonio">
                Coming<br />Soon
              </h2>
              <p className="mb-8 text-gray-300 max-w-md md:max-w-xs lg:max-w-sm xl:max-w-md font-antonio text-xl">
                Fantasy sports is about to change forever. We're building tools that will revolutionize your league's gameplay and keep the crew locked in all season. Sign up now to receive exclusive early access.
              </p>
              <button 
                onClick={() => setIsRegistrationOpen(true)} 
                className="border border-[#18f7b1] text-[#18f7b1] px-8 py-3 rounded hover:bg-[#18f7b1] hover:text-black transition duration-300 font-antonio"
              >
                Join Now
              </button>
            </div>
            
            {/* Image content - Right side scaled down slightly on smaller screens */}
            <div className="relative md:w-[70%] lg:w-[65%] xl:w-[60%] mt-12 md:mt-0">
              <div className="w-full xl:w-[1141px] md:h-[748px] lg:h-[842px] xl:h-[935px] 
                            relative md:absolute md:-right-[50px] lg:-right-[75px] xl:-right-[100px] 
                            md:top-1/2 md:transform md:-translate-y-1/2 z-0">
                <img 
                  src={require('../assets/comingsoongroup.png')} 
                  alt="Fantasy Platforms Integration" 
                  className="w-full h-full object-contain"
                  style={{ 
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                  onError={(e) => {
                    // Fallback if image doesn't load
                    const target = e.target as HTMLImageElement;
                    console.error("Failed to load image");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <LeagueRegistrationSlider 
        isOpen={isRegistrationOpen}
        onClose={() => setIsRegistrationOpen(false)}
      />

      <Footer />
    </main>
  );
};

export default HomePage;