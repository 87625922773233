import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

interface MarkdownFieldProps {
  value: string;
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  minHeight?: number;
  label?: string;
}

export const MarkdownField: React.FC<MarkdownFieldProps> = ({ 
  value, 
  onChange, 
  placeholder, 
  minHeight = 200, 
  label 
}) => {
  return (
    <div className="markdown-editor-wrapper" data-color-mode="light">
      {label && (
        <label className="block text-sm font-medium mb-2">{label}</label>
      )}
      <MDEditor
        value={value}
        onChange={onChange}
        preview="edit"
        height={minHeight}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]]
        }}
        textareaProps={{
          placeholder,
        }}
      />
    </div>
  );
};

export const MarkdownDisplay: React.FC<{
  content: string;
  className?: string;
}> = ({ content, className = '' }) => {
  return (
    <div className={`markdown-display ${className}`}>
      <MDEditor.Markdown 
        source={content} 
        rehypePlugins={[[rehypeSanitize]]}
      />
    </div>
  );
};

export default { MarkdownField, MarkdownDisplay };